import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Breakpoint } from '@mui/system';
import React from 'react';
import styles from './dialog.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import NabuButton from '../../atoms/button/button';
export interface IDialogAction {
	name: string;
	color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	variant?: 'contained' |  'outlined';
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	'data-cy': string;
}

interface Props {
	title: string;
	contentText?: string;
	open: boolean;
	maxWidth?: false | Breakpoint;
	handleClose: () => void;
	fullWidth?: boolean;
	dialogActions: IDialogAction [];
	children: React.ReactNode;
	isPersistent?: boolean;
	className?: any;
}

export default function NabuDialog(props: Props) {
	const handleClose = () => {
		!props?.isPersistent &&	props.handleClose();
	};

	return (
		<React.Fragment>
			
			<Dialog
				fullWidth={props.fullWidth ? props.fullWidth : false}
				maxWidth={props.maxWidth}
				open={props.open}
				onClose={handleClose}
				className={	props?.className }
			>
				<Grid container item sx={{p:3, mt:3}}>
					<Grid md={10.5} item>
						<h2 className={styles.headingbox} data-cy={`${props.title}`}>{props.title}</h2>
					</Grid>
					<Grid md={1.5} item>
						<div className={styles.closeIcon} onClick={() => props.handleClose()} data-cy='close_icon'><NabuButton data-cy='close_icon_btn' variant='text'><CloseIcon data-cy='close_icon_svg' /></NabuButton> </div>
					</Grid>

				</Grid>
				<DialogContent className={styles.dialogContent}>
					<DialogContentText>
						{props.contentText}
					</DialogContentText>
					{props.children}
				</DialogContent>
				<DialogActions>

					<div className={styles.actionbutton}>
						{ props.dialogActions.map((action: IDialogAction, index: number) => (
							<div key={`dialog-action-${index}`} style={{marginRight:'10px'}}>
								<Button
									variant={action.variant}
									color={action.color}
									onClick={action.onClick}
									data-cy={action['data-cy']}
									disabled={action?.disabled}
								>
									{action.name}
								</Button>
							</div>
						))
						}
					</div>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}