import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, FormControl, FormLabel, Grid, ListItemButton, ListItemText } from '@mui/material';
import TextEditor from '@/modules/dashboard/components/text-editor';
import styles from '../snippet.module.scss';
import NabuButton from '@/common/components/atoms/button/button';
import { Save } from '@mui/icons-material';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import parse from 'html-react-parser';
import { Controller, useForm } from 'react-hook-form';
import { SnippetModel } from '../shared/models/snippet';
import NabuSelect from '@/common/components/atoms/select/select';
import { SnippetTypes } from '@/common/constants/constants';
import useNotification from '@/common/hooks/useNotification';
import { useMutation } from 'react-query';
import { saveSnippet } from '@/services/contract';
import { StatusCodes } from '@/common/enums/StatusCodes';
import { getNotificationText, replaceSnippetFieldsWithInput } from '@/common/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import snippetSchema from '../shared/schema/snippetSchema';
import { SnippetFields } from '@/common/constants/constants';
import { useLocation } from 'react-router-dom';
import { NabuCardDefault } from '@/common/components/molecules/cardWhite/cardWhite';

interface IProps {
	onClose: any;
  formData: SnippetModel;
  formState?: boolean;
  refresh?: any;
}

const SnippetForm = (props: IProps) => {
	const [snippetPreview, setSnippetPreview] = useState('');
	const [snippetFields, setSnippetFields] = useState(SnippetFields);
	const [loading, setLoading] = useState(false);
	const [checkState, setCheckState] = useState({
		'@contract_title': false,
		'@contract_subtitle': false,
		'@term': false,
		'@fitting_fee': false,
		'@agent_fee': false,
		'@category': false,
		'@time_in': false,
		'@time_out': false,
		'@lunch_in': false,
		'@lunch_out': false
	} as any);

	const snippetTypeOptions = SnippetTypes.map(type => {
		return { label: type, value: type };
	});

	const form = useForm<SnippetModel>({
		defaultValues: { ...props.formData },
		resolver: yupResolver(snippetSchema()),
		mode: 'onChange'
	});

	useEffect(() => {
		form.reset(props.formData);
		setSnippetPreview(replaceSnippetFieldsWithInput(form?.getValues()?.preview));
	}, [props.formState]);

	const replaceTheInput = (domNode: any) => {
		if (domNode.attribs && domNode.attribs.id === 'replace') {
			const placeholder = domNode.attribs.placeholder ?? '';
			return <NabuTextbox
				placeholder={placeholder}
				data-cy='person-lastname'
			/>;
		}
	};

	const errors = form.formState.errors;

	const options = {
		replace: replaceTheInput
	};

	const handleTextEditorInputChange = (value: string, field: any) => {
		field.onChange(value);

		value = replaceSnippetFieldsWithInput(value);

		setSnippetPreview(value);
	};

	useEffect(() => {
		const preview = form.getValues().preview;

		SnippetFields.forEach((field: any) => {
			if(preview.includes(field.key)){
				setCheckState((currentData: any) => {
					return {
						...currentData,
						[field.key]: true
					};
				});
			}
		});
	}, [props.formState]);

	const notification = useNotification();

	const { mutate: addSnippet } = useMutation({
		mutationFn: saveSnippet,
		onSuccess: (res) => {
			if (res?.status === StatusCodes.OK) {
				notification.notify({
					type: 'success',
					message: form.getValues()?.id ? getNotificationText('Snippet', 'UPDATE') : getNotificationText('Snippet', 'CREATE')
				});
				props.refresh && props.refresh();
				props.onClose && props.onClose();
				form.reset();
			}else {
				notification.notify({
					type: 'error',
					message: form.getValues()?.id ? getNotificationText('Snippet', 'UPDATE', true) : getNotificationText('Snippet', 'CREATE', true)
				});
			}
			setLoading(false);
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Asset', 'UPDATE', true),
				type: 'error',
			});
		},
	});

	const onSubmit = (data: SnippetModel) => {
		if(!form.getValues().preview){
			notification.notify({
				type: 'error',
				message: 'Empty snippet is not allowed'
			});
			return;
		}
		addSnippet({ ...data });
		setLoading(true);
	};

	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

	const removeKey = (key: string) => {
		let value: string = form.getValues().preview;

		value = value.replaceAll(key, '');
		form.setValue('preview', value, { shouldDirty: true });
	};

	const handleCheckboxInputChange = (event: any, key: string) => {
		const insertText = !checkState[key];

		setCheckState((currentData: any) => {
			return {
				...currentData,
				[key]: !currentData[key]
			};
		});
		
		insertText ? insertAtCursor(key) : removeKey(key);
	};

	const editorRef = useRef<any>();

	const insertAtCursor = (valueToInsert: string) => {
		const editor = editorRef.current;
		if (editor) {
			editor.insertContent(valueToInsert);
		}
	};

	const handleAttributeSearchChange = (event: any) => {
		const searchKey: string = event.target.value.toLowerCase();
		const matchedKeys: any = [], notMatchedKeys: any = [];

		for(let i = 0; i < snippetFields.length; i++) {
			if (snippetFields[i].label.toLowerCase().match(searchKey) ) {
				matchedKeys.push(snippetFields[i]);
			}else{
				notMatchedKeys.push(snippetFields[i]);
			}
		}

		setSnippetFields(matchedKeys.concat(notMatchedKeys));
	};

	const location = useLocation();

	return (
		<>
			{(location.pathname.includes('edit') || location.pathname.includes('add')) ? <div className={styles.mainCardSnippet}>
				<>
					<Grid item container columnSpacing={3} >
						<Grid item sm={12} md={3}>
							<div className={styles.snippetSection}>
								<Grid item container columnSpacing={3}>
									<Grid item md={6}>
										<h3 className={styles.snippetTitle}>Attributes list</h3>
									</Grid>
									<Grid item md={6}>
										<FormControl fullWidth>
											<NabuTextbox placeholder="Search Attributes" onChange={handleAttributeSearchChange} />
										</FormControl>
									</Grid>
								</Grid>
								<div className={styles.contractFormBlockSnippet}>
									<div className={styles.formGroupSelect}>
										{snippetFields.map((field: any, index: number) => <ListItemButton key={index} role={undefined} dense className={styles.listItemBordder} sx={{p:0}}>
											<ListItemText style={{paddingLeft:'5px'}}>
												<div className={styles.inlineBox}>
													<span>{field?.label ?? ''}</span>
													<Checkbox {...label} checked={checkState[field.key]} onChange={(event: any) => handleCheckboxInputChange(event, field.key)} sx={{p:0}}/>
												</div> 
											</ListItemText>
										</ListItemButton>)}
									</div>
								</div>
							</div>
						</Grid>
						<Grid item sm={12} md={9} >
							<form onSubmit={form.handleSubmit(onSubmit)}>
							
								<Grid item container columnSpacing={3}>
									<Grid item md={3}>
										<FormControl fullWidth>
											<FormLabel>Snippet name<sup>*</sup></FormLabel>
											<Controller
												name={'name'}
												control={form.control}
												render={({ field }) => (
													<NabuTextbox
														placeholder='SNIPPET NAME'
														data-cy='person-lastname'
														{...field}
														error={errors?.name ? true : false}
														helperText={errors?.name?.message ?? ''}
													/>
												)}
											/>
										</FormControl>
									</Grid>
									<Grid item md={3}>
										<Box>
											<FormControl fullWidth>
												<FormLabel data-cy='representativeTypeLabel' id='select-field-demo-label' htmlFor='select-field-demo-button'>Snippet type<sup>*</sup></FormLabel>
												<Controller
													control={form.control}
													name='type'
													render={({ field }) => (
														<NabuSelect
															data-cy='snippetType' 
															placeholder='Snippet type'
															{...field}
															error={errors?.type ? true : false}
															helperText={errors?.type?.message ?? ''}
															options={snippetTypeOptions}
														/>
													)}
												/>
											</FormControl>
										</Box>
									</Grid>
								</Grid>
								<h3 className={styles.subHeading}>Create Snippet</h3>
								<div className={styles.previewSection}>
									<div className={styles.textEditor}>												
										<Controller
											control={form.control}
											name={'preview'}
											defaultValue=''
											render={({ field }) => (
												<TextEditor
													editorRef={editorRef}
													{...field}
													initialValue={form?.getValues()?.preview ?? ''}
													onChange={(value) => handleTextEditorInputChange(value, field)}
												/>
											)}
										/>
									</div>
									<br></br>
									{snippetPreview && <h3 className={styles.subHeading}>Preview  Snippet</h3>}
									{parse(snippetPreview, options)}
								</div>
								<div className={styles.snippetButton}>
									<Box dir='rtl' sx={{ mt: 2 }}>			
										<NabuButton variant='filled' type='submit'> {loading ? 'Submitting' : 'Save'} <Save /></NabuButton> &nbsp;
										<NabuButton shouldHandleConfirmAction={form.formState.isDirty} variant='outlined' onClick={props.onClose}>Cancel</NabuButton>
									</Box>
								</div>
							</form>
						</Grid>
					</Grid><br></br></>
			</div> : <div>
				<Box sx={{p:3, pt:0}}>
					<NabuCardDefault>
						<div className={styles.snippetView}>
							<div className={styles.textEditor} style={{ display: 'none' }}>												
								<Controller
									control={form.control}
									name={'preview'}
									defaultValue=''
									render={({ field }) => (
										<TextEditor
											editorRef={editorRef}
											{...field}
											initialValue={form?.getValues()?.preview ?? ''}
											onChange={(value) => handleTextEditorInputChange(value, field)}
										/>
									)}
								/>
							</div>
							{parse(snippetPreview ?? '', options)}
						</div>
					</NabuCardDefault>
				</Box>
			</div>}
		</>
	);
};

export default SnippetForm;
