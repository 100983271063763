import styles from './loader.module.scss';

export const Loader = (props: any) => {
	return (
		<div id="spinner" className={`${styles.spinner} ${props.showLoader? styles.visible :''}`}>
			<div className="loader">
				<div className={styles.lds_spinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			</div>
		</div>
	);
};