import { IFilterModel, IPagedResultModel } from '@/common/types/filterModel';
import { httpClient } from './httpClient';
import { IDeleteDocumentValue, IDocumentValue, IDocumentViewModel } from './models/company';
import { IDeleteTalentModel, IElementCastStackPositionUpdateModel, IElementMusicTrack } from './models/element';
import { IElementData } from './models/dashboard';

export const getElementsDetailsById = (id: any) => {
	return httpClient.get(`/Element/${id}`);
};

export const getElements = (filterModel: any) => {
	return httpClient.post<IPagedResultModel<IElementData>>('/Element/GetBy', filterModel);
};

export const createElement = (payload: any) => {
	return httpClient.post('Element/AddElement', payload);
};

export const updateElement = (payload: any) => {
	return httpClient.put('/Element/UpdateElement', payload);
};

export const deleteElement = (payload: any) => {
	return httpClient.delete('/Element/DeleteElement', payload);
};

export const getVendorInfo = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Element/GetVendorInfo/${id}`, filterModel);
};

export const getMusicVendorInfo = (musicVendorGetModel:any) => {
	return httpClient.post('/Element/GetMusicVendorInfo', musicVendorGetModel);
};

export const createVendor = (payload: any) => {
	return httpClient.post('/Element/AddVendorInfo', payload);
};

export const updateVendorInfo = (payload: any) => {
	return httpClient.put('/Element/UpdateVendorInfo', payload);
};

export const deleteVendorInfo = (payload: any) => {
	return httpClient.delete('/Element/DeleteVendorInfo', payload);
};

export const getRightsAcquired = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<any>>(`Element/GetRightsAcquired/${id}`, filterModel);
};

export const getRightsAcquiredById = (id) => {
	return httpClient.get(`Element/GetRightsAcquiredById/${id}`);
};

export const createRightsAcquired = (payload: any) => {
	return httpClient.post('/Element/AddRightsAcquired', payload);
};

export const updateRightsAcquired = (payload: any) => {
	return httpClient.put('/Element/UpdateRightsAcquired', payload);
};

export const deleteRightsAcquired = (payload: any) => {
	return httpClient.delete('/Element/Delete', payload);
};

export const getVendorList = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<any>>('/Element/GetVendorInfo', filterModel);
};

export const getAllMusic = (filterModel: any,elementId:any) => {
	return httpClient.post(`/Music/GetAll/${elementId}`, filterModel);
};

export const saveMusic = (formData:any) => {
	return httpClient.post('/Music/Add', formData);
};

export const deleteMusic = (params:any) => {
	return httpClient.delete(`/Music/Delete/${params.elementId}/${params.musicId}`);
};

export const saveMusicVendor = (params:any) => {
	return httpClient.post('/Music/SaveVendor',params);
};

export const editMusicVendor = (payload: any) => {
	return httpClient.post('/Music/EditVendor', payload);
};

export const deleteMusicVendor = (params:any) => {
	return httpClient.delete(`/Music/DeleteVendor/${params.musicId}/${params.licensorId}`);
};

export const getVendorInfoById = (licesorId:any) => {
	return httpClient.get(`/Element/GetVendorInfoById/${licesorId}`);
};

export const getMusicById = (musicId:any) => {
	return httpClient.get(`/Music/GetById/${musicId}`);
};

export const getAllElements = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IFilterModel>>('/Element/GetBy', filterModel);
};

export const getAllElementBasicDetails = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IFilterModel>>('/Element/GetBasicDetails', filterModel);
};

export const getAllAssetElements = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IFilterModel>>(`/Asset/GetAllAssetElements/${assetId}`, filters);
};

export const addRightsUsed = (payload: any) => {
	return httpClient.post('/Element/AddRightsUsed', payload);
};

export const saveRightsHolders = (payload: any) => {
	return httpClient.post('/Element/RightsHolder/Save', payload);
};

export const saveDeliverable = (payload: any) => {
	return httpClient.post('/Element/Deliverable/Save', payload);
};

export const getAllRightsHolders = (payload: any) => {
	return httpClient.post('/Element/RightsHolder/GetAll', payload);
};

export const getAllDeliverables = (payload: any) => {
	return httpClient.post('/Element/Deliverable/GetAll', payload);
};

export const deleteDeliverable = (payload: any) => {
	return httpClient.delete('/Element/Deliverable/Delete', payload);
};

export const deleteRightsHolder = (payload: any) => {
	return httpClient.delete('/Element/RightsHolder/Delete', payload);
};

export const getAllRightsUsed = (filterModel: IFilterModel, id: any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Element/GetAllRightsUsed/${id}`, filterModel);
};

export const deleteRightsUsed = (payload: any) => {
	return httpClient.delete('/Element/DeleteRightsUsed', payload);
};

export const getAllUsers = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<any>>('/User/GetBy', filterModel);
};

export const createElementDocument = (payload: IDocumentValue) => {
	return httpClient.post('Element/Document/Save', payload);
};

export const getElementDocumentList = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<IDocumentViewModel>>(`Element/Documents/${id}`, filterModel);
};

export const deleteElementDocument = (payload: IDeleteDocumentValue) => {
	return httpClient.delete('Element/Document/Delete', payload);
};

export const createTalent = (payload: any) => {
	return httpClient.post('Element/Talent/Save', payload);
};

export const getTalent = (filterModel: IFilterModel, elementId:any) => {
	return httpClient.post<IPagedResultModel<any>>(`Element/Talent/${elementId}`, filterModel);
};

export const deleteTalent = (body: IDeleteTalentModel) => {
	return httpClient.deleteV2('Element/Talent/Delete', {headers: {}, body}); 
};

export const getELementLinkedCastList = (filterModel: IFilterModel, assetId: string) => {
	return httpClient.post<IPagedResultModel<any>>(`Asset/GetElementLinkedCastList/${assetId}`, filterModel);
};

export const updateElementExpirationDate = (payload: any) => {
	return httpClient.post('Element/UpdateElementExpirationDate', payload);
};

export const updateElementNotes = (payload: any) => {	
	return httpClient.post('Element/UpdateElementNotes', payload);
};

export const getAllLinkedAssetByElement = (filterModel: IFilterModel, elementId: string) => {
	return httpClient.post<IPagedResultModel<any>>(`Element/GetAllLinkedAssetByElement/${elementId}`, filterModel);
};

export const getAllLinkedElementByAsset = (filterModel: IFilterModel, assetId: string) => {
	return httpClient.post<IPagedResultModel<any>>(`Element/GetAllLinkedElementByAsset/${assetId}`, filterModel);
};

export const addLinkedAsset = (payload: any) => {
	return httpClient.post('/Element/AddLinkedAsset', payload);
};

export const updateLinkedAsset = (payload: any) => {
	return httpClient.post('/Element/UpdateLinkedAsset', payload);
};

export const unLinkLinkedAsset = (payload: any) => {
	return httpClient.post('/Element/UnlinkLinkedAsset', payload);
};

export const unLinkLinkedAssetEditVersion = (payload: any) => {
	return httpClient.post('/Element/UnLinkLinkedAssetEditVersion', payload);
};

export const getRenewalTerms = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<any>>(`Element/GetRenewalTerms/${id}`, filterModel);
};

export const addRenewalTerms = (payload: any) => {
	return httpClient.post('/Element/AddRenewalTerms', payload);
};

export const updateRenewalDate = (payload: any) => {
	return httpClient.post('/Element/UpdateRenewalDate', payload);
};

export const getElementsTracks = (filterModel: IFilterModel, elementId: string) => {
	return httpClient.post<IPagedResultModel<IElementMusicTrack>>(`Element/Track/GetBy/${elementId}`, filterModel);
};

export const getLinkedElementsForEditVersionAsset = (filterModel: IFilterModel, id: any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Element/GetLinkedElementsForEditVersionAsset/${id}`, filterModel);
};

export const getRelatedCompanies = (filterModel: any) => {
	return httpClient.post('/Element/Relationships/Companies', filterModel);
};

export const GetRelatedPersons = (filterModel: any) => {
	return httpClient.post('/Element/Relationships/Persons', filterModel);
};

export const GetRelatedAssets = (filterModel: any) => {
	return httpClient.post('/Element/Relationships/Assets', filterModel);
};

export const updateElementCastListStackPosition = (payload: IElementCastStackPositionUpdateModel) => {
	return httpClient.post('/Element/Cast/StackPosition', payload);
};