import { forwardRef } from 'react';
import { FormHelperText, Input } from '@mui/material';

// eslint-disable-next-line react/display-name
export const NabuTextbox = forwardRef((props: any, ref: any) => {
	props = { ...props };
	// useImperativeHandle(ref, () => ({
	// 	setFocus: () => {
	// 		inputRef?.current?.focus();
	// 	}
	// }));
	const handleInputChange = (event) => {
		const input = event.target;
		let value = event.target.value;
		switch (props.convertCase) {
		case 'upperCase':
			value = value.toUpperCase();
			break;
		case 'lowerCase':
			value = value.toLowerCase();
			break;
		case 'default':
			value = event.target.value;
			break;
		default:
			value = value.toUpperCase();
			break;
		}

		if(input?.type !== 'number'){
			const cursorPosition = input.selectionStart;
			input.value = value;
			input.setSelectionRange(cursorPosition, cursorPosition);
		}		
		props.onChange && props.onChange({ ...event, target: { ...event.target, value } });
	};

	return (
		<>
			{/* <FormControl fullWidth data-cy={`${props.label}`}> */}
			{/* <FormLabel data-cy={`${props.label}`}>{props.label} {props.required && <sup>*</sup>}</FormLabel> */}
			<Input type='search' data-cy={`${props.label}`} {...props} onChange={handleInputChange} ref={ref} placeholder={props.placeholder} />
			{props.error && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
			{/* </FormControl> */}
		</>
	);
});
