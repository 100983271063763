import useNotification from '@/common/hooks/useNotification';
import { notificationMessageForFormOpenState } from '@/common/utils/utils';
import { IStore } from '@/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const NavigationBlocker = (props: any) => {
	const location = useLocation();
	const notification = useNotification();
	const formName = useSelector((state: IStore) => state.appContext?.formName);

	const showNotification = () => {
		notification.notify({
			type: 'error',
			message: notificationMessageForFormOpenState(formName)
		});
	};

	useEffect(() => {
		const handlePopState = () => {
			// Prevent the default behavior of the back button
			showNotification();
			window.history.pushState(null, '', window.location.href);
		};

		if(props.blockNavigation) {
			// Push a new state to the history stack
			window.history.pushState(null, '', window.location.href);
  
			// Add the popstate event listener to catch back navigation
			window.addEventListener('popstate', handlePopState);
  
			return () => {
				// Clean up the event listener
				window.removeEventListener('popstate', handlePopState);
			};
		}
	}, [props.blockNavigation, location.pathname]);

	return null;
};

export default NavigationBlocker;