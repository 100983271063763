import * as schema from 'yup';
import { ChangePasswordModel, LoginFormModel, ResetFormModel, ResetNewPasswordModel } from '../models';

export const formSchema = schema.object<Partial<Record<keyof LoginFormModel, schema.AnySchema>>>({
	UserName: schema
		.string()
		.required('email required'),
	// no match as of now, suggest by naveen
	// .matches(
	// 	/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
	// 	'invalid_email'
	// ),
	Password: schema.string().required('This field is required'),
});



export const changePasswordFormSchema= schema.object<Partial<Record<keyof ChangePasswordModel, schema.AnySchema>>>({
	UserName: schema
		.string()
		// no match as of now, suggest by naveen
		// .matches(
		// 	/^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/,
		// 	'invalid_username'
		// )
		.required('An username is required'),
	OldPassword: schema.string().required('Password is required'),
	NewPassword: schema.string().required('Password is required').matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,}$/,'New Password must be 8 characters or more and include at least one uppercase letter, one lowercase letter, one number, and one special character.'),
});

export const resetFormSchema = schema.object<Partial<Record<keyof ResetFormModel, schema.AnySchema>>>({
	userName: schema.string().required('User Name required'),
	email: schema.string().required('This field is required')
		.matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Please enter a valid email.')
});

export const resetNewPasswordSchema = schema.object<Partial<Record<keyof ResetNewPasswordModel, schema.AnySchema>>>({
	newPassword: schema.string().required('Password is required').matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,}$/,'New Password must be 8 characters or more and include at least one uppercase letter, one lowercase letter, one number, and one special character.'),
	confirmPassword: schema.string().required('Confirm Password is required').oneOf([schema.ref('newPassword')], 'Passwords must match').min(8, 'Password should be 8 characters or more')
});
