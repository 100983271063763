import { Loader } from '@/common/components/atoms/loader/loader';
import store from '@/store';
import { setDisableLoader, setLoader } from '@/store/app/reducer';
import { IAppContextModel } from '@/store/app/types';
import { ReactNode, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type LoaderType = { showLoader: (isVisible: boolean) => void };

export const LoaderContext = createContext({} as LoaderType);

interface Props {
	children: ReactNode;
}

export const LoaderContextProvider = ({ children }: Props) => {
	const appContext: IAppContextModel = useSelector(
		(state: any) => state.appContext
	);
	const dispatch = useDispatch();

	const showLoader = (value: boolean) => {
		dispatch(setLoader(value));
	};

	return (
		<LoaderContext.Provider value={{ showLoader }}>
			{children}
			<Loader showLoader={appContext.showLoader} />
		</LoaderContext.Provider>
	);
};

export const disableLoaderForOnce = () => {
	store.dispatch(setDisableLoader(true));
	setTimeout(() => store.dispatch(setDisableLoader(false))); // reset back after a delay
};
