import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Accordion } from '@mui/material';
import React from 'react';
import { useState } from 'react';

interface Props {
	id: number;
	type: string;
	component?: any;
	children: any;
}

const Snippet = (props: Props) => {
	const [expanded, setExpanded] = useState<boolean>(false);

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: 'S' + props.id,
		data: {
			...props
		}
	});

	const handleChange = () => {
		setExpanded(prev => !prev);
	};

	const style = {
		transition,
		transform: CSS.Transform.toString(transform)
	};

	if(isDragging){
		return <Accordion ref={setNodeRef} {...listeners} {...attributes} expanded={expanded} onChange={handleChange}>
			{props.children}
		</Accordion>;
	}

	return (
		<Accordion ref={setNodeRef} expanded={expanded} style={style} {...listeners} {...attributes} onChange={handleChange}>
			{props.children}
		</Accordion>
	);
};

export default Snippet;