import { TemplatePart } from '@/common/constants/constants';

export class TemplateModel {
	id?: string;
	unionType: string = '';
	templateName: string = '';
	templateType: string = '';
	templateId?: string;
	predefinedTemplateName: string = '';
	companyId?: string;
	companyName?: string;
	formParts: TemplatePart[] = [];
}

export class TemplateFilterModel {
	searchText: string = '';
}