import {
	Grid
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TemplateForm from '../../components/templateForm';
import { TemplateModel } from '../../shared/models/template';
import TemplateInfoSubheader from '../subheader';
import { Page } from '@/common/components/templates/pages/page/page';

const SaveTemplate = () => {
	const { templateId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const templateData = location?.state?.formData ?? new TemplateModel();

	const handleClose = () => {
		navigate('/contracts/templates/all');
	};

	return (
		<Page SubHeader={<TemplateInfoSubheader templateName={location.pathname.includes('template-view') ? 'View template' : (templateId ? 'Edit template' : 'Create Template')} templateId={templateId ?? ''}/>} hasChild={true}><>
			<Grid sx={{mb:0}}>
				<TemplateForm formData={templateData} onClose={handleClose} />
			</Grid>
		</></Page>
	);
};

export default SaveTemplate;