
import NabuButton from '@/common/components/atoms/button/button';
import { NabuCardDefault } from '@/common/components/molecules/cardWhite/cardWhite';
import DataTable from '@/common/components/molecules/dataTable/dataTable';
import { NabuSearchFilters } from '@/common/components/molecules/searchFIlter/searchFIlter';
import { DefaultPageSize } from '@/common/constants/constants';
import { ITableActionMenuModel } from '@/common/types/dataTable';
import { Add, Article, DeleteForever, Edit, Save, ViewCarousel, } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
const PayrollVenorCompany = () => {

	const [state, ] = useState({
		payrollVendor: [
			{ 
				name: 'Team Companies', 
				cNo: 'DELL 123', 
				serviceType: 'Talent Payroll', 
				serviceSubType: '-', 
				talentType: 'Union', 
				rateFees: 'Rate/Fees' ,
				handlingApplied: 'Gross Wages',
				description: 'Non Union Payroll',
				guaranteeHandlingCap: '$500 per installement',
				workersComp: '2.2%',
				payrollTaxes: '16%',
				effectDate: '01-01-2022',
				termDate: '31-12-2022',
				vendorAgreement: 'Vendor Agreement',
			},
		],
	});

	const payrollVendorColumns = [
		{ id: 'name', label: 'Vendor Name' },
		{ id: 'cNo', label: 'Client No' },
		{ id: 'serviceType', label: 'Service Type' },
		{ id: 'serviceSubType', label: 'Service Subtype' },
		{ id: 'talentType', label: 'Talent Type' },
		{ id: 'rateFees', label: 'Rate/Fees' },
		{ id: 'handlingApplied', label: 'Handling Applied to' },
		{ id: 'description', label: 'Description' },
		{ id: 'guaranteeHandlingCap', label: 'Guarantee Handling Cap' },
		{ id: 'workersComp', label: 'Workers Comp %' },
		{ id: 'payrollTaxes', label: 'Payroll Taxes %' },
		{ id: 'effectDate', label: 'Effect Date' },
		{ id: 'termDate', label: 'Term Date' },
		{ id: 'vendorAgreement', label: 'Vendor Agreement' },
		{ id: 'actions', label: '' },
	];

	const RateFees = (record: any) => {
		return <div onClick={() => {
			setOpen(prev => !prev);
			setRidx(record?.rowIndex ?? -1);
		}}><Box display={'flex'} alignItems={'center'} marginLeft={'10px'}>8%  <NabuButton variant='text'><ViewCarousel/> View</NabuButton></Box></div>;
	};

	const primaryActions: ITableActionMenuModel[] = [
		{
			title: () => 'Edit',
			icon: () => Edit,
			iconColor: 'primary',
			'data-cy': 'dealPoint_edit',
		},
		{
			title: () => 'Remove',
			icon: () => DeleteForever,
			iconColor: 'error',
			'data-cy': 'dealPoint_Remove',
		},
	];

	const PayrollAgreement = (row: any) => {
		return(
			<div>
				<NabuButton variant='text'><Article/> View</NabuButton>
			</div>
		);
	};

	// Step 1: Initialize state to track visibility
	const [isVisible, setIsVisible] = useState(false);
	
	// Step 2: Function to toggle visibility
	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};
		

	const [open, setOpen] = useState(false);
	const [rIdx, setRidx] = useState(-1);

	return(
		<div>
			<Grid item container columnSpacing={3}>
				<Grid item md={6}>
					<NabuSearchFilters/>
				</Grid>
				<Grid item md={6} dir="rtl">
					<NabuButton variant='filled' onClick={toggleVisibility}> Payroll Vendor <Add/></NabuButton>
				</Grid>
				{isVisible && (
					<Grid item md={12}>
					
						<NabuCardDefault>
							<Grid item container>
								<Grid item md={12}>
									<h3>Add Payroll Vendor </h3>
								</Grid>
								<Grid item md={12} dir="rtl">
									<NabuButton variant='outlined'>Cancel</NabuButton>&nbsp;
									<NabuButton variant='filled'>Save <Save/></NabuButton>
								</Grid>
							</Grid>
						</NabuCardDefault>
				
					</Grid>
				)}
			</Grid>

			<NabuCardDefault>

				<DataTable
					data-cy='payrollVendor-table-grid'
					rows={state.payrollVendor} // Ensure this property is named 'rows'
					columns={payrollVendorColumns}
					actionsMenu={primaryActions}
					currentPage={0}
					rowsPerPage={DefaultPageSize}
					tableName='payrollVendor' totalRowsCount={0}
					inlineRows={{
						isOpen: open,
						slot: 'rateFees',
						rowIndex: rIdx,
					}}
				>
					<RateFees slot='rateFees'/>
					<PayrollAgreement slot='vendorAgreement'/>
				</DataTable>
			</NabuCardDefault>

		</div>
	);
};

export default PayrollVenorCompany;