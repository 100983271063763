import { CAST_ABBREVIATION_CONTROL, COMPANY_INFO_TAB, COMPANY_MAIN_TYPE, ELEMENT_INFO_TAB, ElementType, MISCELLANEOUS_TYPE, PEOPLE_INFO_TAB, PERSON_TYPE, RightsCoverageMainTypes, RightsCoverageTypes, SESSION_ADDITIONAL_COLUMN, SESSION_MEDIA_TYPE, SessionCycleLength, UNION_TYPE } from '../enums';
import fileDefault from '../../assets/img/fileIcons/file-blank-solid-240.png';
import fileCSS from '../../assets/img/fileIcons/file-css-solid-240.png';
import filePdf from '../../assets/img/fileIcons/file-pdf-solid-240.png';
import filePng from '../../assets/img/fileIcons/file-png-solid-240.png';
import fileDoc from '../../assets/img/fileIcons/file-doc.png';
import fileCsv from '../../assets/img/fileIcons/file-csv.png';
import fileXml from '../../assets/img/fileIcons/file-xml.png';

export const DefaultPageSize = 25;
export const DebounceTimeSearchMillis = 500;

export const phoneNumberLabelOptions = {
	ACCOUNTING: 'ACCOUNTING',
	COMPANY: 'COMPANY',
	MAIN: 'MAIN',
	FAX: 'FAX',
	INDIVIDUAL: 'INDIVIDUAL (DIRECT DIAL)',
	DEPARTMENT: 'DEPARTMENT',
	TOLLFREE: 'TOLL-FREE NUMBER',
	FAXNUMBER: 'FAX NUMBER',
};

export const exclusivityCategories = {
	ACCOUNTING_SERVICES: 'ACCOUNTING SERVICES',
	AEROSPACE_DEFENCE: 'AEROSPACE & DEFENCE',
	AGRICULTURE: 'AGRICULTURE',
	AIRLINE_SERVICES: 'AIRLINE SERVICES',
	ALCOHOLIC_BEVERAGES: 'ALCOHOLIC BEVERAGES',
	ALCOHOLIC_BEVERAGES_BEER: 'ALCOHOLIC BEVERAGES - BEER',
	ALCOHOLIC_BEVERAGES_LIQUOR: 'ALCOHOLIC BEVERAGES - LIQUOR',
	ALCOHOLIC_BEVERAGES_WINE: 'ALCOHOLIC BEVERAGES - WINE',
	APPAREL_FOOTWEAR: 'APPAREL & FOOTWEAR',
	ARTS: 'ARTS',
	AUTO_DEALERSHIPS: 'AUTO DEALERSHIPS',
	AUTO_PARTS: 'AUTO PARTS',
	AUTO_REPAIR_SERVICES: 'AUTO REPAIR SERVICES',
	AUTOMOTIVE: 'AUTOMOTIVE',
	BANKING_COMMERCIAL: 'BANKING - COMMERCIAL',
	BANKING_CREDIT_UNIONS: 'BANKING - CREDIT UNIONS',
	BANKING_PERSONAL: 'BANKING - PERSONAL',
	BEAUTY_PRODUCTS: 'BEAUTY PRODUCTS',
	BEVERAGES_ENERGY_DRINKS: 'BEVERAGES - ENERGY DRINKS',
	BEVERAGES_SOFT_DRINKS: 'BEVERAGES - SOFT DRINKS',
	BUSINESS_SERVICES: 'BUSINESS SERVICES',
	CABLE_SATELLITE_TV: 'CABLE & SATELLITE TV',
	CELLPHONE_CARRIERS: 'CELLPHONE CARRIERS',
	CLOTHING: 'CLOTHING',
	CLOTHING_CHILDREN_KIDS: 'CLOTHING - CHILDREN/KIDS',
	CLOTHING_MENS: 'CLOTHING - MENS',
	CLOTHING_WOMENS: 'CLOTHING - WOMENS',
	COMPUTER_HARDWARE: 'COMPUTER HARDWARE',
	COMPUTER_SOFTWARE: 'COMPUTER SOFTWARE',
	CONSTRUCTION: 'CONSTRUCTION',
	CONSUMER_DURABLES: 'CONSUMER DURABLES',
	CONSUMER_ELECTRONICS: 'CONSUMER ELECTRONICS',
	CONSUMER_PACKAGED_GOODS: 'CONSUMER PACKAGED GOODS',
	CONSUMER_SERVICES: 'CONSUMER SERVICES',
	DEPARTMENT_STORES: 'DEPARTMENT STORES',
	E_COMMERCE: 'E-COMMERCE',
	EDUCATION: 'EDUCATION',
	EDUCATION_COLLEGE_UNIVERSITY: 'EDUCATION - COLLEGE/UNIVERSITY',
	ENERGY_SERVICES: 'ENERGY SERVICES',
	ENTERTAINMENT: 'ENTERTAINMENT',
	ENVIRONMENTAL_SERVICES: 'ENVIRONMENTAL SERVICES',
	FAST_FOOD_SERVICES: 'FAST FOOD SERVICES',
	FINANCIAL_SERVICES: 'FINANCIAL SERVICES',
	FINANCIAL_SERVICES_LOANS: 'FINANCIAL SERVICES - LOANS',
	FINANCIAL_SERVICES_RETIREMENT: 'FINANCIAL SERVICES - RETIREMENT',
	FOODS_BEVERAGES: 'FOODS & BEVERAGES',
	FOOD_SNACKS: 'FOOD SNACKS',
	GAMBLING_CASINOS: 'GAMBLING - CASINOS',
	GAMBLING_LOTTERY: 'GAMBLING - LOTTERY',
	GOVERNMENT_SERVICES: 'GOVERNMENT SERVICES',
	GROCERY_STORES: 'GROCERY STORES',
	HEALTHCARE: 'HEALTHCARE',
	HEALTHCARE_DENTISTS: 'HEALTHCARE - DENTISTS',
	HEALTHCARE_DOCTORS: 'HEALTHCARE - DOCTORS',
	HEAT_AIR_COND_SERVICES: 'HEAT & AIR CONDITIONING SERVICES',
	HOME_REPAIR_SERVICES: 'HOME REPAIR SERVICES',
	HOSPITALS: 'HOSPITALS',
	HOTEL_HOSPITALITY: 'HOTEL & HOSPITALITY',
	HOUSEHOLD_GOODS: 'HOUSEHOLD GOODS',
	HOUSEWARES: 'HOUSEWARES',
	INSURANCE_AUTO: 'INSURANCE - AUTO',
	INSURANCE_BUSINESS: 'INSURANCE - BUSINESS',
	INSURANCE_CAR: 'INSURANCE - CAR',
	INSURANCE_HOME: 'INSURANCE - HOME',
	INTERNET_PROVIDERS: 'INTERNET PROVIDERS',
	LEGAL_SERVICES: 'LEGAL SERVICES',
	MANUFACTURING_INDUSTRIAL_GOODS_SERVICES: 'MANUFACTURING, INDUSTRIAL GOODS & SERVICES',
	MARKETING_SERVICES: 'MARKETING SERVICES',
	MEDIA: 'MEDIA',
	MEDICAL_SUPPLIES: 'MEDICAL SUPPLIES',
	MORTGAGE_SERVICES: 'MORTGAGE SERVICES',
	MOVIES_CINEMA: 'MOVIES/CINEMA',
	MUSIC: 'MUSIC',
	NON_PROFITS: 'NON-PROFITS',
	ONLINE_GAMBLING: 'ONLINE - GAMBLING',
	ONLINE_REAL_ESTATE_SERVICES: 'ONLINE - REAL ESTATE SERVICES',
	ONLINE_RETAILER_SHOPPING: 'ONLINE - RETAILER/SHOPPING',
	ONLINE_SCHOOLS_EDUCATION: 'ONLINE - SCHOOLS/EDUCATION',
	ONLINE_TECHNOLOGY_PROVIDER: 'ONLINE - TECHNOLOGY PROVIDER',
	ONLINE_TRAVEL_SERVICES: 'ONLINE - TRAVEL SERVICES',
	PEST_CONTROL_SERVICES: 'PEST CONTROL SERVICES',
	PET_FOOD_SUPPLIES: 'PET FOOD & SUPPLIES',
	PHARMACEUTICALS: 'PHARMACEUTICALS',
	PLUMBING_SERVICES: 'PLUMBING SERVICES',
	POLITICAL_ADS: 'POLITICAL ADS',
	PROFESSIONAL_SERVICES: 'PROFESSIONAL SERVICES',
	PUBLIC_RELATIONS: 'PUBLIC RELATIONS',
	QUICK_SERVICE_RESTAURANT:'QUICK SERVICE RESTAURANT',
	REAL_ESTATE: 'REAL ESTATE',
	RESTAURANT_CASUAL_DINING: 'RESTAURANT - CASUAL DINING',
	RESTAURANT_FINE_DINING: 'RESTAURANT - FINE DINING',
	RESTAURANT_BARS: 'RESTAURANT - BARS',
	RETAIL_ELECTRONICS: 'RETAIL - ELECTRONICS',
	RETAIL_FURNITURE: 'RETAIL - FURNITURE',
	RETAIL_HOME_IMPROVEMENT_STORES: 'RETAIL - HOME IMPROVEMENT STORES',
	RETAIL_SPORTING_GOODS: 'RETAIL - SPORTING GOODS',
	RETAILER_BOOKS: 'RETAILER - BOOKS',
	SHIPPING_SERVICES: 'SHIPPING SERVICES',
	SPORTS: 'SPORTS',
	TECHNOLOGY: 'TECHNOLOGY',
	TELECOMMUNICATIONS: 'TELECOMMUNICATIONS',
	TOBACCO_PRODUCTS: 'TOBACCO PRODUCTS',
	TRAVEL_TOURISM: 'TRAVEL & TOURISM',
	TV: 'TV',
	UTILITY_SERVICES: 'UTILITY SERVICES',
	VEGETABLES_FRUITS: 'VEGETABLES & FRUITS',
	VITAMINS: 'VITAMINS',
	WASTE_DISPOSAL_SERVICES: 'WASTE DISPOSAL SERVICES',
};

export const PassportTypes = [
	'Regular',
	'Card',
	'Diplomatic'
];

export const MasterTypes = [
	'Large',
	'Cycle',
	'AUDIO PSPC'
];

export const MaritalStatusTypes = [
	'Single or married filing Separately',
	'Married Filing jointly (Or Qualifying Widow(er))',
	'Head of Household'
];

export const FederalTaxClassification = [
	'Individual/Sole Proprietor or single – member LLC',
	'C corporation',
	'S corporation',
	'Partnership',
	'Trust/Estate',
	'LLC'
];

export const ManagedByOptions = [
	'Advertiser',
	'Ad Agency',
	'Production'
];

export const InsuranceTypes = [
	'Auto insurance – NON – owned & Hired Auto',
	'General Liability',
	'Production insurance',
	'Travel Insurance',
	'Workers Compensation Insurance',
	'Errors and Omissions (E&O) Insurance',
	'Equipment Insurance',
	'Media Liability Insurance',
	'Rental Equipment Insurance',
	'Stunt Insurance'
];

export const contactSubTypeOptions = {
	BILLING: 'BILLING',
	INVOICE_LEGAL: 'INVOICE, LEGAL',
	SERVICE_AGREEMENTS: 'SERVICE AGREEMENTS',
};

export const addressTypeOptions = {
	ACCOUNTING_INVOICES: 'ACCOUNTING/INVOICES',
	MAILING: 'MAILING',
	PRIMARY: 'PRIMARY',
	REGIONAL_OFFICE: 'REGIONAL OFFICE',
	OTHER: 'OTHER'
};

export const personType = {
	AGENT: 'AGENT',
	STAFF: 'STAFF',
	CREW: 'CREW',
	TALENT: 'TALENT',
	VENDOR: 'VENDOR',
};

export const relationship = {
	FATHER: 'FATHER',
	GRANDPARENT: 'GRANDPARENT',
	GUARDIAN: 'GUARDIAN',
	MOTHER: 'MOTHER',
	SIBLING: 'SIBLING',
	SPOUSE: 'SPOUSE',
};

export const gender = {
	F: 'FEMALE',
	M: 'MALE',
	NB: 'NON BINARY',
};

export const DateFormat = 'MM/DD/YYYY';

export const exclusivityCategoryOptions = [
	{ label: 'Accounting Services', value: 'accounting_services' },
	{ label: 'Aerospace & Defence', value: 'aerospace_defence' },
	{ label: 'Agriculture', value: 'agriculture' },
	{ label: 'Airline Services', value: 'airline_services' },
	{ label: 'Alcoholic Beverages', value: 'alcoholic_beverages' },
	{ label: 'Alcoholic Beverages - BEER', value: 'alcoholic_beverages_beer' },
	{
		label: 'Alcoholic Beverages - Liquor',
		value: 'alcoholic_beverages_liquor',
	},
	{ label: 'Alcoholic Beverages - Wine', value: 'alcoholic_beverages_wine' },
	{ label: 'Apparel & Footwear', value: 'apparel_footwear' },
	{ label: 'Arts', value: 'arts' },
	{ label: 'Auto Dealerships', value: 'auto_dealerships' },
	{ label: 'Auto Parts', value: 'auto_parts' },
	{ label: 'Auto Repair Services', value: 'auto_repair_services' },
	{ label: 'Automotive', value: 'automotive' },
	{ label: 'Banking - Commercial', value: 'banking_commercial' },
	{ label: 'Banking - Credit Unions', value: 'banking_credit_unions' },
	{ label: 'Banking - Personal', value: 'banking_personal' },
	{ label: 'Beauty Products', value: 'beauty_products' },
	{ label: 'Beverages - Energy Drinks', value: 'beverages_energy_drinks' },
	{ label: 'Beverages - Soft Drinks', value: 'beverages_soft_drinks' },
	{ label: 'Business Services', value: 'business_services' },
	{ label: 'Cable & Satellite TV', value: 'cable_satellite_tv' },
	{ label: 'CellPhone Carriers', value: 'cellphone_carriers' },
	{ label: 'Clothing', value: 'clothing' },
	{ label: 'Clothing - Children/Kids', value: 'clothing_children_kids' },
	{ label: 'Clothing - Mens', value: 'clothing_mens' },
	{ label: 'Clothing - Womens', value: 'clothing_womens' },
	{ label: 'Computer Hardware', value: 'computer_hardware' },
	{ label: 'Computer Software', value: 'computer_software' },
	{ label: 'Construction', value: 'construction' },
	{ label: 'Consumer Durables', value: 'consumer_durables' },
	{ label: 'Consumer Electronics', value: 'consumer_electronics' },
	{ label: 'Consumer Packaged Goods', value: 'consumer_packaged_goods' },
	{ label: 'Consumer Services', value: 'consumer_services' },
	{ label: 'Department Stores', value: 'department_stores' },
	{ label: 'E-Commerce', value: 'e_commerce' },
	{ label: 'Education', value: 'education' },
	{
		label: 'Education - College/University',
		value: 'education_college_university',
	},
	{ label: 'Energy Services', value: 'energy_services' },
	{ label: 'Entertainment', value: 'entertainment' },
	{ label: 'Environmental Services', value: 'environmental_services' },
	{ label: 'Fast Food Services', value: 'fast_food_services' },
	{ label: 'Financial Services', value: 'financial_services' },
	{ label: 'Financial Services - Loans', value: 'financial_services_loans' },
	{
		label: 'Financial Services - Retirement',
		value: 'financial_services_retirement',
	},
	{ label: 'Foods & Beverages', value: 'foods_beverages' },
	{ label: 'Food Snacks', value: 'food_snacks' },
	{ label: 'Gambling - Casinos', value: 'gambling_casinos' },
	{ label: 'Gambling - Lottery', value: 'gambling_lottery' },
	{ label: 'Government Services', value: 'government_services' },
	{ label: 'Grocery Stores', value: 'grocery_stores' },
	{ label: 'Healthcare', value: 'healthcare' },
	{ label: 'Healthcare - Dentists', value: 'healthcare_dentists' },
	{ label: 'Healthcare - Doctors', value: 'healthcare_doctors' },
	{
		label: 'Heat & Air Conditioning Services',
		value: 'heat_air_cond_services',
	},
	{ label: 'Home Repair Services', value: 'home_repair_services' },
	{ label: 'Hospitals', value: 'hospitals' },
	{ label: 'Hotel & Hospitality', value: 'hotel_hospitality' },
	{ label: 'Household Goods', value: 'household_goods' },
	{ label: 'Housewares', value: 'housewares' },
	{ label: 'Insurance - Auto', value: 'insurance_auto' },
	{ label: 'Insurance - Business', value: 'insurance_business' },
	{ label: 'Insurance - Car', value: 'insurance_car' },
	{ label: 'Insurance - Home', value: 'insurance_home' },
	{ label: 'Internet Providers', value: 'internet_providers' },
	{ label: 'Legal Services', value: 'legal_services' },
	{
		label: 'Manufacturing, Industrial Goods & Services',
		value: 'manufacturing_industrial_goods_services',
	},
	{ label: 'Marketing Services', value: 'marketing_services' },
	{ label: 'Media', value: 'media' },
	{ label: 'Medical Supplies', value: 'medical_supplies' },
	{ label: 'Mortgage Services', value: 'mortgage_services' },
	{ label: 'Movies/Cinema', value: 'movies_cinema' },
	{ label: 'Music', value: 'music' },
	{ label: 'Non-Profits', value: 'non_profits' },
	{ label: 'Online - Gambling', value: 'online_gambling' },
	{
		label: 'Online - Real Estate Services',
		value: 'online_real_estate_services',
	},
	{ label: 'Online - Retailer/Shopping', value: 'online_retailer_shopping' },
	{ label: 'Online - Schools/Education', value: 'online_schools_education' },
	{
		label: 'Online - Technology Provider',
		value: 'online_technology_provider',
	},
	{ label: 'Online - Travel Services', value: 'online_travel_services' },
	{ label: 'Pest Control Services', value: 'pest_control_services' },
	{ label: 'Pet Food & Supplies', value: 'pet_food_supplies' },
	{ label: 'Pharmaceuticals', value: 'pharmaceuticals' },
	{ label: 'Plumbing Services', value: 'plumbing_services' },
	{ label: 'Political Ads', value: 'political_ads' },
	{ label: 'Professional Services', value: 'professional_services' },
	{ label: 'Public Relations', value: 'public_relations' },
	{ label: 'Quick Service Restaurants', value: 'QUICK_SERVICE_RESTAURANT' },
	{ label: 'Real Estate', value: 'real_estate' },
	{ label: 'Restaurant - Casual Dining', value: 'restaurant_casual_dining' },
	{ label: 'Restaurant - Fine Dining', value: 'restaurant_fine_dining' },
	{ label: 'Restaurant - Bars', value: 'restaurant_bars' },
	{ label: 'Retail - Electronics', value: 'retail_electronics' },
	{ label: 'Retail - Furniture', value: 'retail_furniture' },
	{
		label: 'Retail - Home Improvement Stores',
		value: 'retail_home_improvement_stores',
	},
	{ label: 'Retail - Sporting Goods', value: 'retail_sporting_goods' },
	{ label: 'Retailer - Books', value: 'retailer_books' },
	{ label: 'Shipping Services', value: 'shipping_services' },
	{ label: 'Sports', value: 'sports' },
	{ label: 'Technology', value: 'technology' },
	{ label: 'Telecommunications', value: 'telecommunications' },
	{ label: 'Tobacco Products', value: 'tobacco_products' },
	{ label: 'Travel & Tourism', value: 'travel_tourism' },
	{ label: 'TV', value: 'tv' },
	{ label: 'Utility Services', value: 'utility_services' },
	{ label: 'Vegetables & Fruits', value: 'vegetables_fruits' },
	{ label: 'Vitamins', value: 'vitamins' },
	{ label: 'Waste Disposal Services', value: 'waste_disposal_services' },
];

export const personTypes = {
	[PERSON_TYPE.AGENT]: {
		visibleTabs: [
			PEOPLE_INFO_TAB.ADDRESS, 
			PEOPLE_INFO_TAB.CONTACT,
		],
	},
	[PERSON_TYPE.CREW]: {
		visibleTabs: [
			PEOPLE_INFO_TAB.ADDRESS,
			PEOPLE_INFO_TAB.CONTACT,
			PEOPLE_INFO_TAB.EMERGENCY_CONTACT,
			PEOPLE_INFO_TAB.TRAVEL_INFO,
			PEOPLE_INFO_TAB.INDIVIDUAL_ID_WITH_PAYROLL_VENDOR,
			PEOPLE_INFO_TAB.BANK_INFO,
			PEOPLE_INFO_TAB.W4_INDIVIDUAL_WITHHOLDING,
			PEOPLE_INFO_TAB.W9_CORP_BUS_WITHHOLDING,
			PEOPLE_INFO_TAB.W8BEN,
			PEOPLE_INFO_TAB.BANKING_TAXES,
		]
	},
	[PERSON_TYPE.TALENT]: {
		visibleTabs: [
			PEOPLE_INFO_TAB.ADDRESS,
			PEOPLE_INFO_TAB.CONTACT,
			PEOPLE_INFO_TAB.LOAN_OUT_CORPORATION_INFO,
			PEOPLE_INFO_TAB.EMERGENCY_CONTACT,
			PEOPLE_INFO_TAB.SPECIFICATIONS,
			PEOPLE_INFO_TAB.TRAVEL_INFO,
			PEOPLE_INFO_TAB.INDIVIDUAL_ID_WITH_PAYROLL_VENDOR,
			PEOPLE_INFO_TAB.BANK_INFO,
			PEOPLE_INFO_TAB.W4_INDIVIDUAL_WITHHOLDING,
			PEOPLE_INFO_TAB.W9_CORP_BUS_WITHHOLDING,
			PEOPLE_INFO_TAB.W8BEN,
			PEOPLE_INFO_TAB.AGENT_INFO,
			PEOPLE_INFO_TAB.BANKING_TAXES,
			PEOPLE_INFO_TAB.PHOTOS
		],
	},
	[PERSON_TYPE.STAFF]: {
		visibleTabs: [
			PEOPLE_INFO_TAB.BANKING_TAXES,
			PEOPLE_INFO_TAB.TRAVEL_INFO,
			PEOPLE_INFO_TAB.ADDRESS,
			PEOPLE_INFO_TAB.CONTACT,
			PEOPLE_INFO_TAB.EMERGENCY_CONTACT,
			PEOPLE_INFO_TAB.BANK_INFO,
			PEOPLE_INFO_TAB.W4_INDIVIDUAL_WITHHOLDING,
			PEOPLE_INFO_TAB.W9_CORP_BUS_WITHHOLDING,
			PEOPLE_INFO_TAB.W8BEN,
		],
	},
	[PERSON_TYPE.VENDOR]: {
		visibleTabs: [
			PEOPLE_INFO_TAB.ADDRESS,
			PEOPLE_INFO_TAB.CONTACT,
			PEOPLE_INFO_TAB.EMERGENCY_CONTACT,
			PEOPLE_INFO_TAB.BANK_INFO,
			PEOPLE_INFO_TAB.BANKING_TAXES,
		],
	},
};

export const CompanyTypes = {
	[COMPANY_MAIN_TYPE.AD_AGENCY]: {
		subtype: [
			'AGENCY OF RECORD',
			'MULTI-CULTURAL',
			'MEDIA AGENCY',
			'HOLDING COMPANY',
			'EXPERIENTIAL AGENCY',
			'CREATIVE',
			'PUBLIC RELATION(PR)',
			'SOCIAL + DIGITAL'
		],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.AD_ID,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.PAYROLL_VENDOR,
			COMPANY_INFO_TAB.COMPANY_HIERARCHY,
			COMPANY_INFO_TAB.BUSINESS_TAX_INFO,
			COMPANY_INFO_TAB.SOCIAL_MEDIA,
			COMPANY_INFO_TAB.SIGNATORY,
			COMPANY_INFO_TAB.ADVERTISER,
			COMPANY_INFO_TAB.INSURANCE,
		],
	},
	[COMPANY_MAIN_TYPE.ADVERTISER]: {
		subtype: [
			'AEROSPACE AND DEFENSE',
			'ALCOHOL & TOBACCO',
			'APPAREL & FOOTWEAR',
			'ARTS AND EDUCATION',
			'AUTOMOTIVE',
			'BANKING AND FINANCIAL SERVICES',
			'BUSINESS AND PROFESSIONAL SERVICES',
			'CONSUMER DURABLES',
			'CONSUMER ELECTRONICS',
			'CONSUMER PACKAGED GOODS',
			'CONSUMER SERVICES',
			'ENERGY, UTILITIES',
			'ENTERTAINMENT AND SPORTS',
			'FOOD AND BEVERAGES',
			'HEALTHCARE',
			'INSURANCE',
			'MANUFACTURING, INDUSTRIAL GOODS AND SERVICES',
			'MEDIA',
			'NON-PROFITS AND GOVERNMENT',
			'PHARMACEUTICALS',
			'REAL ESTATE & CONSTRUCTION',
			'RESTAURANT AND FAST FOOD',
			'RETAIL',
			'TECHNOLOGY',
			'TELECOMMUNICATIONS',
			'TRAVEL, TRANSPORTATION, TOURISM, HOSPITALITY',
		],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.PRODUCT,
			COMPANY_INFO_TAB.AD_ID,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.PAYROLL_VENDOR,
			COMPANY_INFO_TAB.COMPANY_HIERARCHY,
			COMPANY_INFO_TAB.BUSINESS_TAX_INFO,
			COMPANY_INFO_TAB.SOCIAL_MEDIA,
			COMPANY_INFO_TAB.RELATIONSHIPS,
			COMPANY_INFO_TAB.TRANSFER_OF_RIGHTS,
			COMPANY_INFO_TAB.SIGNATORY,
			COMPANY_INFO_TAB.INSURANCE,
			COMPANY_INFO_TAB.BRANDS_PRODUCTS
		],
	},
	[COMPANY_MAIN_TYPE.AGENT_REPRESENTATIVE]: {
		subtype: [
			'TALENT AGENCY',
			'TALENT MANAGER',
			'LAW FIRM',
			'ACCOUNTANT/ CPA FIRM',
		],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.PAYROLL_VENDOR,
			COMPANY_INFO_TAB.COMPANY_HIERARCHY,
			COMPANY_INFO_TAB.BUSINESS_TAX_INFO,
			COMPANY_INFO_TAB.SOCIAL_MEDIA,
			COMPANY_INFO_TAB.INSURANCE,
			COMPANY_INFO_TAB.BANK,
		],
	},
	[COMPANY_MAIN_TYPE.CASTING_COMPANY]: {
		subtype: ['EXTRAS', 'GENERAL', 'VOICE'],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.PAYROLL_VENDOR,
			COMPANY_INFO_TAB.COMPANY_HIERARCHY,
			COMPANY_INFO_TAB.BUSINESS_TAX_INFO,
			COMPANY_INFO_TAB.SOCIAL_MEDIA,
			COMPANY_INFO_TAB.INSURANCE,
		],
	},
	[COMPANY_MAIN_TYPE.DIVISION]: {
		subtype: [] as string[],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.PRODUCT,
			COMPANY_INFO_TAB.AD_ID,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.PAYROLL_VENDOR,
			COMPANY_INFO_TAB.COMPANY_HIERARCHY,
			COMPANY_INFO_TAB.BUSINESS_TAX_INFO,
			COMPANY_INFO_TAB.SOCIAL_MEDIA,
			COMPANY_INFO_TAB.SIGNATORY,
			COMPANY_INFO_TAB.INSURANCE,
			COMPANY_INFO_TAB.BRANDS_PRODUCTS
		],
	},
	[COMPANY_MAIN_TYPE.HOSPITAL]: {
		subtype: [] as string[],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.INSURANCE,
		],
	},
	[COMPANY_MAIN_TYPE.LOCATION]: {
		subtype: [
			'STUDIO',
			'MUSIC',
		],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.BUSINESS_TAX_INFO,
			COMPANY_INFO_TAB.SOCIAL_MEDIA,
			COMPANY_INFO_TAB.INSURANCE,
		],
	},
	[COMPANY_MAIN_TYPE.PRODUCTION_COMPANY]: {
		subtype: [
			'ANIMATION',
			'COMMERCIALS',
			'CORP VIDEOS',
			'DIGITAL/WEB',
			'DOCUMENTARY & BTS (BEHIND THE SCENES)',
			'EDITORIAL',
			'INFORMERCIAL',
			'LIVE EVENT',
			'MUSIC HOUSE',
			'MUSIC VIDEOS',
			'POST PRODUCTION',
			'PROMO & TRAILER',
			'TRAILER',
			'VR',
		],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.PAYROLL_VENDOR,
			COMPANY_INFO_TAB.COMPANY_HIERARCHY,
			COMPANY_INFO_TAB.BUSINESS_TAX_INFO,
			COMPANY_INFO_TAB.SOCIAL_MEDIA,
			COMPANY_INFO_TAB.SIGNATORY,
			COMPANY_INFO_TAB.INSURANCE,
		],
	},
	[COMPANY_MAIN_TYPE.VENDOR]: {
		subtype: [
			'AERIAL & DRONES',
			'ANIMALS & HANDLER',
			'ANIMATION',
			'ART BUYER',
			'ART DEPARTMENT',
			'AUDIO',
			'BABY NURSE',
			'BUSINESS AFFAIRS',
			'CAMERAS',
			'CLEARANCE & LICENSING',
			'CONSULTANT',
			'CRAFT SERVICE',
			'DIGITAL MEDIA',
			'DIRECTORS PRODUCERS WRITERS',
			'DISTRIBUTION SERVICES',
			'EDITORIAL HOUSE',
			'ELECTRICAL & RIGGING',
			'FREIGHT & SHIPPING',
			'GRAPHIC DESIGN',
			'GRIP & RIGGING',
			'INSURANCE',
			'LEGAL/ATTORNEY',
			'LOCATION SERVICES',
			'MAKE-UP & WARDROBE',
			'MARKETING & ADVERTISING',
			'MEDICS',
			'MODELLING AGENCIES',
			'MUSIC HOUSE',
			'MUSIC PUBLISHING SERVICES',
			'MUSIC SERVICES',
			'ONLINE FREELANCE SERVICES',
			'PACKAGING VENDORS',
			'PAYROLL SERVICES',
			'PHOTOGRAPHY',
			'POST PRODUCTION',
			'PRE PRODUCTION SERVICES',
			'PRINTING VENDORS',
			'PROPS',
			'PUBLIC RELATIONS',
			'RENTAL EQUIPMENT',
			'SECURITY',
			'SIGNATORY SERVICES',
			'SOFTWARE',
			'SOUND/FX',
			'SPECIAL EFFECTS',
			'STAGES & STUDIOS',
			'STOCK IMAGES & VIDEOS',
			'STUDIO TEACHERS',
			'STUNTS',
			'TRAFFIC MANAGEMENT ',
			'TRAILERS & MOTORHOMES',
			'VTR/SCRIPT',
			'VEHICLES',
			'VOICEOVER SERVICES',
			'WARDROBE & COSTUMES',
			'OTHER PROFESSIONAL SERVICE',
		],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.AD_ID,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.PAYROLL_VENDOR,
			COMPANY_INFO_TAB.COMPANY_HIERARCHY,
			COMPANY_INFO_TAB.BUSINESS_TAX_INFO,
			COMPANY_INFO_TAB.SOCIAL_MEDIA,
			COMPANY_INFO_TAB.INSURANCE,
		],
	},
	[COMPANY_MAIN_TYPE.LOAN_OUT_CORPORATION]: {
		subtype: [] as string[],
		visibleTabs: [
			COMPANY_INFO_TAB.COMPANY_PROFILE,
			COMPANY_INFO_TAB.DOCUMENTS,
			COMPANY_INFO_TAB.STAFF,
			COMPANY_INFO_TAB.ADDRESS,
			COMPANY_INFO_TAB.MEMBERS,
			COMPANY_INFO_TAB.BANK,
		],
	},
};

export const ElementTypes = {
	[ElementType.Art]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
			ELEMENT_INFO_TAB.TALENT,
		],
		visibleProperties: [
			'URL LINK'
		]
	},
	[ElementType.Audio]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
			ELEMENT_INFO_TAB.TALENT,
		],
		visibleProperties: [
			'URL LINK',
			'FRAME INFO',
			'NO OF CLIPS',
			'DATE OF PRODUCTION',
			'COUNTRY OF PRODUCTION',
		]
	},
	[ElementType.BrandedItems]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
		],
		visibleProperties: [
			'URL LINK',
		]
	},
	[ElementType.Company]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
		],
	},
	[ElementType.Image]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
			ELEMENT_INFO_TAB.TALENT,
		],
		visibleProperties: [
			'URL LINK',
			'DATE OF PRODUCTION',
			'COUNTRY OF PRODUCTION',
			'NO OF IMAGES',
			'KEYLINES'
		]
	},
	[ElementType.Landmarks]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
		],
		visibleProperties: [
			'COUNTRY',
			'STATE ',
			'CITY',
			'URL LINK',
		]
	},
	[ElementType.Music]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
			ELEMENT_INFO_TAB.TALENT,
			ELEMENT_INFO_TAB.MUSIC,
		],
		visibleProperties: [
			'URL LINK',
			'DATE OF PRODUCTION',
			'COUNTRY OF PRODUCTION',
		]
	},
	[ElementType.Trademarks]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
		],
		visibleProperties: [
			'URL LINK',
		]
	},
	[ElementType.Vehicle]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
		],
		visibleProperties: [
			'URL LINK',
			'MAKE',
			'MODEL',
			'YEAR',
		]
	},
	[ElementType.Video]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
			ELEMENT_INFO_TAB.TALENT,
		],
		visibleProperties: [
			'URL LINK',
			'FRAME INFO',
			'NO OF VIDEOS',
			'DATE OF PRODUCTION',
			'COUNTRY OF PRODUCTION',
		]
	},
	[ElementType.Misc]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
		],
		visibleProperties: [
			'URL LINK',
		]
	},
	[ElementType.Props]: {
		visibleTabs: [
			ELEMENT_INFO_TAB.ELEMENT_PROFILE,
			ELEMENT_INFO_TAB.LICENSE_DETAILS,
			ELEMENT_INFO_TAB.RIGHTS_ACQUIRED,
		],
		visibleProperties: [
			'URL LINK',
			'NOTES'
		]
	},
};

export const ImageConfig = {
	default: fileDefault,
	pdf: filePdf,
	png: filePng,
	css: fileCSS,
	doc: fileDoc,
	docx: fileDoc,
	csv: fileCsv,
	xml: fileXml,
	xlsx: fileXml,
};

export const PayrollVendorServiceTypeOptions = [
	'BA',
	'Crew & Talent',
	'Contractor',
	'Crew payroll',
	'Freelance Staff',
	'Music Publishing',
	'Network clearance',
	'Owner/Officer',
	'Staff',
	'Talent Payroll',
	'Traffic',
];

export const elementTypes = [
	'Art',
	'Audio',
	'Branded Items',
	'Company',
	'Image',
	'Landmarks',
	'Music',
	'Trademarks',
	'Vehicle',
	'Video',
	'Misc/Others',
	'Props/Others',
	'Fonts',
	'Books',
	'Quotes'
];

export const DeliverableTypeOptions = [
	'COMMERCIAL',
	'OLV',
	'PROMO',
	'CORPORATE/EDUCATIONAL'
];

export const EditingProvisionsOptions = [
	'Unlimited',
	'Number defined'
];

export const elementUnionTypes = [
	'ACTRA',
	'AFM',
	'AFTRA',
	'Non Union',
	'SAG AFTRA',
	'None',
];

export const elementSubTypes = {
	[ElementType.Art]: {
		subtype: ['Film', 'Original work for Hire', 'Reproduction'],
	},
	[ElementType.Audio]: {
		subtype: [
			'Mnemonic',
			'Original',
			'Sound design',
			'Sound recording',
			'Stock',
		],
	},
	[ElementType.BrandedItems]: {
		subtype: ['Apparel & Jewellery', 'Equipment', 'Food & Beverage'],
	},
	[ElementType.Company]: {
		subtype: ['Business Name', 'Logo', 'Packaging', 'Product', 'Tag Line'],
	},
	[ElementType.Image]: {
		subtype: ['Original', 'Public Domain', 'Stock', 'User generated'],
	},
	[ElementType.Landmarks]: {
		subtype: ['Amusement rides', 'Buildings', 'Sculptures', 'Statues'],
	},
	[ElementType.Music]: {
		subtype: [
			'Commissioned',
			'Licensed',
			'Stock (Master/Publisher)',
		]
	},
	[ElementType.Trademarks]: {
		subtype: [],
	},
	[ElementType.Vehicle]: {
		subtype: [
			'Airplane',
			'Auto',
			'Bicycle',
			'Boat',
			'Glider',
			'Helicopter',
			'Hot Air Balloon',
			'Motorcycle',
			'Train',
		],
	},
	[ElementType.Video]: {
		subtype: [
			'Original',
			'Public domain',
			'Stock',
			'User generated',
			'Licensed',
			'Brand/Client provided materials',
			'Print',
			'Celebrity Guar',
			'Influencer',
			'Illustrator',
			'Non union Principal',
			'Non Union Extra',
			'NU Driver',
			'NU VO',
			'Photographer',
			'Model',
			'Right of publicity',
		]
	},
	[ElementType.Misc]: {
		subtype: [
			'GIF',
			'MEME',
			'Name/Likeness',
			'Quotes',
			'Recipes',
			'Social media post',
		],
	},
	[ElementType.Props]: {
		subtype: ['Album cover', 'Books', 'Furniture', 'Signage'],
	},
};

export const AssetTypeOptions = [
	'Audio',
	'Image',
	'Interactive',
	'Print',
	'Video',
];

export const UNION_AGREEMENTS = {
	TV_AND_RADIO_COMMERCIAL : 'TV & RADIO COMMERCIAL',
	AUDIO_ACS : 'AUDIO ACS',
	AUDIO_STANDARD : 'AUDIO STANDARD',
	CORPORATE_EDUCATIONAL_NON_BROADCAST : 'CORPORATE/EDUCATIONAL/NON BROADCAST',
	INFOMERCIAL: 'INFOMERCIAL',
	TV_COMMERCIAL_ACS: 'TV COMMERCIAL ACS',
	TV_COMMERCIAL_STANDARD: 'TV COMMERCIAL STANDARD',
	AFM: 	'AFM',
	NON_UNION: 'NON UNION',
}; 

export const AssetUnionType = {
	[UNION_TYPE.AFM]: {
		name: UNION_TYPE.AFM,
		agreement: {
			[UNION_AGREEMENTS.TV_AND_RADIO_COMMERCIAL]: {
				subcategory: [],
			},
		},
	},
	[UNION_TYPE.SAG]: {
		name: UNION_TYPE.SAG,
		agreement: {
			[UNION_AGREEMENTS.AUDIO_ACS]: {
				subcategory: ['DIGITAL NATIONAL', 'DIGITAL REGIONAL'],
			},
			[UNION_AGREEMENTS.AUDIO_STANDARD]: {
				subcategory: [
					'FOREIGN USE ONLY',
					'MADE FOR INDUSTRIAL USE ONLY',
					'MADE FOR INTERNET ONLY',
					'MADE FOR NEW MEDIA ONLY',
					'PUBLIC SERVICE',
					'ANNOUNCEMENT(PSA)',
					'REGIONAL',
				],
			},
			[UNION_AGREEMENTS.CORPORATE_EDUCATIONAL_NON_BROADCAST]: {
				subcategory: [
					'CATEGORY 1',
					'CATEGORY 1 - AUDIO',
					'CATEGORY 2',
					'CATEGORY 2 - AUDIO',
				],
			},
			[UNION_AGREEMENTS.INFOMERCIAL]: {
				subcategory: [],
			},
			[UNION_AGREEMENTS.TV_COMMERCIAL_ACS]: {
				subcategory: [
					'UPFRONT DIGITAL',
					'UPFRONT FLEX',
					'UPFRONT PLUS',
				],
			},
			[UNION_AGREEMENTS.TV_COMMERCIAL_STANDARD]: {
				subcategory: [
					'ASIAN LANGUAGE',
					'DEALER A',
					'DEALER B',
					'FOREIGN USE ONLY',
					'LOW BUDGET DIGITAL WAIVER',
					'MADE FOR CABLE ONLY',
					'MADE FOR FOREIGN ONLY',
					'MADE FOR INDUSTRIAL ONLY',
					'MADE FOR INTERNET ONLY',
					'MADE FOR NEW MEDIA ONLY',
					'NON AIR DEMO',
					'PUBLIC SERVICE ANNOUNCEMENT (PSA)',
					'REGIONAL',
					'SEASONAL (VALENTINE, CHRISTMAS DAY)',
					'SOCIAL MEDIA WAIVER',
					'SPANISH LANGUAGE',
					'TEST MARKET',
				],
			},
		},
	},
	[UNION_TYPE.SAG_AFTRA]: {
		name: UNION_TYPE.SAG_AFTRA,
		agreement: {
			[UNION_AGREEMENTS.AUDIO_ACS]: {
				subcategory: ['DIGITAL NATIONAL', 'DIGITAL REGIONAL'],
			},
			[UNION_AGREEMENTS.AUDIO_STANDARD]: {
				subcategory: [
					'FOREIGN USE ONLY',
					'MADE FOR INDUSTRIAL USE ONLY',
					'MADE FOR INTERNET ONLY',
					'MADE FOR NEW MEDIA ONLY',
					'PUBLIC SERVICE',
					'ANNOUNCEMENT(PSA)',
					'REGIONAL',
				],
			},
			[UNION_AGREEMENTS.CORPORATE_EDUCATIONAL_NON_BROADCAST]: {
				subcategory: [
					'CATEGORY 1',
					'CATEGORY 1 - AUDIO',
					'CATEGORY 2',
					'CATEGORY 2 - AUDIO',
				],
			},
			[UNION_AGREEMENTS.INFOMERCIAL]: {
				subcategory: [],
			},
			[UNION_AGREEMENTS.TV_COMMERCIAL_ACS]: {
				subcategory: [
					'UPFRONT DIGITAL',
					'UPFRONT FLEX',
					'UPFRONT PLUS',
				],
			},
			[UNION_AGREEMENTS.TV_COMMERCIAL_STANDARD]: {
				subcategory: [
					'ASIAN LANGUAGE',
					'DEALER A',
					'DEALER B',
					'FOREIGN USE ONLY',
					'LOW BUDGET DIGITAL WAIVER',
					'MADE FOR CABLE ONLY',
					'MADE FOR FOREIGN ONLY',
					'MADE FOR INDUSTRIAL ONLY',
					'MADE FOR INTERNET ONLY',
					'MADE FOR NEW MEDIA ONLY',
					'NON AIR DEMO',
					'PUBLIC SERVICE ANNOUNCEMENT (PSA)',
					'REGIONAL',
					'SEASONAL (VALENTINE, CHRISTMAS DAY)',
					'SOCIAL MEDIA WAIVER',
					'SPANISH LANGUAGE',
					'TEST MARKET',
				],
			},
		},
	},
	'NON UNION': {
		name: 'NON UNION',
		agreement: {},
	},
};

export const AssetStatusOptions = [
	'INCOMPLETE',
	'ACTIVE',
	'EXPIRED',
	'RELEASED',
	'RELEASED (USE INACTIVE)',
	'RELEASED (USE ACTIVE)',
	'NA'
];

export const ElementStatusOptions = [
	'Active',
	'Expired',
];

export const OccupationType = {
	PRINCIPAL: 'PRINCIPAL',
	VOICE_OVER: 'VOICE OVER',
	CHARACTER_VOICE: 'CHARACTER VOICE',
	DANCER: 'DANCER',
	GROUP_3_5: 'GROUP 3-5',
	GROUP_6_8: 'GROUP 6-8',
	GROUP_9_OR_MORE: 'GROUP 9 OR MORE',
	GROUP_SIGNATURE_3_5: 'GROUP SIGNATURE 3-5',
	GROUP_SIGNATURE_6_8: 'GROUP SIGNATURE 6-8',
	GROUP_SIGNATURE_9_OR_MORE: 'GROUP SIGNATURE 9 OR MORE',
	MODEL: 'MODEL',
	PILOT: 'PILOT',
	PUPPETEER: 'PUPPETEER',
	SPECIALITY_ACT: 'SPECIALITY ACT',
	SINGER: 'SINGER',
	SINGER_DUO: 'SINGER DUO',
	DANCER_DUO: 'DANCER DUO',
	GROUP_SINGERS_DANCERS_SPEAKERS_3_5: 'GROUP SINGERS, DANCERS, SPEAKERS 3-5',
	GROUP_SINGER_SPEAKER_6_8: 'GROUP SINGER, SPEAKER 6-8',
	DANCER_3_PLUS: 'DANCER 3+',
	GROUP_DANCER_6_PLUS: 'GROUP DANCER 6+',
	GROUP_SINGER_9_MORE: 'GROUP SINGER 9 OR MORE',
	CONTRACTOR: 'CONTRACTOR',
	CHOREOGRAPHER: 'CHOREOGRAPHER',
	BODY_DOUBLE: 'BODY DOUBLE',
	SOUND_EFFECT_ARTIST: 'SOUND EFFECT ARTIST',
	STUNT_PERFORMER: 'STUNT PERFORMER',
	STUNT_COORDINATOR: 'STUNT COORDINATOR',
	EXTRA_IN_PERP_BUYOUT: 'EXTRA IN PERP/BUYOUT',
	EXTRA_13_WEEKS: 'EXTRA 13 WEEKS',
	HAND_MODEL_PERP_BUYOUT: 'HAND MODEL PERP/BUYOUT',
	HAND_MODEL_13_WEEKS: 'HAND MODEL 13 WEEKS',
	STAND_IN_PERP_BUYOUT: 'STAND IN PERP/BUYOUT',
	STAND_IN_13_WEEKS: 'STAND IN 13 WEEKS',
	PHOTO_DOUBLE: 'PHOTO DOUBLE',
	LEADER_AFM: 'LEADER (AFM)',
	ARRANGER_AFM: 'ARRANGER (AFM)',
	ORCHESTRATOR_AFM: 'ORCHESTRATOR (AFM)',
	SIDE_MUSICIAN_AFM: 'SIDE MUSICIAN (AFM)',
	COPYIST_AFM: 'COPYIST (AFM)',
	// NON UNION OFF CAMERA EMPLOYMENT TYPE
	GROUP_DANCER: 'GROUP DANCER',
	GROUP_SINGER: 'GROUP SINGER',
	MUSICIAN: 'MUSICIAN',
	// NON UNION ON CAMERA EMPLOYMENT TYPE
	EXTRA: 'EXTRA',
	HAND_MODEL: 'HAND MODEL',
	STAND_IN: 'STAND-IN',
	// OTHER
	EXTRA_UNLIMITED_BUYOUT: 'EXTRA UNLIMITED BUYOUT',
	GROUP_SINGER_3_5: 'GROUP SINGER 3-5',
	GROUP_SINGER_6_8: 'GROUP SINGER 6-8',
	HAND_MODEL_UNLIMITED_BUYOUT: 'HAND MODEL PERP/BUYOUT',
	GROUP_SINGER_SPEAKER_9_PLUS: 'GROUP SINGER, SPEAKER 9+',
	STUNT: 'STUNT',
	DAY_PERFORMER: 'DAY PERFORMER',
	NARRATOR_SPOKESPERSON_D9: 'NARRATOR/SPOKESPERSON+D9',
	DAY_PERFORMER_3: '3 DAY PERFORMER',
	WEEKLY_5_DAY_STUDIO: 'WEEKLY 5 - DAY STUDIO',
	WEEKLY_6_DAY_STUDIO: 'WEEKLY 6 - DAY STUDIO',
	EXTRA_GENERAL_BACKGROUND: 'EXTRA - GENERAL BACKGROUND',
	EXTRA_SPECIAL_ABILITY: 'EXTRA - SPECIAL ABILITY',
	EXTRA_SILENT_BIT: 'EXTRA - SILENT BIT',
	SINGER_SOLO_DUO: 'SINGER - SOLO/DUO',
	SINGER_GROUP_3_PLUS: 'SINGER - GROUP 3+',
	SINGER_GROUP_STEP_OUT: 'SINGER - GROUP STEP OUT',
	INTERACTIVE_VOICE_RECORDING: 'INTERACTIVE VOICE RECORDING',
	STORECASTING: 'STORECASTING',
	CHOREGRAPHED_DANCER_SWIMMER_SKATER_ETC: 'CHOREGRAPHED DANCER, SWIMMER, SKATER, ETC.',
	UNRECORDED_SILENT_PART: 'UNRECORDED SILENT PART',
	NON_PRINCIPAL_AUDIO_PROGRAM_ONLY: 'NON PRINCIPAL - AUDIO PROGRAM ONLY',
	SINGER_SOLO_PLUS_GROUP_16_BARS: 'SINGER - SOLO + GROUP < 16 BARS',
	SINGER_GROUP_STEP_OUT_8_BARS: 'SINGER - GROUP STEP OUT < 8 BARS',
	PHONECASTING: 'PHONECASTING',
	ACTOR: 'ACTOR',
	ANNOUNCER: 'ANNOUNCER',
	SOLO_SINGER: 'SOLO SINGER',
	GROUP_SINGER_SPEAKER_3_5: 'GROUP SINGER/SPEAKER 3-5',
	SOUND_EFFECT_PERFORMER: 'SOUND EFFECT PERFORMER',
	LINES_OR_LESS_5: '5 LINES OR LESS',
	HOST: 'HOST',
	LEADER: 'LEADER',
	ARRANGER: 'ARRANGER',
	ORCHESTRATOR: 'ORCHESTRATOR',
	COPYIST: 'COPYIST',
};

export const EmploymentTypeOptionsMapping = {
	[UNION_AGREEMENTS.TV_COMMERCIAL_ACS] : [
		OccupationType.PRINCIPAL,
		OccupationType.EXTRA_UNLIMITED_BUYOUT,
		OccupationType.EXTRA_13_WEEKS,
		OccupationType.VOICE_OVER,
		OccupationType.DANCER,
		OccupationType.GROUP_3_5,
		OccupationType.GROUP_6_8,
		OccupationType.GROUP_9_OR_MORE,
		OccupationType.GROUP_SINGER_3_5,
		OccupationType.GROUP_SINGER_6_8,
		OccupationType.GROUP_SINGER_9_MORE,
		OccupationType.HAND_MODEL_UNLIMITED_BUYOUT,
		OccupationType.HAND_MODEL_13_WEEKS,
		OccupationType.MODEL,
		OccupationType.PILOT,
		OccupationType.PUPPETEER,
		OccupationType.SINGER,
		OccupationType.SINGER_DUO,
		OccupationType.DANCER_DUO,
		OccupationType.GROUP_SINGERS_DANCERS_SPEAKERS_3_5,
		OccupationType.GROUP_SINGER_SPEAKER_6_8,
		OccupationType.GROUP_DANCER_6_PLUS,
		OccupationType.GROUP_SINGER_SPEAKER_9_PLUS,
		OccupationType.CHOREOGRAPHER,
		OccupationType.STAND_IN,
		OccupationType.BODY_DOUBLE,
		OccupationType.PHOTO_DOUBLE,
		OccupationType.SOUND_EFFECT_ARTIST,
		OccupationType.STUNT,
		OccupationType.STUNT_COORDINATOR,
	],
	[UNION_AGREEMENTS.TV_COMMERCIAL_STANDARD] : [
		OccupationType.PRINCIPAL,
		OccupationType.EXTRA_UNLIMITED_BUYOUT,
		OccupationType.EXTRA_13_WEEKS,
		OccupationType.VOICE_OVER,
		OccupationType.DANCER,
		OccupationType.GROUP_3_5,
		OccupationType.GROUP_6_8,
		OccupationType.GROUP_9_OR_MORE,
		OccupationType.GROUP_SINGER_3_5,
		OccupationType.GROUP_SINGER_6_8,
		OccupationType.GROUP_SINGER_9_MORE,
		OccupationType.HAND_MODEL_UNLIMITED_BUYOUT,
		OccupationType.HAND_MODEL_13_WEEKS,
		OccupationType.MODEL,
		OccupationType.PILOT,
		OccupationType.PUPPETEER,
		OccupationType.SINGER,
		OccupationType.SINGER_DUO,
		OccupationType.DANCER_DUO,
		OccupationType.GROUP_SINGERS_DANCERS_SPEAKERS_3_5,
		OccupationType.GROUP_SINGER_SPEAKER_6_8,
		OccupationType.GROUP_DANCER_6_PLUS,
		OccupationType.GROUP_SINGER_SPEAKER_9_PLUS,
		OccupationType.CHOREOGRAPHER,
		OccupationType.STAND_IN,
		OccupationType.BODY_DOUBLE,
		OccupationType.PHOTO_DOUBLE,
		OccupationType.SOUND_EFFECT_ARTIST,
		OccupationType.STUNT,
		OccupationType.STUNT_COORDINATOR,
	],
	[UNION_AGREEMENTS.CORPORATE_EDUCATIONAL_NON_BROADCAST] : [
		OccupationType.DAY_PERFORMER,
		OccupationType.NARRATOR_SPOKESPERSON_D9,
		OccupationType.DAY_PERFORMER_3,
		OccupationType.WEEKLY_5_DAY_STUDIO,
		OccupationType.WEEKLY_6_DAY_STUDIO,
		OccupationType.EXTRA_GENERAL_BACKGROUND,
		OccupationType.EXTRA_SPECIAL_ABILITY,
		OccupationType.EXTRA_SILENT_BIT,
		OccupationType.SINGER_SOLO_DUO,
		OccupationType.SINGER_GROUP_3_PLUS,
		OccupationType.SINGER_GROUP_STEP_OUT,
		OccupationType.INTERACTIVE_VOICE_RECORDING,
		OccupationType.STORECASTING,
		OccupationType.CHOREGRAPHED_DANCER_SWIMMER_SKATER_ETC,
		OccupationType.UNRECORDED_SILENT_PART,
		OccupationType.NON_PRINCIPAL_AUDIO_PROGRAM_ONLY,
		OccupationType.SINGER_SOLO_PLUS_GROUP_16_BARS,
		OccupationType.SINGER_GROUP_STEP_OUT_8_BARS,
		OccupationType.PHONECASTING,
	],
	[UNION_AGREEMENTS.AUDIO_ACS] : [
		OccupationType.ACTOR,
		OccupationType.ANNOUNCER,
		OccupationType.SOLO_SINGER,
		OccupationType.SINGER_DUO,
		OccupationType.SINGER_DUO,
		OccupationType.GROUP_SINGER_SPEAKER_3_5,
		OccupationType.GROUP_SINGER_SPEAKER_6_8,
		OccupationType.GROUP_SINGER_SPEAKER_9_PLUS,
		OccupationType.SOUND_EFFECT_PERFORMER,
	],
	[UNION_AGREEMENTS.INFOMERCIAL] : [
		OccupationType.LINES_OR_LESS_5,
		OccupationType.HOST,
		OccupationType.MODEL,
		OccupationType.EXTRA,
	],
	[UNION_AGREEMENTS.AFM] : [
		OccupationType.MUSICIAN,
		OccupationType.LEADER,
		OccupationType.ARRANGER,
		OccupationType.ORCHESTRATOR,
		OccupationType.COPYIST,
	],
	[UNION_AGREEMENTS.NON_UNION] : [
		OccupationType.PRINCIPAL,
		OccupationType.EXTRA,
		OccupationType.VOICE_OVER,
		OccupationType.BODY_DOUBLE,
		OccupationType.CHOREOGRAPHER,
		OccupationType.DANCER,
		OccupationType.GROUP_DANCER,
		OccupationType.GROUP_SINGER,
		OccupationType.HAND_MODEL,
		OccupationType.MODEL,
		OccupationType.MUSICIAN,
		OccupationType.PHOTO_DOUBLE,
		OccupationType.PILOT,
		OccupationType.PUPPETEER,
		OccupationType.SINGER,
		OccupationType.SPECIALITY_ACT,
		OccupationType.STAND_IN,
		OccupationType.STUNT_COORDINATOR,
		OccupationType.STUNT_PERFORMER,
	],
};

export const EmploymentType = [
	OccupationType.PRINCIPAL,
	OccupationType.VOICE_OVER,
	OccupationType.CHARACTER_VOICE,
	OccupationType.DANCER,
	OccupationType.GROUP_3_5,
	OccupationType.GROUP_6_8,
	OccupationType.GROUP_9_OR_MORE,
	OccupationType.GROUP_SIGNATURE_3_5,
	OccupationType.GROUP_SIGNATURE_6_8,
	OccupationType.GROUP_SIGNATURE_9_OR_MORE,
	OccupationType.MODEL,
	OccupationType.PILOT,
	OccupationType.PUPPETEER,
	OccupationType.SPECIALITY_ACT,
	OccupationType.SINGER,
	OccupationType.SINGER_DUO,
	OccupationType.DANCER_DUO,
	OccupationType.GROUP_SINGERS_DANCERS_SPEAKERS_3_5,
	OccupationType.GROUP_SINGER_SPEAKER_6_8,
	OccupationType.DANCER_3_PLUS,
	OccupationType.GROUP_DANCER_6_PLUS,
	OccupationType.GROUP_SINGER_9_MORE,
	OccupationType.CONTRACTOR,
	OccupationType.CHOREOGRAPHER,
	OccupationType.BODY_DOUBLE,
	OccupationType.SOUND_EFFECT_ARTIST,
	OccupationType.STUNT_PERFORMER,
	OccupationType.STUNT_COORDINATOR,
	OccupationType.EXTRA_IN_PERP_BUYOUT,
	OccupationType.EXTRA_13_WEEKS,
	OccupationType.EXTRA_13_WEEKS,
	OccupationType.HAND_MODEL_PERP_BUYOUT,
	OccupationType.HAND_MODEL_13_WEEKS,
	OccupationType.STAND_IN_PERP_BUYOUT,
	OccupationType.STAND_IN_13_WEEKS,
	OccupationType.PHOTO_DOUBLE,
	OccupationType.LEADER_AFM,
	OccupationType.ARRANGER_AFM,
	OccupationType.ORCHESTRATOR_AFM,
	OccupationType.SIDE_MUSICIAN_AFM,
	OccupationType.COPYIST_AFM,
];

export const NonUnionOffCameraEmploymentType = [
	OccupationType.PRINCIPAL,
	OccupationType.VOICE_OVER,
	OccupationType.GROUP_DANCER,
	OccupationType.GROUP_SINGER,
	OccupationType.MUSICIAN,
	OccupationType.SINGER,
	OccupationType.SPECIALITY_ACT,
];

export const NonUnionOnCameraEmploymentType = [
	OccupationType.PRINCIPAL,
	OccupationType.EXTRA,
	OccupationType.BODY_DOUBLE,
	OccupationType.CHOREOGRAPHER,
	OccupationType.DANCER,
	OccupationType.GROUP_DANCER,
	OccupationType.GROUP_SINGER,
	OccupationType.HAND_MODEL,
	OccupationType.MODEL,
	OccupationType.MUSICIAN,
	OccupationType.PHOTO_DOUBLE,
	OccupationType.PILOT,
	OccupationType.PUPPETEER,
	OccupationType.SINGER,
	OccupationType.SPECIALITY_ACT,
	OccupationType.STAND_IN,
	OccupationType.STUNT_COORDINATOR,
	OccupationType.STUNT_PERFORMER,
];

export const musicTypeOptions = [
	{ label: 'Original', value: 'Original' },
	{ label: 'Licensed', value: 'Licensed' },
];

export const originalLicenseTypeOptions = [
	{ label: 'Work for Hire', value: 'Work for Hire' },
];

export const nonOriginalLicenseTypeOptions = [
	{ label: 'Original', value: 'Original' },
	{ label: 'Public domain', value: 'Public domain' },
	{ label: 'Re-recorded', value: 'Re-recorded' },
	{ label: 'Stock', value: 'Stock' },
];

export const musicRightOptions = [
	{ label: 'Master', value: 'Master' },
	{ label: 'Publishing', value: 'Publishing' },
	{ label: 'Synchronization', value: 'Synchronization' },
];

export const musicProRightsOptions = [
	{ label: 'ASCAP', value: 'ASCAP' },
	{ label: 'BMI', value: 'BMI' },
	{ label: 'PRO', value: 'PRO' },
	{ label: 'SESEC', value: 'SESEC' },
];

export const performerUnionOptions = [
	{ label: 'SAG-AFTRA', value: 'SAG-AFTRA' },
	{ label: 'AFM', value: 'AFM' },
	{ label: 'Non Union', value: 'Non Union' },
];

export const LicenseManagementTypes = [
	{ label: 'RIGHTS MANAGED', value: 'Rights Managed' },
	{ label: 'RIGHTS READY', value: 'Rights Ready' },
	{ label: 'ROYALTY FREE', value: 'Royalty Free' },
	{ label: 'WORK FOR HIRE', value: 'Work For Hire' },
];

export const RightsCoverageOptions = [
	'Broadcast',
	'Digital',
	'Out of Home',
	'Print'
];

export const RightsCoverageMainOptions = {
	[RightsCoverageTypes.BROADCAST]: {
		maintype: ['Video/TV', 'Broadcast/Linear', 'Audio/Radio'],
	},
	[RightsCoverageTypes.DIGITAL]: {
		maintype: ['Websites', 'Streaming/OTT ', 'Video Games', 'Virtual/Augmented Reality', 'Youtube', 'Social Media'],
	},
	[RightsCoverageTypes.OUTOFHOME]: {
		maintype: ['Airport', 'Gas Station', 'Transit', 'Billboard', 'Cinema/Theatrical', 'Digital OOH', 'Corporate/Internal', 'Posters', 'PR Launch', 'Retail'],
	},
	[RightsCoverageTypes.PRINT]: {
		maintype: ['Brochures', 'Catalogs', 'Collateral', 'Direct Marketing', 'Editorial', 'Packaging', 'Publications'],
	},
};

export const RightsCoverageSubOption ={
	[RightsCoverageMainTypes.BROADCAST_LINEAR]: {
		subType: ['Network', 'Cable', 'Editorial', 'VOD(Video on Demand)'],
	},
	[RightsCoverageMainTypes.AUDIO_RADIO]: {
		subType: ['Terrestrial Radio', 'Satellite', 'Editorial'],
	},
	[RightsCoverageMainTypes.SOCIALMEDIA]: {
		subType: ['Facebook', 'Instagram', 'LinkedIn', 'Snapchat', 'Tik Tok', 'X(Twitter)', 'Display Ads', 'ECommerce', 'Podcasts', 'Pre/mid/Post rolls'],
	},
	[RightsCoverageMainTypes.RETAIL]: {
		subType: ['In Store', 'POP or POS', 'Stadiums', 'Trade shows', 'Vending Machines'],
	},
	[RightsCoverageMainTypes.PUBLICATIONS]: {
		subType: ['Hard Cover', 'Magazine', 'Newspaper', 'Soft Cover', 'Trade Paperbacks'],
	},
};

export const PeriodUnitsOptions = [
	'DAY (S)',
	'WEEK (S)',
	'MONTH (S)',
	'YEAR (S)',
];

export const RegionOptions = [
	'WORLDWIDE',
	'AFRICA',
	'ASIA',
	'CARIBBEAN',
	'EUROPE',
	'NORTH AMERICA',
	'CENTRAL AMERICA',
	'SOUTH AMERICA',
	'OCEANIA',
];

export const SessionTypeForUnion = {
	[SESSION_MEDIA_TYPE.CORPORATE_EDUCATIONAL_BROADCAST]: {
		usageType: {
			'BASIC CABLE': {
				cycleLength: [ '3 Years', 'Maximum Use'],
				startCycleDate: true,
			},
			'CUSTOMIZATION': {},
			'FOREIGN TV (OUTSIDE US & CANADA)': {
				cycleLength: ['Maximum Use'],
			},
			'INTEGRATION': {},
			'SALE OR RENTAL TO GENERAL PUBLIC': {
				cycleLength: ['Unlimited'],
				startCycleDate: true,
			},
			'SALE OR RENTAL TO THE INDUSTRY': {
				cycleLength: ['Unlimited'],
				startCycleDate: true,
			},
			'THEATRICAL EXHIBITION': {
				cycleLength: ['Maximum Use'],
			}
		},
	},
	[SESSION_MEDIA_TYPE.DIGITAL]: {
		usageType: {
			'GAMING PLATFORMS/VR/ER/EMERGING PLATFORM': {
				cycleLength: ['21 Months'],
				startCycleDate: true,
			},
			'SOCIAL MEDIA/YOUTUBE': {
				cycleLength: ['30 Days'],
				startCycleDate: true,
			},
			'SOCIAL MEDIA': {
				cycleLength: ['30 Days'],
				startCycleDate: true,
				useDetail: [
					{ label: 'YOUTUBE USE', value: 'YOUTUBE USE' },
				],
			},
			'STREAMING': {
				cycleLength: ['4 Weeks', '13 Weeks', '52 Weeks'],
				startCycleDate: true,
			},
			'STREAMING PLATFORMS': {
				cycleLength: ['4 Weeks', '13 Weeks', '52 Weeks'],
				startCycleDate: true,
			},
			'TRADITIONAL DIGITAL': {
				cycleLength: ['4 Weeks', '13 Weeks', '52 Weeks'],
				startCycleDate: true,
			}
		},
	},
	[SESSION_MEDIA_TYPE.MUSIC]: {
		usageType: {
			'2ND ALLOWABLE EDIT': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'BROADCAST - RADIO': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['8 Weeks'],
				startCycleDate: true,
			},
			'BROADCAST - TV': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['13 Weeks'],
				subClass: ['Additional Reuse', 'Initial'],
				startCycleDate: true,
			},
			'CONVERSION/DUB FEE': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['Physical Media ( DVD )'],
				startCycleDate: true,
			},
			'FOREIGN USE': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['12 Months', '24 Months'],
				startCycleDate: true,
			},
			'INITIAL USE': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'INTERNET/NEW MEDIA': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['8 Weeks', '26 Weeks', '52 Weeks'],
				startCycleDate: true,
			},
			'LOCAL': {
				cycleLength: ['52 Weeks', '2 Years', '3 Years'],
				startCycleDate: true,
			},
			'MULTI-PLATFORM': {
				language: [{ label : 'English', value: 'en'}],
				subClass: ['Additional Reuse', 'Initial'],
				cycleLength: ['52 Weeks'],
				startCycleDate: true,
			},
			'NON-BROADCAST': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['8 Weeks', '12 Months', '24 Months'],
				startCycleDate: true,
			},
			'REGIONAL': {
				cycleLength: ['8 Weeks', '13 Weeks', '52 Weeks'],
				startCycleDate: true,
			},
			'REINSTATEMENT': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'NEW USE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.RADIO]: {
		usageType: {
			'AUDITION': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'CANCELLATION FEE': {
				cycleLength: ['1 Year'],
				startCycleDate: true,
			},
			'DEALER': {
				cycleLength: ['8 Weeks', '26 Weeks'],
				startCycleDate: true,
			},
			'FOREIGN USE': {
				cycleLength: ['18 Months'],
				startCycleDate: true,
			},
			'GUARANTEE': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},			
			'INTERNET - MADE FOR': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'INTERNET - MOVE OVER': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'LATE FEE/PENALTY': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'LOCAL PROGRAM': {
				cycleLength: ['1 Year'],
				startCycleDate: true,
			},
			'NETWORK': {
				subClass: ['National', 'Regional With Any Or All Majors', 'Regional without Majors'],
				cycleLength: [],
				startCycleDate: true,
				isSubClassControlledCycleLength: true,
				controlledCycleLength: {
					'National': [ '1 Weeks', '4 Weeks', '8 Weeks', '13 Weeks' ],
					'Regional With Any Or All Majors': ['13 Weeks'],
					'Regional without Majors': ['13 Weeks'],
				}
			},
			'NEW MEDIA - MADE FOR': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'NEW MEDIA - MOVE OVER': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'NON-AIR DEMO': {
				cycleLength: ['1 Year'],
				startCycleDate: true,
			},
			'PSA SESSION': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'PSA USE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'REGIONAL': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'RECONTRACT': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'REINSTATEMENT': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'SINGLE MARKET COMMERCIALS': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'SOCIAL MEDIA WAIVER': {
				cycleLength: ['30 Days'],
				startCycleDate: true,
			},
			'THEATRICAL/INDUSTRIAL': {
				cycleLength: ['30 Days', 'Maximum Use'],
				startCycleDate: true,
			},
			'WILDSPOT': {
				unit: {
					'Majors': [
						{ city: 'Chicago. IL', count: 10 },
						{ city: 'Los Angeles, CA', count: 39 },
						{ city: 'New York, NY', count: 32 },
					],
					'Others': [
						{ city: 'Atlanta, GA', count: 6 },
						{ city: 'Austin, Tx', count: 2 },
						{ city: 'Baltimore, MD', count: 3 },
						{ city: 'Boston, MA', count: 6 },
						{ city: 'Charlotte, NC', count: 3 },
						{ city: 'Cincinnati, OH', count: 2 },
						{ city: 'Cleveland, OH', count: 3 },
						{ city: 'Columbus, Oh', count: 2 },
						{ city: 'Dallas-Fort Worth, Tx', count: 7 },
						{ city: 'Denver, CO', count: 4 },
						{ city: 'Detroit, MI', count: 5 },
						{ city: 'Grand Rapids-Kalamazoo-Battle Creek, MI', count: 2 },
						{ city: 'Greenville - Spartanburg - Asheville - Anderson, NC', count: 2 },
						{ city: 'Hartford - New Haven, CT', count: 2 },
						{ city: 'Houston, TX', count: 6 },
						{ city: 'Indianapolis, IN', count: 3 },
						{ city: 'Kansas, MO', count: 2 },
						{ city: 'Las Vegas, NV', count: 2 },
						{ city: 'Mexico city, Mexico', count: 49 },
						{ city: 'Miami, Fl', count: 4 },
						{ city: 'Milwaukee, WI', count: 2 },
						{ city: 'Minneapolis - St Paul, Mn', count: 4 },
						{ city: 'Montreal, Qc', count: 5 },
						{ city: 'Nashville, Tn', count: 2 },
						{ city: 'Norfolk - Portsmouth - New port News, RI', count: 2 },
						{ city: 'Oklahoma City, OK', count: 2 },
						{ city: 'Orlando -  Daytona beach, Fl', count: 4 },
						{ city: 'Philadelphia, PA', count: 8 },
						{ city: 'Phoenix, Az', count: 5 },
						{ city: 'Pittsburgh, Pa', count: 3 },
						{ city: 'Portland, OR', count: 3 },
						{ city: 'Puerto Rico', count: 3 },
						{ city: 'Raleigh Durham, NC', count: 3 },
						{ city: 'Sacramento - Stockton, CA', count: 3 },
						{ city: 'Salt Lake City, UT', count: 2 },
						{ city: 'San Antonio, TX', count: 2 },
						{ city: 'San Diego, CA', count: 3 },
						{ city: 'San Francisco, CA', count: 8 },
						{ city: 'Seattle - Tacoma, WA', count: 5 },
						{ city: 'St.Louis, MO', count: 3 },
						{ city: 'Tampa -St.Petersburg, FL', count: 5 },
						{ city: 'Toronto, ON', count: 8 },
						{ city: 'Vancouver, BC', count: 3 },
						{ city: 'Washington DC', count: 6 },
						{ city: 'West Palm Beach - Ft.Pierce, FL', count: 2 },
					]
				},
				cycleLength: ['8 Weeks', '13 Weeks'],
				startCycleDate: true,
				unitColumns: true,		
				groupedUnit: true,		
			},
		},
	},
	[SESSION_MEDIA_TYPE.TV]: {
		usageType: {
			'2ND ALLOWABLE EDIT': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'ALL OTHER NORTH AMERICAN LINEAR': {
				cycleLength: ['4 Weeks', '13 Weeks', '52 Weeks'],
				startCycleDate: true,
			},
			'ALLOWABLE LIFT': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'AUDITION': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'BASIC CABLE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'BROADCAST-TV': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'CABLE': {
				language: [{ label : 'English', value: 'en'}, { label : 'Spanish', value: 'es'}],
				subClass: ['Local', 'National'],
				cycleLength: ['4 Weeks', '13 Weeks', '52 Weeks'],
				isLanguageControlledCycleLength: false,
				controlledCycleLength: {
					'en': ['13 Weeks'],
					'es': ['4 Weeks', '13 Weeks', '52 Weeks']
				},
				startCycleDate: true,
				useDetail: [
					{ label: 'NATIONAL CABLE', value: 'NATIONAL CABLE' },
					{ label: 'LOCAL CABLE ONLY', value: 'LOCAL CABLE ONLY' },
				],
			},
			'CANCELLATION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'COMBINED NETWORK/WILDSPOT': {
				language: [{ label : 'Spanish', value: 'es'}],
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
				unit: [
					{ city: 'Albuquerque,NM', count: 3 },
					{ city: 'Atlanta, GA', count: 2 },
					{ city: 'Austin, Tx', count: 2 },
					{ city: 'Baltimore, MD', count: 3 },
					{ city: 'Boston, MA', count: 2 },
					{ city: 'Charlotte, NC', count: 3 },
					{ city: 'Chicago. IL', count: 9 },
					{ city: 'Cincinnati, OH', count: 2 },
					{ city: 'Cleveland, OH', count: 3 },
					{ city: 'Columbus, Oh', count: 2 },
					{ city: 'Dallas-Fort Worth, Tx', count: 9 },
					{ city: 'Denver, CO', count: 4 },
					{ city: 'Detroit, MI', count: 5 },
					{ city: 'EL Centro, CA: YUMA, AZ & Mexicali, MX(Combined)', count: 6 },
					{ city: 'El paso, TX & Juarez, MX(Combined)', count: 11 },
					{ city: 'Fresno & Visalia, CA(Combined)', count: 3 },
					{ city: 'Grand Rapids-Kalamazoo-Battle Creek, MI', count: 2 },
					{ city: 'Greenville - Spartanburg - Nashville - Anderson, NC', count: 2 },
					{ city: 'Harrisburg - Lancaster - Lebanon- York', count: 2 },
					{ city: 'Hartford - New Haven, CT', count: 2 },
					{ city: 'Houston, TX', count: 11 },
					{ city: 'Indianapolis, IN', count: 3 },
					{ city: 'Kansas, MO', count: 2 },
					{ city: 'Laredo, TX & Nueva Laredo, MX (Combined)', count: 2 },
					{ city: 'Las Vegas, NV', count: 2 },
					{ city: 'Los Angeles, CA', count: 39 },
					{ city: 'Mcallen, Tx; Brownsville, TX; & Matamoros, MX(Combined)', count: 8 },
					{ city: 'Mexico city, Mexico', count: 49 },
					{ city: 'Miami, Fl', count: 17 },
					{ city: 'Milwaukee, WI', count: 2 },
					{ city: 'Minneapolis - St Paul, Mn', count: 4 },
					{ city: 'Motreal, Qc', count: 5 },
					{ city: 'Nashville, Tn', count: 2 },
					{ city: 'New York, NY', count: 32 },
					{ city: 'Norfolk - Portsmouth - New port News, RI', count: 2 },
					{ city: 'Oklahoma City, OK', count: 2 },
					{ city: 'Orlando -  Daytona beach, Fl', count: 3 },
					{ city: 'Phoenix, Az', count: 6 },
					{ city: 'Philadelphia, PA', count: 3 },
					{ city: 'Pittsburgh, Pa', count: 3 },
					{ city: 'Portland, OR', count: 3 },
					{ city: 'Puerto Rico', count: 21 },
					{ city: 'Raleigh Durham, NC', count: 3 },
					{ city: 'Sacramento - Stockton, CA', count: 5 },
					{ city: 'Salt Lake City, UT', count: 2 },
					{ city: 'San Antonio, TX', count: 8 },
					{ city: 'San Diego, CA, TIJUANA , MX', count: 13 },
					{ city: 'San Francisco, CA', count: 6 },
					{ city: 'Seattle - Tacoma, WA', count: 5 },
					{ city: 'St.Louis, MO', count: 3 },
					{ city: 'Tampa , FL', count: 3 },
					{ city: 'Toronto, ON', count: 8 },
					{ city: 'Vancouver, BC', count: 3 },
					{ city: 'Washington DC', count: 3 },
					{ city: 'West Palm Beach - Ft.Pierce, FL', count: 2 },
				],
				unitColumns: true,		
				groupedUnit: false,
			},
			'DEALER': {
				language: [{ label : 'English', value: 'en'}],
				subClass: ['Dealer A', 'Dealer A + NY', 'Dealer B', 'Dealer B + NY'],
				isSubClassMultiSelect: true,
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '52 Weeks'],
				startCycleDate: true,
			},
			'DIGINET': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
				useDetail: [
					{ label: 'NUMBER OF USES NEEDS TO BE CAPTURED OR UNLIMITED', value: 'NUMBER OF USES NEEDS TO BE CAPTURED OR UNLIMITED' },
				],
			},
			'DOWNGRADE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'FOREIGN USE TV (OUSIDE US&CANADA)': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'FOREIGN USE': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['21 Months'],
				startCycleDate: true,
				countries: ['UK', 'Europe', 'Asia Pacific', 'Japan', 'Rest of World( Excludes USA, CAN, Mex)', 'World Wide'],
			},
			'GUARANTEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'GAMING PLATFORMS/VR/ER/EMERGING PLATFORM': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'HOLDING FEES': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'ION': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
				useDetail: [
					{ label: 'NUMBER OF USES NEEDS TO BE CAPTURED OR UNLIMITED', value: 'NUMBER OF USES NEEDS TO BE CAPTURED OR UNLIMITED' },
				],
			},
			'ION/DIGINET': {
				language: [{ label : 'English', value: 'en'}, { label : 'Spanish', value: 'es'}],
				cycleLength: ['13 Weeks', 'Unlimited'],
				startCycleDate: true,
				additionalColumns: [
					SESSION_ADDITIONAL_COLUMN.NETWORK_USES,
					SESSION_ADDITIONAL_COLUMN.ENTRY,
					SESSION_ADDITIONAL_COLUMN.AIR_DATE,
					SESSION_ADDITIONAL_COLUMN.NETWORK,
					SESSION_ADDITIONAL_COLUMN.PROGRAM,
					SESSION_ADDITIONAL_COLUMN.EDIT,
				],
			},
			'INTEGRATION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'INTERNET/NEW MEDIA': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'INTERNET-MADE FOR': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'INTERNET-MOVE OVER': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'LATE NIGHT WAIVER': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
				subClass: ['ABC', 'CBS', 'FOX', 'NBC'],
				isSubClassMultiSelect: true,
			},
			'LATE FEE/PENALTY': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'LOCAL CABLE': {
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'LOCAL': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'NETWORK': {
				language: [{ label : 'English', value: 'en'}, { label : 'Spanish', value: 'es'}],
				cycleLength: ['13 Weeks'],
				useDetail: [
					{ label: 'NUMBER OF USES NEEDS TO BE CAPTURED', value: 'NUMBER OF USES NEEDS TO BE CAPTURED OR UNLIMITED' },
				],
				startCycleDate: true,
				subClass: [],
				isLanguageControlledSubClass: true,
				controlledSubClass: {
					'en': ['CLASS A', 'CLASS B', 'CLASS B+NY', 'CLASS C', 'UNWIRED'],
					'es': ['COMBO']
				},
				isSubClassControlledCycleLength: true,
				controlledCycleLength: {
					'CLASS A': [ '13 Weeks' ],
					'CLASS B': ['4 Weeks', '13 Weeks', '1 Year'],
					'CLASS B+NY': ['4 Weeks', '13 Weeks', '1 Year'],
					'CLASS C': ['4 Weeks', '13 Weeks', '1 Year'],
					'UNWIRED': ['4 Weeks', '13 Weeks', '1 Year'],
					'COMBO': ['13 Weeks'],
				}
			},
			'NEW MEDIA - MADE FOR': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'NEW MEDIA - MOVE OVER': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['4 Weeks', '8 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'NON-AIR DEMO': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'PSA USE': {
				cycleLength: ['1 Year'],
				startCycleDate: true,
			},
			'PSA SESSION ': {
				cycleLength: ['1 Year'],
				startCycleDate: true,
			},
			'RECONTRACT': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'REINSTATEMENT': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'STREAMING': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'SOCIAL MEDIA WAIVER': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['30 Days'],
				startCycleDate: true,
				subClass: ['With Youtube', 'Without Youtube']
			},
			'SPANISH PROGRAM': {
				cycleLength: ['4 Weeks', '13 Weeks', '1 Year'],
				startCycleDate: true,
			},
			'SPANISH LANGUAGE PROGRAM USE': {
				cycleLength: ['4 Weeks', '13 Weeks', '52 Weeks'],
				startCycleDate: true,
			},
			'UNWIRED NETWORK WAIVER': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
				subClass: ['National']
			},
			'UPGRADE ': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'THEATRICAL/INDUSTRIAL': {
				language: [{ label : 'English', value: 'en'}],
				cycleLength: ['30 Days', 'Unlimited'],
				startCycleDate: true,
				useDetail: [
					{ label: 'UP TO 30 DAYS USE', value: 'UP TO 30 DAYS USE' },
					{ label: 'ADDITIONAL USE AFTER 30TH DAY', value: 'ADDITIONAL USE AFTER 30TH DAY' },
					{ label: 'DISTRIBUTION ON PHYSICAL MEDIA', value: 'DISTRIBUTION ON PHYSICAL MEDIA' },
				],
			},
			'THEATRICAL EXHIBITION': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'WILDSPOT': {
				language: [{ label : 'English', value: 'en'}, { label : 'Spanish', value: 'es'}],
				cycleLength: ['4 Weeks', '13 Weeks', '52 Weeks'],
				subClass: [],
				isLanguageControlledSubClass: true,
				isLanguageControlledCycleLength: false,
				useDetail: [
					{ label: 'ALL BROADCAST MARKETS', value: 'ALL BROADCAST MARKETS' },
					{ label: 'ALL MARKETS EXCLUDING NY&LA', value: 'ALL MARKETS EXCLUDING NY&LA' },
				],
				controlledCycleLength: {
					'en': ['4 Weeks', '13 Weeks', '52 Weeks'],
					'es': ['4 Weeks', '13 Weeks', '52 Weeks']
				},
				startCycleDate: true,
				controlledSubClass: {
					'en': [],
					'es': ['Combo']
				},
				unit: [
					{ city: 'Chicago. IL', count: 10 },
					{ city: 'Los Angeles, CA', count: 39 },
					{ city: 'New York, NY', count: 32 },
					{ city: 'Atlanta, GA', count: 6 },
					{ city: 'Austin, Tx', count: 2 },
					{ city: 'Baltimore, MD', count: 3 },
					{ city: 'Boston, MA', count: 6 },
					{ city: 'Charlotte, NC', count: 3 },
					{ city: 'Cincinnati, OH', count: 2 },
					{ city: 'Cleveland, OH', count: 3 },
					{ city: 'Columbus, Oh', count: 2 },
					{ city: 'Dallas-Fort Worth, Tx', count: 7 },
					{ city: 'Denver, CO', count: 4 },
					{ city: 'Detroit, MI', count: 5 },
					{ city: 'Grand Rapids-Kalamazoo-Battle Creek, MI', count: 2 },
					{ city: 'Greenville - Spartanburg - Nashville - Anderson, NC', count: 2 },
					{ city: 'Hartford - New Haven, CT', count: 2 },
					{ city: 'Harrisburg - Lancaster - Lebanon- York', count: 2 },
					{ city: 'Houston, TX', count: 6 },
					{ city: 'Indianapolis, IN', count: 3 },
					{ city: 'Kansas, MO', count: 2 },
					{ city: 'Las Vegas, NV', count: 2 },
					{ city: 'Mexico city, Mexico', count: 52 },
					{ city: 'Miami, Fl', count: 4 },
					{ city: 'Milwaukee, WI', count: 2 },
					{ city: 'Minneapolis - St Paul, Mn', count: 4 },
					{ city: 'Motreal, Qc', count: 4 },
					{ city: 'Nashville, Tn', count: 2 },
					{ city: 'Norfolk - Portsmouth - New port News, RI', count: 2 },
					{ city: 'Oklahoma City, OK', count: 2 },
					{ city: 'Orlando -  Daytona beach, Fl', count: 4 },
					{ city: 'Philadelphia, PA', count: 8 },
					{ city: 'Phoenix, Az', count: 5 },
					{ city: 'Pittsburgh, Pa', count: 3 },
					{ city: 'Portland, OR', count: 3 },
					{ city: 'Puerto Rico', count: 3 },
					{ city: 'Raleigh Durham, NC', count: 3 },
					{ city: 'Sacramento - Stockton, CA', count: 3 },
					{ city: 'Salt Lake City, UT', count: 2 },
					{ city: 'San Antonio, TX', count: 2 },
					{ city: 'San Diego, CA', count: 2 },
					{ city: 'San Francisco, CA', count: 6 },
					{ city: 'Seattle - Tacoma, WA', count: 5 },
					{ city: 'St.Louis, MO', count: 3 },
					{ city: 'Tampa -St.Petersburg, FL', count: 5 },
					{ city: 'Toronto, ON', count: 9 },
					{ city: 'Vancouver, BC', count: 4 },
					{ city: 'Washington DC', count: 7 },
					{ city: 'West Palm Beach - Ft.Pierce, FL', count: 2 },
				],
				unitColumns: true,		
				groupedUnit: false,
			},
		},
	},
	[SESSION_MEDIA_TYPE.PRINT] : {
		usageType: {
			'ALL PRINT': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'BROCHURES': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'CATALOGS': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'COLLATERAL': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'DIRECT MAIL/MARKETING': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'DIGITAL': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'EDITORIAL': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'MAGAZINE': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'NEWS PAPER': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'OUT-OF-HOME (OOH) OR OUTDOOR': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'POP/POS - POINT OF PURCHASE OR SALE': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'Packaging': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'PUBLICATIONS': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'HARD COVER': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'SPOFT COVER': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
			'TRADE PAPERBACKS': {
				startCycleDate: true,
				cycleLength: ['1 Day'],
			},
		},
	}
} as const;

export const SessionTypeForNonUnion = {
	[SESSION_MEDIA_TYPE.DIGITAL]: {
		usageType: {
			'AUGMENTED/VIRTUAL REALITY': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'DISPLAY ADS': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'E-COMMERCE': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'EDITORIAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'PODCASTS': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'PRE/MID/POST ROLLS': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'SOCIAL MEDIA': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'VIDEO GAMES': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'WEBSITES': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'YOUTUBE': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.INDUSTRIAL_NON_BROADCAST]: {
		usageType: {
			'CINEMA/JUMBO TRON': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'POINT OF PURCHASE OR SALE - POP/POS': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'THEATRICAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.INFOMERCIAL]: {
		usageType: {
			'BROADCAST': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
				subTypes: [
					'CABLE',
					'NETWORK',
					'VIDEO ON DEMAND',
				]
			},
			'BROADCAST & CABLE': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'INDUSTRIAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.MULTI]: {
		usageType: {
			'ALL MEDIA': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'ALL MEDIA -NO BROADCAST': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'ALL MEDIA- NO PRINT': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'ALL MEDIA- NO RADIO': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'ALL PRINT': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'ALL PRINT - NO OOH': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.PRINT]: {
		usageType: {
			'ALL PRINT MATERIALS': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'BANNERS': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'BEHIND THE SCENE (BTS)': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'BILLBOARD': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'BROCHURES': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'CATALOG': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'CIRCULAR': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'COLLATERAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'CORPORATE/INDUSTRIAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'DIGITAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'DIRECT MAIL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'EDITORIAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'IN STORE MEDIA (ISM)': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'LOOK BOOK': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'OUT OF HOME (OOH)': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'PACKAGING': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'POINT OF PURCHASE (POP)': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'POINT OF SALE (POS)': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'PUBLIC RELATIONS (PR)': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'PUBLICATIONS': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'RETAIL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.RADIO_COMMERCIAL]: {
		usageType: {
			'BROADCAST': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
				subTypes: [
					'LOCAL',
					'NETWORK',
					'REGIONAL',
				]
			},
			'DEALER': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'DIGITAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
				subTypes:[
					'AUGMENTED/VIRTUAL REALITY',
					'DISPLAY ADS',
					'E-COMMERCE',
					'EDITORIAL',
					'PODCASTS',
					'PRE/MID/POST ROLLS',
					'SOCIAL MEDIA',
					'VIDEO GAMES',
					'WEBSITES',
					'YOUTUBE',
				]
			},
			'EDITORIAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'INDUSTRIAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'ON-AIR PROMOS': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'SATELLITE': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
			'TERRESTRIAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.TV_COMMERCIAL]: {
		usageType: {
			'BROADCAST/LINEAR': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
				subTypes : [
					'CABLE',
					'NETWORK',
					'VIDEO ON DEMAND',
				]
			},
			'DEALER': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'DIGITAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
				subTypes: [
					'AUGMENTED/VIRTUAL REALITY',
					'DISPLAY ADS',
					'E-COMMERCE',
					'EDITORIAL',
					'PODCASTS',
					'PRE/MID/POST ROLLS',
					'SOCIAL MEDIA',
					'VIDEO GAMES',
					'WEBSITES',
					'YOUTUBE',
				]
			},
			'FOREIGN': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'INDUSTRIAL': {
				cycleLength: ['1 Day'],
				startCycleDate: true,
			},
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.BEHIND_THE_SCENE_BTS]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.FILM]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.GAMES]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.HISTORICAL]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.LIVE_EVENT]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.MUSIC_VIDEO]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.PROMO]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.PUBLIC_RELATIONS_EVENT]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.RADIO_PROGRAM]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.SPONSORSHIP]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.TRAILER]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
	[SESSION_MEDIA_TYPE.TV_PROGRAM]: {
		usageType: {
			'SESSION FEE': {
				cycleLength: ['13 Weeks'],
				startCycleDate: true,
			},
		},
	},
} as const;

export const ImportSourceOptions = [
	{
		label: 'Talent-Use Cycle Report-With Versions (ER)',
		value: 'Talent-UseCycleReport-WithVersions (ER)',
	},
	{
		label: 'Talent-Commercial Expiry Report (ER)',
		value: 'Talent-CommercialExpiryReport (ER)',
	},
	{
		label: 'Talent-Cast Expiry Report (ER)',
		value: 'Talent-CastExpiryReport (ER)',
	},
	{
		label: 'BILLING HISTORY WITH CAST PAYMENT (ER)',
		value: 'BILLING HISTORY WITH CAST PAYMENT (ER)',
	},
	{ label: 'Commercial Report (ER)', value: 'CommercialReport (ER)' },
	{ label: 'Team.Cycle Expiration (TM)', value: 'Team.CycleExpiration (TM)' },
	{ label: 'NEXT HOLDING FEE DUE (TEAM)', value: 'NEXT HOLDING FEE DUE (TEAM)' },
	{ label: 'Cycle Expiration (HT)', value: 'Cycle Expiration (HT)' },
	{ label: 'INTERNAL RIGHTS TRACKER (7MG)', value: 'INTERNAL RIGHTS TRACKER (7MG)' },
	{ label: 'Account Transfer (TM)', value: 'AccountTransfer (TM)' },
	{ label: 'TEAM Completion report', value: 'TEAM Completion report' },
	{ label: 'HIGHLAND Session Completion Report', value: 'HIGHLAND Session Completion Report' }
];

export const RepresentativeTypeOptions = [
	{ label: 'TALENT AGENT', value: 'TALENT AGENT' },
	{ label: 'MANAGER', value: 'MANAGER' },
	{ label: 'ATTORNEY', value: 'ATTORNEY' },
	{ label: 'ACCOUNTANT', value: 'ACCOUNTANT' }
];

export const ApplyUseOptions = [
	'Scale',
	'Double scale',
	'Other'
];

export const ApplySessionOptions = [
	'Scale',
	'Double scale',
	'Other'
];

export const EmployeeTypeOptions = [
	'Staff',
	'Talent'
];

export const NotificationAlertOptions = [
	'1 DAY',
	'3 DAYS',
	'7 DAYS',
	'14 DAYS',
	'30 DAYS',
	'45 DAYS',
	'60 DAYS',
	'90 DAYS'
];

export const SnippetTypes = [
	'Header',
	'Body',
	'Legal',
	'Footer'
];

export enum PartType {
	SNIPPET = 'S',
	FORMPART = 'F'
}

export interface TemplatePart {
	id: number;
	orderId?: number;
	type: PartType;
	component: any;
	snippetId?: string;
	snippetType?: string;
	snippetName?: string;
}

export const SnippetFields = [
	{ label: 'Contract title', key: '@contract_title' },
	{ label: 'Contract Subtitle', key: '@contract_subtitle' },
	{ label: 'Term', key: '@term' },
	{ label: 'Fitting fee', key: '@fitting_fee' },
	{ label: 'Agent fee', key: '@agent_fee' },
	{ label: 'Category', key: '@category' },
	{ label: 'Time in', key: '@time_in' },
	{ label: 'Time out', key: '@time_out' },
	{ label: 'Lunch in', key: '@lunch_in' },
	{ label: 'Lunch out', key: '@lunch_out' }
];

export const PaymentTypeOptions = [
	'Full Amount',
	'Instalment',
	'Per cycle'
];

export const BankTypes = [
	'US/Domestic',
	'International',
	'Australia only'
];

export const Ethnicities = [
	'African American/Black',
	'Arab/Middle Eastern',
	'Asian Pacific Islander',
	'Caucasian',
	'Latino/Hispanic',
	'Multi-Racial',
	'Native American',
	'South Asian'
];

export const MultiBirthTypes = ['Quadruplet', 'Quintuplet', 'Sextuplet', 'Triplet', 'Twin'];

export const MultiBirthSubTypes = ['Fraternal', 'Identical'];

export const ChildNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const CoveringAreaTypeOptions = [
	{ label: 'Film', value: 'Film'},
	{ label: 'Print', value: 'Print'},
	{ label: 'TV', value: 'TV'},
	{ label: 'Radio', value: 'Radio'},
];

export const TypicalRoles = [
	'Body Double',
	'Character Voice',
	'Dancer',
	'Driver',
	'Extra',
	'Group Dancer',
	'Hand Model',
	'Model',
	'Musician',
	'Photo Double',
	'Pilot',
	'Principal',
	'Puppeteer',
	'Singer',
	'Special Effect Artist',
	'Specialty Act',
	'Stand In',
	'Stunt Coordinator',
	'Stunt Performer',
	'Voice Over'
];

export const Skills = [
	'Dance',
	'Pilot',
	'Plays Instrument',
	'Plays Sports',
	'Singing/Voice',
	'Stunt/Drivers',
	'Unique Skills'
];

export const Allergies = [
	'Cats',
	'Dogs',
	'Horses',
	'Cigarette Smoke',
	'Cockroaches',
	'Dust Mites',
	'Mold and Mildew',
	'Ragweed/Pollen',
	'Theatrical Smoke',
	'Chocolate',
	'Cow’s Milk',
	'Eggs',
	'Fish',
	'Peanuts',
	'Shellfish',
	'Soy',
	'Strawberries',
	'Tree Nuts',
	'Wheat',
	'Amoxicillin',
	'Aspirin',
	'Ibuprofen',
	'Penicillin'
];

export const UnionRepresentedOptions = [
	{ label: 'UNION', value: 'UNION'},
	{ label: 'NON UNION', value: 'NON UNION'}
];

export const GeoAreaCoveredOptions = [
	{ label: 'Domestic', value: 'Domestic'},
	{ label: 'International', value: 'International'},
	{ label: 'Domestic/International', value: 'Domestic/International'}
];

export const OriginalAsset = 'ORIGINAL';

export const AssetEditVersionReasons = [
	'Addressable Edits',
	'Changes To Off Camera Message',
	'Factual Information',
	'Dealer ID',
	'Foreign Language Voice Overs',
	'Legal Change',
	'Longer',
	'Longer/Shorter',
	'Network Requirement',
	'Rearrangement Of On Camera Material',
	'Recalls/Retakes',
	'Same Advertiser - Product/Service Name Changes',
	'Shorter',
	'Special Offer Or Promotion',
	'Paid Edits',
	'Permitted Changes',
	'Tag Change',
];

export const ExpirationType = {
	HoldingFeesDue: 'HOLDING FEES DUE',
	UsageRight: 'USAGE RIGHT',
	MPU: 'MPU',
	TalentContract: 'TALENT CONTRACT',
	ElementExpire: 'ELEMENT EXPIRE',
	MSAContract: 'MSA CONTRACT',
	Contract: 'CONTRACT',
};

export const ChangeEmploymentCategoryAction = [
	'Upgrade',
	'Downgrade',
	'Outgrade',
	'Re-Classify',
	'No Action',
	'Moved from NU to Union'
];

export const DateSessionMapping = {
	[SessionCycleLength.DAYS_30] : '30 Days',
	[SessionCycleLength.WEEKS_1] : '1 Weeks',
	[SessionCycleLength.WEEKS_4] : '4 Weeks',
	[SessionCycleLength.WEEKS_8] : '8 Weeks',
	[SessionCycleLength.WEEKS_13] : '13 Weeks',
	[SessionCycleLength.WEEKS_26] : '26 Weeks',
	[SessionCycleLength.WEEKS_52] : '52 Weeks',
	[SessionCycleLength.MONTHS_18] : '18 Months',
	[SessionCycleLength.MONTHS_21] : '21 Months',
	[SessionCycleLength.YEAR_1] : '1 Year',
	[SessionCycleLength.YEARS_2] : '2 Years',
	[SessionCycleLength.YEARS_3] : '3 Years',
};

export const ExpirationTypeEmailTemplate = {
	[ExpirationType.HoldingFeesDue]: `
        <div class="container">
            <p>Dear <strong>-client-</strong>,</p>
            <p>The next Holding Fee cycle is due for renewal with the following details:</p>
            <table>
                <tr>
                    <th>Commercial ID</th>
                    <th>:</th>
                    <td onClick={-handleCommercialIdClick-}>-commercialId-</td>
                </tr>
								<tr>
                    <th>Title</th>
                    <th>:</th>
                    <td>-title-</td>
                </tr>
								<tr>
                    <th>Number of Versions</th>
                    <th>:</th>
                    <td>-numberOfVersions-</td>
                </tr>
                <tr>
                    <th>Advertiser</th>
                    <th>:</th>
                    <td>-advertiser-</td>
                </tr>
								<tr>
                    <th>Contract Type</th>
                    <th>:</th>
                    <td>-contractType-</td>
                </tr>
								<tr>
                    <th>Ad-Agency</th>
                    <th>:</th>
                    <td>-adAgency-</td>
                </tr>
                <tr>
                    <th>Holding Fee Due Date</th>
                    <th>:</th>
                    <td>-nextHfDueDate-</td>
                </tr>
								<tr>
                    <th>Number of Talent by Category</th>
                    <th>:</th>
                    <td>-noOfTalentByCategory-</td>
                </tr>
								<tr>
                    <th></th>
                    <th>:</th>
                    <td></td>
                </tr>
								<tr>
                    <th>Important Dates to Consider</th>
                    <th>:</th>
                    <td></td>
                </tr>
								<tr>
                    <th>SAG-AFTRA Maximum Period of Use</th>
                    <th>:</th>
                    <td>-SAGAFTRAMaximumPeriodOfUse-</td>
                </tr>
								<tr>
                    <th>Earliest Use Cycle Expiring</th>
                    <th>:</th>
                    <td>-earliestUseCycleExpiring-</td>
                </tr>
								<tr>
                    <th>Earliest Talent Expiration</th>
                    <th>:</th>
                    <td>-earliestTalentExpiring-</td>
                </tr>
								<tr>
                    <th>Guarantee Term Expiration:</th>
                    <th>:</th>
                    <td>-guaranteeTermExpiration-</td>
                </tr>
                <tr>
                    <th>Earliest Element Expiration</th>
                    <th>:</th>
                    <td>-earliestElementExpiring-</td>
                </tr>
            </table>
            <p>Please confirm if you intend to Hold or Release this asset.</p>
            <p>Thank You.</p>
            <p>&nbsp;</p>
            <p><strong>Team 7MilesGlobal</strong></p>
        </div>
        `,
	[ExpirationType.MPU]: `
        <div class="container">
            <p>Dear <strong>-client-</strong>,</p>
            <p>The following commercial is reaching its maximum period of use</p>
            <table>
                <tr>
                    <th>Commercial ID</th>
                    <th>:</th>
                    <td>-commercialId-</td>
                </tr>
								<tr>
                    <th>Number of Versions</th>
                    <th>:</th>
                    <td>-numberOfVersions-</td>
                </tr>
                <tr>
                    <th>Title</th>
                    <th>:</th>
                    <td>-title-</td>
                </tr>
								<tr>
                    <th>Advertiser</th>
                    <th>:</th>
                    <td>-advertiser-</td>
                </tr>
								<tr>
                    <th>Ad-Agency</th>
                    <th>:</th>
                    <td>-adAgency-</td>
                </tr>
								<tr>
                    <th>Contract Type</th>
                    <th>:</th>
                    <td>-contractType-</td>
                </tr>
                <tr>
                    <th>SAG-AFTRA Maximum Period of Use</th>
                    <th>:</th>
                    <td>-SAGAFTRAMaximumPeriodOfUse-</td>
                </tr>
								<tr>
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
								<tr>
                    <th>Important Dates to Consider</th>
                    <th>:</th>
                    <td></td>
                </tr>
								<tr>
                    <th>Next Holding Fee Due</th>
                    <th>:</th>
                    <td>-nextHoldingFeeDue-</td>
                </tr>
								<tr>
                    <th>Earliest Talent Expiration</th>
                    <th>:</th>
                    <td>-earliestTalentExpiration-</td>
                </tr>
								<tr>
                    <th>Guarantee Term Expiration</th>
                    <th>:</th>
                    <td>-guaranteeTermExpiration-</td>
                </tr>
								<tr>
                    <th>Earliest Element Expiration</th>
                    <th>:</th>
                    <td>-earliestElementExpiration-</td>
                </tr>
            </table>
            <p>Please confirm if you intend to continue to air this commercial beyond this date, so that necessary contracts can be negotiated for renewals.</p>
            <p>Thank You.</p>
            <p>&nbsp;</p>
            <p><strong>Team 7MilesGlobal</strong></p>
        </div>
        `,
	[ExpirationType.UsageRight]: `
        <div class="container">
            <p>Dear <strong>-client-</strong>,</p>
            <p>The Use Rights for the following commercial is expiring soon</p>
            <table>
                <tr>
                    <th>Commercial ID</th>
                    <th>:</th>
                    <td>-commercialId-</td>
                </tr>
                <tr>
                    <th>Title</th>
                    <th>:</th>
                    <td>-title-</td>
                </tr>
								<tr>
                    <th>Advertiser</th>
                    <th>:</th>
                    <td>-advertiser-</td>
                </tr>
								<tr>
                    <th>Ad-Agency</th>
                    <th>:</th>
                    <td>-adAgency-</td>
                </tr>
								<tr>
                    <th>Contract Type</th>
                    <th>:</th>
                    <td>-contractType-</td>
                </tr>
                <tr>
                    <th>Use Type</th>
                    <th>:</th>
                    <td>-useType-</td>
                </tr>
                <tr>
                    <th>Expiration date</th>
                    <th>:</th>
                    <td>-expiryDate-</td>
                </tr>
								<tr>
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
								<tr>
                    <th>Important Dates to Consider</th>
                    <th>:</th>
                    <td></td>
                </tr>
								<tr>
                    <th>Maximum Period of Use</th>
                    <th>:</th>
                    <td>-maximumPeriodOfUse-</td>
                </tr>
								<tr>
                    <th>Next Holding Fee Due</th>
                    <th>:</th>
                    <td>-nextHoldingFeeDue-</td>
                </tr>
								<tr>
                    <th>Earliest Talent Expiration</th>
                    <th>:</th>
                    <td>-earliestTalentExpiration-</td>
                </tr>
								<tr>
                    <th>Guarantee Term Expiration</th>
                    <th>:</th>
                    <td>-guaranteeTermExpiration-</td>
                </tr>
								<tr>
                    <th>Earliest Element Expiration</th>
                    <th>:</th>
                    <td>-earliestElementExpiration-</td>
                </tr>
                <tr>
                    <th>Description</th>
                    <th>:</th>
                    <td><span style="color: red !important;">-description-</span></td>
                </tr>
            </table>
            <p>Please confirm if you intend to continue to air this commercial for the media/use type beyond the current period of use. Please let us know how long you would like to extend the rights for.</p>
            <p>Disclaimer: Disclaimer</p>
						<p>Thank You.</p>
            <p>&nbsp;</p>
            <p><strong>7MilesGlobal</strong></p>
        </div>
        `,
	[ExpirationType.Contract]: `
				<div class="container">
					<p>Dear <strong>-client-</strong>,</p>
					<p>Hii</p>
					<p>Attached is contract for form. Please make sure to completes all the forms at the session<p>
					<p>Please make sure to sign the contract/forms and return to me, so we can submit payroll.<p>
					<p><strong>Team 7MilesGlobal</strong></p>
			</div>
	`,
	[ExpirationType.ElementExpire]: `
        <div class="container">
            <p>Dear <strong>-client-</strong>,</p>
            <p>The Rights Contracted for the following Element is expiring soon</p>
            <table>
				<tr>
                    <th>Ad-Agency</th>
                    <th>:</th>
                    <td>-adAgency-</td>
                </tr>
				<tr>
                    <th>Advertiser</th>
                    <th>:</th>
                    <td>-advertiser-</td>
                </tr>
				<tr>
                    <th>Element ID</th>
                    <th>:</th>
                    <td>-elementId-</td>
                </tr>
				<tr>
                    <th>Element Name</th>
                    <th>:</th>
                    <td>-elementName-</td>
                </tr>
				<tr>
                    <th>Element Type</th>
                    <th>:</th>
                    <td>-elementType-</td>
                </tr>
				<tr>
                    <th>Licensor</th>
                    <th>:</th>
                    <td>-licensor-</td>
                </tr>
				<tr>
                    <th>Media Type</th>
                    <th>:</th>
                    <td>-mediaType-</td>
                </tr>
                <tr>
                    <th>Region-Country</th>
                    <th>:</th>
                    <td>-territory- - -countries-</td>
                </tr>
				<tr>
                    <th>No Of Cast</th>
                    <th>:</th>
                    <td>-talentData-</td>
                </tr>
				<tr>
                    <th>Expiry Date</th>
                    <th>:</th>
                    <td>-expirationDate-</td>
                </tr>
            </table>
            <p>Please confirm if you intend to continue to use this element rights, beyond the current period of purchase. Please let us know how long you would like to extend the rights for.</p>
            <p>Disclaimer: Disclaimer</p>
						<p>Thank You.</p>
            <p>&nbsp;</p>
            <p><strong>7MilesGlobal</strong></p>
        </div>
        `,
};

export const AddNewModule = {
	COMPANY : 'company',
	ASSET : 'asset',
	PERSON : 'people',
	ELEMENT : 'element',
	CONTRACT : 'contract',
};

export const TalentUnionTypes = [
	'SAG AFTRA',
	'AFM',
	'NON UNION'
];

export const TalentUnionAgreementTypes = [	
	'TV Commercial Standard',
	'Audio CS',
	'Audio Standard',
	'Corporate/Educational/Non Broadcast',
	'TV Commercial ACS'
];

export const TalentSubCategoryTypes = [
	'Asian Language',
	'Dealer A',
	'Dealer B',
	'Foreign Use only',
	'Low budget digital waiver',
	'Made for cable only',
	'Made for Foreign only',
	'Made for Industrial only',
	'Made for Internet only',
	'Made for new media only ',
	'Non Air Demo',
	'Public service announcement',
	'Regional',
	'Seasonal',
	'Social Media Waiver',
	'Spanish Language',
	'Test Market',
];

export const TalentEmploymentCategoryTypes = [
	'Body Double',
	'Character Voice',
	'Choreographer',
	'Contractor',
	'Dancer',
	'Dancer 3+',
	'Dancer Duo',
	'Extra 13 Weeks',
	'Extra in Perp/buyout',
	'Group 3-5',
	'Group 6-8',
	'Group 9 or more ',
	'Group Signature 3-5',
	'Group Signature 6-8',
	'Group Signature 9 or More',
	'Group Singers, Dancers, Speakers 3-5',
	'Group Singer, Speaker 6-8',
	'Group dancer 6+',
	'Group Singer 9+',
	'Hand Model 13 Weeks',
	'Hand Model Perp/buyout',
	'Model',
	'Musician',
	'Pilot',
	'Principal',
	'Puppeteer',
	'Photo Double',
	'Singer',
	'Singer Duo',
	'Sound effect artist',
	'Speciality Act',
	'Stand In 13 Weeks',
	'Stand In Perp/buyout',
	'Stunt Coordinator',
	'Stunt Performer',
	'Voice over',
];

export const TextEditorConfig = {
	toolbar: {
		items: [
			'selectAll',
			'|', 'undo', 'redo',
			'|', 'heading',
			'|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
			'|', 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', 'code',
			'|', 'blockQuote', 'codeBlock', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',
			'|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
		],
		shouldNotGroupWhenFull: false
	}
};

export const GovernmentForms = [
	'W4 - short version',
	'W8 Ben - short version',
	'W9 Ben - short version',
	'Minor trust form',
	'NY wage theft prevention form',
	'CA wage theft prevention form'
];

export const NoteTextEditorConfig = {
	toolbar: {
		items: [
			'selectAll',
			'|', 'undo', 'redo',
			'|', 'heading',
			'|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
			'|', 'bold', 'italic', 'strikethrough', 'underline',
			'|', 'bulletedList', 'numberedList'
		],
		shouldNotGroupWhenFull: false
	}
};

export const _7MG_CLIENT = '7MG';
export const _7MILES_GLOBAL = '7MILESGLOBAL';
export const _7MILES = '7MILES';

export const AssetFields = [
	'title',
	'adId',
	'expireMpuEndDate',
	'assetFile.fileType'
];

export const SessionFields = [
	'useRightType',
	'startCycleDate',
	'cycleExpireDate',
	'cycleLength',
	'note'
];

export const CompanyFields = [
	'companyNames.0.name',
	'companyNames.1.name',
	'companyNames.2.name',
	'companyType.mainType',
	'companyType.subType'
];

export const PersonFields = [
	'personName.firstName',
	'personName.middleName',
	'personName.lastName'
];

export const CompanyFieldMap = new Map([
	['companyNames.0.name', 'Legal name'],
	['companyNames.1.name', 'Business name'],
	['companyNames.2.name', 'DBA name'],
	['companyType.mainType', 'Main type'],
	['companyType.subType', 'Sub type']
]);

export const PersonFieldMap = new Map([
	['personName.firstName', 'First name'],
	['personName.middleName', 'Middle name'],
	['personName.lastName', 'Last name']
]);

export const AssetFieldMap = new Map([
	['title', 'Title'],
	['adId', 'AdId'],
	['expireMpuEndDate', 'Expiry Date'],
	['assetFile.fileType', 'Asset Type']
]);

export const SessionFieldMap = new Map([
	['mediaType', 'Media Type'],
	['note', 'Notes'],
	['startCycleDate', 'Cycle Start Date'],
	['cycleExpireDate', 'Cycle End Date'],
	['note', 'Notes'],
	['cycleLength', 'Cycle Length'],
	['useRightType', 'Use Right Type']
]);

export const ModuleNames = {
	Company : 'Company',
	Asset : 'Asset',
	People : 'People',
	Element: 'Element',
	Notification: 'Notification',
	ReportUpload: 'ReportUpload',
	Contracts: 'Contracts',
	Reports: 'Reports'
};

export const FederalTaxClassificationOptions = [
	{ label:'INDIVIDUAL/SOLE PROPRIETOR', value:'INDIVIDUAL/SOLE PROPRIETOR'},
	{ label:'C-CORPORATION', value:'C-CORPORATION'},
	{ label:'S-CORPORATION', value:'S-CORPORATION'},
	{ label:'PARTNERSHIP', value:'PARTNERSHIP'},
	{ label:'TRUST/ESTATE', value:'TRUST/ESTATE'},
	{ 
		label:'LIMITED LIABILITY COMPANY(LLC)', 
		value:'LIMITED LIABILITY COMPANY(LLC)',
		options: [
			{ label:'C-CORPORATION(LLC)', value:'C-CORPORATION(LLC)'},
			{ label:'S-CORPORATION(LLC)', value:'S-CORPORATION(LLC)'},
			{ label:'PARTNERSHIP(LLC)', value:'PARTNERSHIP(LLC)'}
		]
	},
];
export const SocialMediaTypes = [
	'FACEBOOK',
	'INSTAGRAM',
	'LINKEDIN',
	'SKYPE',
	'SNAPCHAT',
	'TUMBLR',
	'TWITTER',
	'TIKTOK',
	'YOUTUBE',
	'PINTEREST'
];

export const SignatoryUnionType = {
	[UNION_TYPE.AFM]: {
		name: UNION_TYPE.AFM,
		agreements: ['TV & RADIO COMMERCIAL']
	},
	[UNION_TYPE.SAG_AFTRA]: {
		name: UNION_TYPE.SAG_AFTRA,
		agreements: [
			'AUDIO ACS',
			'AUDIO STANDARD',
			'CORPORATE/EDUCATIONAL/NON BROADCAST',
			'TV COMMERCIAL ACS',
			'TV COMMERCIAL STANDARD'
		],
	}
};

export const ContactTypeOptions = [
	{ label: 'ACCOUNTING/INVOICES', value: 'ACCOUNTING_INVOICES' },
	{ label: 'MAILING', value: 'MAILING' },
	{ label: 'PRIMARY', value: 'PRIMARY' },
	{ label: 'REGIONAL OFFICE', value: 'REGIONAL_OFFICE' }
];

export const CompanyNameType = {
	legalName: 'legalName',
	abbreviation: 'abbreviation',
	dbaName: 'DBAName'
};

export const ContractUnionType = {
	[UNION_TYPE.AFM]: {
		name: UNION_TYPE.AFM,
		agreements: []
	},
	[UNION_TYPE.PRINT]: {
		name: UNION_TYPE.PRINT,
		agreements: []
	},
	[UNION_TYPE.SAG_AFTRA]: {
		name: UNION_TYPE.SAG_AFTRA,
		agreements: [
			'Audio ACS',
			'Audio Commercial Audition Report',
			'Audio Standard 22',
			'Corporate Educational/Non Broadcast (Co-Ed)',
			'Infomercial',
			'TV Commercial Standard 22',
		],
	}
};

export const ContractAgreementType = [
	'COMMERCIALS-PRINCIPAL CONTRACT-22',
	'COMMERCIALS-EXTRA CONTRACT-22'
];

export const ContractStatus = {
	Draft: 'Draft',
	EmailSent: 'EmailSent',
	Active: 'Active',
	Expired: 'Expired',
	Cancelled: 'Cancelled',
	DueReview: 'Due Review',
	DueSigning: 'Due Signing',
};

export const ContractStatusOptions = [
	{label: 'Draft', value: 'Draft'},
	{label: 'Active', value: 'Active'},
	{label: 'Expired', value: 'Expired'},
	{label: 'Cancelled', value: 'Cancelled'},
	{label: 'Due Review', value: 'Due Review'},
	{label: 'Due Signing', value: 'Due Signing'},
];

export const AdditionalFeeSplProOptions = [
	{label: 'Cancellation Fee', value: 'Cancellation_Fee'},
	{label: 'Stunt Adjustment', value: 'Stunt_Adjustment'},
	{label: 'Manicure', value: 'Manicure'},
	{label: 'Travel', value: 'Travel'},
	{label: 'Wardrobe/Fitting', value: 'Wardrobe/Fitting'},
];

export const DealPointType = [
	'EDITS/VERSIONS',
	'DEMOS',
	'SOCIAL MEDIA POSTS',
	'SOCIAL MEDIA STORIES',
	'STILL IMAGES',
	'TAGS',
	'AUTOGRAPHS',
	'OTHER'
];

export const MiscellaneousType = ['CONTRACTS', 'MATERIALS', 'REQUESTS', 'OTHER'];

export const MiscellaneousTypes = {
	[MISCELLANEOUS_TYPE.CONTRACTS]: {
		contractItems: [
			'CANCELLATION/KILL FEE',
			'CONTRACT TERM',
			'EXCLUSIVITY',
			'NDA',
			'NON – DISPARAGEMENT',
			'OT RATE',
			'PRE NEGOTIATED ITEMS',
			'TERRITORY',
			'OTHER'
		]
	},
	[MISCELLANEOUS_TYPE.MATERIALS]: {
		contractItems: [
			'BTS',
			'CREATIVE APPROVAL',
			'HASHTAGS',
			'PR INTERVIEWS',
			'VO APPROVAL',
			'OTHER'
		]
	},
	[MISCELLANEOUS_TYPE.REQUESTS]: {
		contractItems: [
			'CATERING REQUESTS',
			'GLAM SQUAD',
			'SPECIAL REQUESTS',
			'TRAILER',
			'TRAVEL'
		]
	},
	[MISCELLANEOUS_TYPE.OTHER]: {
		contractItems: ['OTHERS']
	},
};

export const ContractType = {
	UNION: 'UNION',
	NON_UNION: 'NON UNION',
	MSA: 'MSA',
};

export const CONTRACT_FORM = {
	SAG_AFTRA_SD_EMP_CTR_PRI_COM_EX1_Y22 : '2022 STANDARD SAG-AFTRA EMPLOYMENT CONTRACT - PRINCIPALS IN COMMERCIALS (EXHIBIT 1)',
	SAG_AFTRA_SD_EMP_CTR_COM_PRI_PER_Y22 : '2022 SAG-AFTRA STANDARD EMPLOYMENT CONTRACT - COMMERCIALS - PRINCIPAL PERFORMERS',
	AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM : 'AFTRA STANDARD EMPLOYMENT CONTRACT - INDUSTRIAL/EDUCATIONAL FILM OR VIDEOTAPE PROGRAMS',
	SAG_AFTRA_SD_EXT_COM_EX2_Y22 : '2022 STANDARD SAG-AFTRA - EXTRAS IN COMMERCIALS (EXHIBIT 2)',
	SAG_AFTRA_SD_AUD_COM : 'STANDARD SAG-AFTRA - AUDIO COMMERCIALS',
	SAG_AFTRA_SD_ACS_AUD_COM_Y22 : '2022 STANDARD SAG-AFTRA - ACS AUDIO COMMERCIALS',
	NON_UNION_PER_AGRMNT_OFF_CAM_PRI: 'NON-UNION PERFORMER AGREEMENT - OFF CAMERA PRINCIPAL',
	NON_UNION_PER_AGRMNT_ON_CAM_PRI: 'NON-UNION PERFORMER AGREEMENT - ON CAMERA PRINCIPAL',
	NON_UNION_TALENT_AGRMNT: 'NON-UNION TALENT AGREEMENT',
	NON_UNION_PER_AGRMNT_ON_CAM_PRI_TALENT_AGRMNT: 'NON-UNION PERFORMER AGREEMENT - ON CAMERA PRINCIPAL, NON-UNION TALENT AGREEMENT',
	NON_UNION_TALENT_AGREEMENT_EXTRA: 'NON-UNION TALENT AGREEMENT – EXTRA',
	NON_UNION_TALENT_AGREEMENT_VOICE_OVER: 'NON-UNION TALENT AGREEMENT – VOICE OVER',
	NON_UNION_TALENT_AGREEMENT_ON_CAMERA_PRINCIPAL: 'NON-UNION TALENT AGREEMENT – ON CAMERA PRINCIPAL',
	CREATOR_AGREEMENT : 'CREATOR AGREEMENT',
	INFLUENCER_AGREEMENT : 'INFLUENCER AGREEMENT',
	MSA_TEMPLATE_THREE : 'MSA TEMPLATE THREE',
	SAG_AFTRA_COED : 'SAG-AFTRA CORPORATE/EDUCATIONAL & NON-BROADCAST CONTRACT STANDARD EMPLOYMENT CONTRACT',
	SAG_AFTRA_TV_PRINCIPAL:'2022 STANDARD SAG-AFTRA EMPLOYMENT CONTRACT FOR COMMERCIALS'
};

export const ContractAgreementMapping = {
	[ContractType.UNION]: [
		CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_PRI_COM_EX1_Y22,
		CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_COM_PRI_PER_Y22,
		CONTRACT_FORM.AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM,
		CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_EX2_Y22,
		CONTRACT_FORM.SAG_AFTRA_SD_AUD_COM,
		CONTRACT_FORM.SAG_AFTRA_SD_ACS_AUD_COM_Y22,
		CONTRACT_FORM.SAG_AFTRA_COED,
		CONTRACT_FORM.SAG_AFTRA_TV_PRINCIPAL
	],
	[ContractType.NON_UNION]: [
		CONTRACT_FORM.NON_UNION_PER_AGRMNT_OFF_CAM_PRI,
		CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI,
		CONTRACT_FORM.NON_UNION_TALENT_AGRMNT,
		CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI_TALENT_AGRMNT,
		CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_EXTRA,
		// CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_VOICE_OVER,
		CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_ON_CAMERA_PRINCIPAL,
	],
	[ContractType.MSA]: [
		CONTRACT_FORM.CREATOR_AGREEMENT,
		CONTRACT_FORM.INFLUENCER_AGREEMENT,
		CONTRACT_FORM.MSA_TEMPLATE_THREE,
	],
	'OTHER FORMS': [
		'NY WAGE THEFT PREVENTION FORM',
		'MINOR TRUST INFORMATION FORM',
		'CA WAGE THEFT PREVENTION FORM',
		'W4',
		'W8',
		'W9'
	]
};

export const PerformerCategoryOptions = [
	{label: 'Principal Performer', value: 'Principal Performer'},
	{label: 'Stunt Performer', value: 'Stunt Performer'},
	{label: 'Stunt Coordinator', value: 'Stunt Coordinator'},
	{label: 'Contractor', value: 'Contractor'},
	{label: 'Specialty Act', value: 'Specialty Act'},
	{label: 'Dancer', value: 'Dancer'},
	{label: 'Singer', value: 'Singer'},
	{label: 'Group Signature 9 or more', value: 'Group Signature 9 or more'},
	{label: 'Group 3-5', value: 'Group 3-5'},
	{label: 'Group 6-8', value: 'Group 6-8'},
	{label: 'Signature - Solo or Duo', value: 'Signature - Solo or Duo'},
	{label: 'Group Signature 3-5', value: 'Group Signature 3-5'},
	{label: 'Group Signature 6-8', value: 'Group Signature 6-8'},
	{label: 'Group 9 or more', value: 'Group 9 or more'},
	{label: 'Pilot', value: 'Pilot'},
	{label: 'Sign Language Interpreter', value: 'Sign Language Interpreter'},
	{label: 'Solo or Duo', value: 'Solo or Duo'}
];

export const CONTRACT_REF_ID = {
	MAIN_FORM : 'contract-main-form',
	MAIN_FORM_PART_2 : 'contract-main-form-part-2',
	MAIN_FORM_PART_3 : 'contract-main-form-part-3',
	MAIN_FORM_PART_4 : 'contract-main-form-part-4',
	W4_SHORT_FORM_PART_1 : 'contract-w4-short-form-part-1',
	W4_SHORT_FORM_PART_2 : 'contract-w4-short-form-part-2',
	W4_SHORT_FORM_PART_3 : 'contract-w4-short-form-part-3',
	W8_BEN_SHORT_FORM_PART_1 : 'contract-w8-ben-short-form-part-1',
	W8_BEN_SHORT_FORM_PART_2 : 'contract-w8-ben-short-form-part-2',
	W9_BEN_SHORT_FORM : 'contract-w9-ben-short-form',
	W9_BEN_SHORT_FORM_PART_2 : 'contract-w9-ben-short-form-part-2',
	MINOR_TRUST_FORM : 'contract-minor-trust-form',
	NY_THEFT_PREVENTION_FORM : 'contract-ny-theft-prevention-form',
	CA_WAGE_THEFT_PREVENTION_FORM : 'contract-ca-wage-theft-prevention-form',
};

export const CONTRACT_GUARDIAN = {
	FATHER : 'FATHER',
	MOTHER : 'MOTHER',
	GUARDIAN : 'GUARDIAN'
};

export const mediaTypeValue = 'MEDIATYPE';
export const mediaMainTypeValue = 'MEDIAMAINTYPE';
export const mediaSubTypeValue = 'MEDIASUBTYPE';

export const mediaType = [
	{
		id: '1',
		label: 'All Media',
		type: mediaTypeValue,
		column: 'first'
	},
	{
		id: '2',
		label: 'Broadcast',
		type: mediaTypeValue,
		column: 'first'
	},
	{
		id: '3',
		label: 'Video/TV',
		type: mediaTypeValue,
		column: 'first'
	},
	{
		id: '4',
		label: 'Broadcast/Linear',
		type: mediaTypeValue,
		column: 'first'
	},
	{
		id: '5',
		label: 'Audio/Radio',
		type: mediaTypeValue,
		column: 'first'
	},
	{
		id: '6',
		label: 'Digital',
		type: mediaTypeValue,
		column: 'second'
	},
	{
		id: '7',
		label: 'Out of Home',
		type: mediaTypeValue,
		column: 'third'
	},
	{
		id: '8',
		label: 'Print',
		type: mediaTypeValue,
		column: 'fourth'
	},
	{
		id: '9',
		label: 'TV Commercial',
		type: mediaTypeValue,
		column: 'fifth'
	},
	{
		id: '101',
		label: 'Network',
		type: mediaMainTypeValue,
		column: 'first'
	},
	{
		id: '102',
		label: 'Cable',
		type: mediaMainTypeValue,
		column: 'first'
	},
	{
		id: '103',
		label: 'Editorial',
		type: mediaMainTypeValue,
		column: 'first'
	},
	{
		id: '104',
		label: 'Video on Demand (VOD)',
		type: mediaMainTypeValue,
		column: 'first'
	},
	{
		id: '105',
		label: 'Terrestrial Radio',
		type: mediaMainTypeValue,
		column: 'first'
	},
	{
		id: '106',
		label: 'Editorial',
		type: mediaMainTypeValue,
		column: 'first'
	},
	{
		id: '107',
		label: 'Satellite Radio',
		type: mediaMainTypeValue,
		column: 'first'
	},
	{
		id: '108',
		label: 'On Air Promos',
		type: mediaMainTypeValue,
		column: 'first'
	},
	{
		id: '109',
		label: 'Display Ads',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '110',
		label: 'E-Commerce',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '111',
		label: 'Editorial',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '112',
		label: 'Podcasts',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '113',
		label: 'Pre/Mid/Post Rolls',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '114',
		label: 'YouTube',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '115',
		label: 'Social Media',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '116',
		label: 'Streaming/OTT',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '117',
		label: 'Websites',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '118',
		label: 'Augmented/Virtual Reality',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '119',
		label: 'Video Games',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '120',
		label: 'Airports',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '121',
		label: 'Billboards',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '122',
		label: 'Cinema/Theatrical',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '123',
		label: 'Digital/OOH',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '124',
		label: 'Industrial',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '125',
		label: 'Posters',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '126',
		label: 'PR Launch',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '127',
		label: 'Closed Circuit',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '128',
		label: 'Retail',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '129',
		label: 'Stadiums',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '130',
		label: 'Trade Shows',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '131',
		label: 'Transit',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '132',
		label: 'Vending Machines',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '133',
		label: 'Brochures',
		type: mediaMainTypeValue,
		column: 'fourth'
	},
	{
		id: '134',
		label: 'Catalogs',
		type: mediaMainTypeValue,
		column: 'fourth'
	},
	{
		id: '135',
		label: 'Banners',
		type: mediaMainTypeValue,
		column: 'fourth'
	},
	{
		id: '136',
		label: 'Collateral',
		type: mediaMainTypeValue,
		column: 'fourth'
	},
	{
		id: '137',
		label: 'Direct Marketing',
		type: mediaMainTypeValue,
		column: 'fourth'
	},
	{
		id: '138',
		label: 'Editorial',
		type: mediaMainTypeValue,
		column: 'fourth'
	},
	{
		id: '139',
		label: 'Packaging',
		type: mediaMainTypeValue,
		column: 'fourth'
	},
	{
		id: '140',
		label: 'Publications',
		type: mediaMainTypeValue,
		column: 'fourth'
	},
	{
		id: '141',
		label: 'Corporate/Internal ',
		type: mediaMainTypeValue,
		column: 'third'
	},
	{
		id: '142',
		label: 'Broadcast/Linear Domestic',
		type: mediaMainTypeValue,
		column: 'fifth'
	},
	{
		id: '1001',
		label: 'Network (Class A)',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1002',
		label: 'Diginet/ION',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1003',
		label: 'Cable',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1004',
		label: 'National',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1005',
		label: 'Local',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1006',
		label: 'Wildspot',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1007',
		label: 'All Markets Excluding NY/LA',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1008',
		label: 'Dealer',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1009',
		label: 'All Other North American Use',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1010',
		label: 'Spanish Language - Program',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1011',
		label: 'Theatrical/Industrial',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '143',
		label: 'Digital Use',
		type: mediaMainTypeValue,
		column: 'fifth'
	},
	{
		id: '1013',
		label: 'Traditional Digital',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1014',
		label: 'Streaming Platforms',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1015',
		label: 'Amazon Prime',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1016',
		label: 'Hulu',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1017',
		label: 'Netflix',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1018',
		label: 'Disney +',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1019',
		label: 'Apple TV+',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1020',
		label: 'Peacock',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1021',
		label: 'Paramount Plus',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1022',
		label: 'Tubi',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1023',
		label: 'Roku',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1024',
		label: 'Social Media',	
		type: mediaSubTypeValue,
		column: 'fifth'
	},	
	{
		id: '1056',
		label: 'Meta',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1057',
		label: 'Instagram',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1058',
		label: 'LinkedIn',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1059',
		label: 'SnapChat',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1060',
		label: 'TikTok',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1061',
		label: 'X (Twitter)',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1025',
		label: 'Gaming Platforms/Virtual Worlds/Augmented',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '144',
		label: 'Foreign',
		type: mediaMainTypeValue,
		column: 'fifth'
	},
	{
		id: '1062',
		label: 'Worldwide',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1063',
		label: 'United Kingdom',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1064',
		label: 'Europe',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1065',
		label: 'Asia Pacific',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1066',
		label: 'Japan',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '1067',
		label: 'Rest Of World',
		type: mediaSubTypeValue,
		column: 'fifth'
	},
	{
		id: '145',
		label: 'Low Budget Digital Waiver',
		type: mediaMainTypeValue,
		column: 'fifth'
	},
	{
		id: '146',
		label: 'Social Media and YouTube Waiver',
		type: mediaMainTypeValue,
		column: 'fifth'
	},
	{
		id: '147',
		label: 'PSA Waiver',
		type: mediaMainTypeValue,
		column: 'fifth'
	},
	{
		id: '1030',
		label: 'Meta',
		type: mediaMainTypeValue,
		column: 'second'
	},
	{
		id: '1031',
		label: 'Instagram',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1032',
		label: 'LinkedIn',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1033',
		label: 'SnapChat',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1034',
		label: 'TikTok',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1035',
		label: 'X (Twitter)',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1036',
		label: 'Amazon Prime',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1037',
		label: 'Hulu',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1038',
		label: 'Netflix',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1039',
		label: 'Disney +',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1040',
		label: 'Apple TV+',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1041',
		label: 'Peacock',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1042',
		label: 'Paramount Plus',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1043',
		label: 'Tubi',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1044',
		label: 'Roku',
		type: mediaSubTypeValue,
		column: 'second'
	},
	{
		id: '1045',
		label: 'In Store',
		type: mediaSubTypeValue,
		column: 'third'
	},
	{
		id: '1046',
		label: 'POS/POP',
		type: mediaSubTypeValue,
		column: 'third'
	},
	{
		id: '1047',
		label: 'Banners',
		type: mediaSubTypeValue,
		column: 'fourth'
	},
	{
		id: '1048',
		label: 'Swag',
		type: mediaSubTypeValue,
		column: 'fourth'
	},
	{
		id: '1049',
		label: 'Packaging',
		type: mediaSubTypeValue,
		column: 'fourth'
	},
	{
		id: '1050',
		label: 'Other',
		type: mediaSubTypeValue,
		column: 'fourth'
	},
	{
		id: '1051',
		label: 'Hard Cover',
		type: mediaSubTypeValue,
		column: 'fourth'
	},
	{
		id: '1052',
		label: 'Magazine (incl Online)',
		type: mediaSubTypeValue,
		column: 'fourth'
	},
	{
		id: '1053',
		label: 'NewsPaper',
		type: mediaSubTypeValue,
		column: 'fourth'
	},
	{
		id: '1054',
		label: 'Soft Cover',
		type: mediaSubTypeValue,
		column: 'fourth'
	},
	{
		id: '1055',
		label: 'Trade Paperbacks',
		type: mediaSubTypeValue,
		column: 'fourth'
	}
];

export const mediaTypeOptions = [
	{
		key: '1',
		title: 'All Media',
		type: mediaTypeValue,
	},
	{
		key: '2',
		title: 'Broadcast',
		type: mediaTypeValue,
	},
	{
		key: '3',
		title: 'Video/TV',
		type: mediaTypeValue,
	},
	{
		key: '4',
		title: 'Broadcast/Linear',
		type: mediaTypeValue,
		children: [
			{
				key: '101',
				title: 'Network',
				type: mediaMainTypeValue,
			},
			{
				key: '102',
				title: 'Cable',
				type: mediaMainTypeValue,
			},
			{
				key: '103',
				title: 'Editorial',
				type: mediaMainTypeValue,
			},
			{
				key: '104',
				title: 'Video on Demand (VOD)',
				type: mediaMainTypeValue,
			}
		],
	},
	{
		key: '5',
		title: 'Audio/Radio',
		type: mediaTypeValue,
		children: [
			{
				key: '105',
				title: 'Terrestrial Radio',
				type: mediaMainTypeValue,
			},
			{
				key: '106',
				title: 'Editorial',
				type: mediaMainTypeValue,
			},
			{
				key: '107',
				title: 'Satellite Radio',
				type: mediaMainTypeValue,
			},
			{
				key: '108',
				title: 'On Air Promos',
				type: mediaMainTypeValue,
			}
		],
	},
	{
		key: '6',
		title: 'Digital',
		type: mediaTypeValue,
		children: [
			{
				key: '109',
				title: 'Display Ads',
				type: mediaMainTypeValue,
			},
			{
				key: '110',
				title: 'E-Commerce',
				type: mediaMainTypeValue,
			},
			{
				key: '111',
				title: 'Editorial',
				type: mediaMainTypeValue,
			},
			{
				key: '112',
				title: 'Podcasts',
				type: mediaMainTypeValue,
			},
			{
				key: '113',
				title: 'Pre/Mid/Post Rolls',
				type: mediaMainTypeValue,
			},
			{
				key: '114',
				title: 'YouTube',
				type: mediaMainTypeValue,
			},
			{
				key: '115',
				title: 'Social Media',
				type: mediaMainTypeValue,
				children: [
					{
						key: '1030',
						title: 'Meta'
					},
					{
						key: '1031',
						title: 'Instagram'
					},
					{
						key: '1032',
						title: 'LinkedIn'
					},
					{
						key: '1033',
						title: 'SnapChat'
					},
					{
						key: '1034',
						title: 'TikTok'
					},
					{
						key: '1035',
						title: 'X (Twitter)'
					}
				]
			},
			{
				key: '116',
				title: 'Streaming/OTT',
				type: mediaMainTypeValue,
				children: [
					{
						key: '1036',
						title: 'Amazon Prime',
					},
					{
						key: '1037',
						title: 'Hulu'
					},
					{
						key: '1038',
						title: 'Netflix'
					},
					{
						key: '1039',
						title: 'Disney +'
					},
					{
						key: '1040',
						title: 'Apple TV+'
					},
					{
						key: '1041',
						title: 'Peacock'
					},
					{
						key: '1042',
						title: 'Paramount Plus'
					},
					{
						key: '1043',
						title: 'Tubi'
					},
					{
						key: '1044',
						title: 'Roku'
					}
				]
			},
			{
				key: '117',
				title: 'Websites',
				type: mediaMainTypeValue,
			},
			{
				key: '118',
				title: 'Augmented/Virtual Reality',
				type: mediaMainTypeValue,
			},
			{
				key: '119',
				title: 'Video Games',
				type: mediaMainTypeValue,
			}
		],
	},
	{
		key: '7',
		title: 'Out of Home',
		type: mediaTypeValue,
		children: [
			{
				key: '120',
				title: 'Airports',
				type: mediaMainTypeValue,
			},
			{
				key: '121',
				title: 'Billboards',
				type: mediaMainTypeValue,
			},
			{
				key: '122',
				title: 'Cinema/Theatrical',
				type: mediaMainTypeValue,
			},
			{
				key: '141',
				title: 'Corporate/Internal ',
				type: mediaMainTypeValue,
			},
			{
				key: '123',
				title: 'Digital/OOH',
				type: mediaMainTypeValue,
			},
			{
				key: '124',
				title: 'Industrial',
				type: mediaMainTypeValue,
			},
			{
				key: '125',
				title: 'Posters',
				type: mediaMainTypeValue,
			},
			{
				key: '126',
				title: 'PR Launch',
				type: mediaMainTypeValue,
			},
			{
				key: '127',
				title: 'Closed Circuit',
				type: mediaMainTypeValue,
			},
			{
				key: '128',
				title: 'Retail',
				type: mediaMainTypeValue,
				children: [
					{
						key: '1045',
						title: 'In Store',
					},
					{
						key: '1046',
						title: 'POS/POP'
					}
				]
			},
			{
				key: '129',
				title: 'Stadiums',
				type: mediaMainTypeValue,
			},
			{
				key: '130',
				title: 'Trade Shows',
				type: mediaMainTypeValue,
			},
			{
				key: '131',
				title: 'Transit',
				type: mediaMainTypeValue,
			},
			{
				key: '132',
				title: 'Vending Machines',
				type: mediaMainTypeValue,
			}
		],
	},
	{
		key: '8',
		title: 'Print',
		type: mediaTypeValue,
		children: [
			{
				key: '133',
				title: 'Brochures',
				type: mediaMainTypeValue,
			},
			{
				key: '134',
				title: 'Catalogs',
				type: mediaMainTypeValue,
			},
			{
				key: '135',
				title: 'Banners',
				type: mediaMainTypeValue,
			},
			{
				key: '136',
				title: 'Collateral',
				type: mediaMainTypeValue,
				children: [
					{
						key: '1047',
						title: 'Banners'
					},
					{
						key: '1048',
						title: 'Swag'
					},
					{
						key: '1049',
						title: 'Packaging'
					},
					{
						key: '1050',
						title: 'Other'
					}
				]
			},
			{
				key: '137',
				title: 'Direct Marketing',
				type: mediaMainTypeValue,
			},
			{
				key: '138',
				title: 'Editorial',
				type: mediaMainTypeValue,
			},
			{
				key: '139',
				title: 'Packaging',
				type: mediaMainTypeValue,
			},
			{
				key: '140',
				title: 'Publications',
				type: mediaMainTypeValue,
				children: [
					{
						key: '1051',
						title: 'Hard Cover',
					},
					{
						key: '1052',
						title: 'Magazine (incl Online)'
					},
					{
						key: '1053',
						title: 'NewsPaper'
					},
					{
						key: '1054',
						title: 'Soft Cover'
					},
					{
						key: '1055',
						title: 'Trade Paperbacks'
					}
				]
			},
		],
	},
	{
		key: '9',
		title: 'TV Commercial',
		type: mediaTypeValue,
		children: [
			{
				key: '142',
				title: 'Broadcast/Linear Domestic',
				type: mediaMainTypeValue,
				children: [
					{
						key: '1001',
						title: 'Network (Class A)'
					},
					{
						key: '1002',
						title: 'Diginet/ION'
					},
					{
						key: '1003',
						title: 'Cable',
						children: [
							{
								key: '1004',
								title: 'National'
							},
							{
								key: '1005',
								title: 'Local'
							},
						]
					},
					{
						key: '1006',
						title: 'Wildspot',
						children: [
							{
								key: '1007',
								title: 'All Markets Excluding NY/LA'
							}
						]
					},
					{
						key: '1008',
						title: 'Dealer',
					},
					{
						key: '1009',
						title: 'All Other North American Use',
					},
					{
						key: '1010',
						title: 'Spanish Language - Program',
					},
					{
						key: '1011',
						title: 'Theatrical/Industrial',
					}
				],
			},
			{
				key: '143',
				title: 'Digital Use',
				type: mediaMainTypeValue,
				children: [
					{
						key: '1013',
						title: 'Traditional Digital'
					},
					{
						key: '1014',
						title: 'Streaming Platforms',
						children: [
							{
								key: '1015',
								title: 'Amazon Prime',
							},
							{
								key: '1016',
								title: 'Hulu'
							},
							{
								key: '1017',
								title: 'Netflix'
							},
							{
								key: '1018',
								title: 'Disney +'
							},
							{
								key: '1019',
								title: 'Apple TV+'
							},
							{
								key: '1020',
								title: 'Peacock'
							},
							{
								key: '1021',
								title: 'Paramount Plus'
							},
							{
								key: '1022',
								title: 'Tubi'
							},
							{
								key: '1023',
								title: 'Roku'
							}
						]
					},
					{
						key: '1024',
						title: 'Social Media',	
						children: [
							{
								key: '1056',
								title: 'Meta'
							},
							{
								key: '1057',
								title: 'Instagram'
							},
							{
								key: '1058',
								title: 'LinkedIn'
							},
							{
								key: '1059',
								title: 'SnapChat'
							},
							{
								key: '1060',
								title: 'TikTok'
							},
							{
								key: '1061',
								title: 'X (Twitter)'
							}
						]
					},
					{
						key: '1025',
						title: 'Gaming Platforms/Virtual Worlds/Augmented'
					}
				],
			},
			{
				key: '144',
				title: 'Foreign',
				type: mediaMainTypeValue,
				children: [
					{
						key: '1062',
						title: 'Worldwide'
					},
					{
						key: '1063',
						title: 'United Kingdom'
					},
					{
						key: '1064',
						title: 'Europe'
					},
					{
						key: '1065',
						title: 'Asia Pacific'
					},
					{
						key: '1066',
						title: 'Japan'
					},
					{
						key: '1067',
						title: 'Rest Of World'
					}
				]
			},
			{
				key: '145',
				title: 'Low Budget Digital Waiver',
				type: mediaMainTypeValue,
			},
			{
				key: '146',
				title: 'Social Media and YouTube Waiver',
				type: mediaMainTypeValue,
			},
			{
				key: '147',
				title: 'PSA Waiver',
				type: mediaMainTypeValue,
			}
		]
	},
];

export const MAX_FILE_SIZE_IN_BYTES = 1073741824; // 1GB

export const minDateValue = '0001-01-01T00:00:00';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export 	const Contract_Note_Type = {
	SPECIAL_PROVISIONS: 'Special Provisions',
	FREE_FORM: 'Free Form',
	NOTES: 'Notes',
};

export const ContractResourceType = {
	Material: 'MATERIAL',
	Service: 'SERVICE',
};

export const ContractBankUserTypes = {
	Talent_Directly:'Talent-Directly',
	Loan_Out_Corp:'Loan-Out-Corp',
	Talent_Agency:'Talent-Agency'
};

export const ASSET_USE_RIGHT_CYCLE_UNIT = {
	DAYS:'DAYS',
	WEEKS:'WEEKS',
	MONTHS:'MONTHS',
	YEARS:'YEARS',
};

export const ASSET_EDIT_TYPE = {
	PAID_EDITS: 'PAID EDITS',
	PERMITTED_CHANGES: 'PERMITTED CHANGES',
	ADDRESSABLE_EDITS: 'ADDRESSABLE EDITS',
};

export const ASSET_VERSION_TYPE = {
	SHORTER_VERSION: 'SHORTER VERSION',
	LONGER_VERSION: 'LONGER VERSION',
};

export const ASSET_EDIT_VERSION_TYPE_TO_SUBTYPE_MAPPING = {
	[ASSET_EDIT_TYPE.PERMITTED_CHANGES]: [
		'CHANGE VO/MUSIC',
		'CHANGES TO OFF CAMERA MESSAGE',
		'DEALER & TAG CHANGES',
		'FOREIGN LANGUAGE VO',
		'LEGAL/NETWORK REQUIREMENT ',
		'PRODUCT CHANGES (SAME PRODUCT LINE/VARIANT) IN THE NATURE OF A TAG',
		'REARRANGEMENT OF ON CAMERA MATERIAL',
		'SPECIAL OFFERS AND PROMOTIONS',
		'SUPERS',
	],
	[ASSET_EDIT_TYPE.PAID_EDITS]: [
		'BEYOND THE NATURE OF A TAG',
		'CHANGE OF SETTING VIA CGI OR GREEN SCREEN',
		'TALENT IN DIFFERENT WARDROBE AND/OR PROPS',
	],
	[ASSET_EDIT_TYPE.ADDRESSABLE_EDITS]: [
		'ADVERTISER LIBRARY',
		'MULTIPLE VARIATIONS FOR DYNAMICALLY INSERTING TO INDIV HOUSEHOLDS',
	],
	[ASSET_VERSION_TYPE.LONGER_VERSION]: [],
	[ASSET_VERSION_TYPE.SHORTER_VERSION]: [],
};

export const UNION_TYPES = {
	ACTRA: 'ACTRA',
	AFTRA: 'AFTRA',
	AFM: 'AFM',
	SAG: 'SAG',
	SAG_AFTRA: 'SAG AFTRA',
	UDA: 'UDA',
	NON_UNION: 'NON UNION',
};

export const ABBREVIATED_CAST_CATEGORIES_MAPPING = {
	[UNION_AGREEMENTS.TV_COMMERCIAL_ACS]: {
		[OccupationType.PRINCIPAL]: {
			abbreviation: 'P',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.EXTRA_UNLIMITED_BUYOUT]: {
			abbreviation: 'EXB',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.EXTRA_13_WEEKS]: {
			abbreviation: 'EX13',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.VOICE_OVER]: {
			abbreviation: 'VO',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.DANCER]: {
			abbreviation: 'DA',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.GROUP_3_5]: {
			abbreviation: 'GD3-5',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.GROUP_6_8]: {
			abbreviation: 'GD6-8',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.GROUP_9_OR_MORE]: {
			abbreviation: 'GD9+',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.GROUP_SINGER_3_5]: {
			abbreviation: 'GS3-5',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.GROUP_SINGER_6_8]: {
			abbreviation: 'GS6-8',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.GROUP_SINGER_9_MORE]: {
			abbreviation: 'GS 9+',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.HAND_MODEL_UNLIMITED_BUYOUT]: {
			abbreviation: 'HMB',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.HAND_MODEL_13_WEEKS]: {
			abbreviation: 'HMB13',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.MODEL]: {
			abbreviation: 'MDL',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.PILOT]: {
			abbreviation: 'PI',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.PUPPETEER]: {
			abbreviation: 'PUP',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.SINGER]: {
			abbreviation: 'SS',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.SINGER_DUO]: {
			abbreviation: 'DUO',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.DANCER_DUO]: {
			abbreviation: 'DADUO',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.GROUP_SINGERS_DANCERS_SPEAKERS_3_5]: {
			abbreviation: OccupationType.GROUP_SINGERS_DANCERS_SPEAKERS_3_5,
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.GROUP_SINGERS_DANCERS_SPEAKERS_3_5]: {
			abbreviation: OccupationType.GROUP_SINGERS_DANCERS_SPEAKERS_3_5,
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.GROUP_DANCER_6_PLUS]: {
			abbreviation: OccupationType.GROUP_DANCER_6_PLUS,
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.GROUP_SINGER_SPEAKER_9_PLUS]: {
			abbreviation: OccupationType.GROUP_SINGER_SPEAKER_9_PLUS,
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.GROUP_SINGER_SPEAKER_9_PLUS]: {
			abbreviation: OccupationType.GROUP_SINGER_SPEAKER_9_PLUS,
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.CHOREOGRAPHER]: {
			abbreviation: OccupationType.CHOREOGRAPHER,
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.STAND_IN]: {
			abbreviation: 'STIN',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.BODY_DOUBLE]: {
			abbreviation: 'BD',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.PHOTO_DOUBLE]: {
			abbreviation: 'PD',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.SOUND_EFFECT_ARTIST]: {
			abbreviation: 'SEA',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.STUNT]: {
			abbreviation: 'ST',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.STUNT]: {
			abbreviation: 'ST',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.STUNT_COORDINATOR]: {
			abbreviation: 'STCO',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
	},
	[UNION_AGREEMENTS.CORPORATE_EDUCATIONAL_NON_BROADCAST]: {
		[OccupationType.DAY_PERFORMER]: {
			abbreviation: 'DP',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.NARRATOR_SPOKESPERSON_D9]: {
			abbreviation: 'SPKP',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.DAY_PERFORMER_3]: {
			abbreviation: '3DP',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.WEEKLY_5_DAY_STUDIO]: {
			abbreviation: 'W5D',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.WEEKLY_6_DAY_STUDIO]: {
			abbreviation: 'W6D',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.EXTRA_GENERAL_BACKGROUND]: {
			abbreviation: 'EX',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.EXTRA_SILENT_BIT]: {
			abbreviation: 'EXSI',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.SINGER_SOLO_DUO]: {
			abbreviation: 'SSD',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.SINGER_GROUP_3_PLUS]: {
			abbreviation: 'GS3+',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.SINGER_GROUP_STEP_OUT]: {
			abbreviation: 'GSSO',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.INTERACTIVE_VOICE_RECORDING]: {
			abbreviation: 'PIVR',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.STORECASTING]: {
			abbreviation: 'PSC',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.CHOREGRAPHED_DANCER_SWIMMER_SKATER_ETC]: {
			abbreviation: 'CHP',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.UNRECORDED_SILENT_PART]: {
			abbreviation: OccupationType.UNRECORDED_SILENT_PART,
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.NON_PRINCIPAL_AUDIO_PROGRAM_ONLY]: {
			abbreviation: OccupationType.NON_PRINCIPAL_AUDIO_PROGRAM_ONLY,
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.SINGER_SOLO_PLUS_GROUP_16_BARS]: {
			abbreviation: 'SS16',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.SINGER_GROUP_STEP_OUT_8_BARS]: {
			abbreviation: 'SS18',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.PHONECASTING]: {
			abbreviation: 'PPHO',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
	},
	[UNION_AGREEMENTS.AUDIO_ACS]: {
		[OccupationType.ACTOR]: {
			abbreviation: 'ACT',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.ANNOUNCER]: {
			abbreviation: 'ANN',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.SOLO_SINGER]: {
			abbreviation: 'SS',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.DANCER_DUO]: {
			abbreviation: 'DUO',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.GROUP_SINGER_SPEAKER_3_5]: {
			abbreviation: 'GS 3-5',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.GROUP_SINGER_SPEAKER_6_8]: {
			abbreviation: 'GS 6-8',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.GROUP_SINGER_SPEAKER_9_PLUS]: {
			abbreviation: 'GS 9+',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.SOUND_EFFECT_PERFORMER]: {
			abbreviation: 'SEP',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
	},
	[UNION_AGREEMENTS.INFOMERCIAL]: {
		[OccupationType.LINES_OR_LESS_5]: {
			abbreviation: '5LL',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.HOST]: {
			abbreviation: 'HO',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.MODEL]: {
			abbreviation: 'MDL',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.EXTRA]: {
			abbreviation: 'EX',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
	},
	[UNION_AGREEMENTS.TV_AND_RADIO_COMMERCIAL]: {
		[OccupationType.MUSICIAN]: {
			abbreviation: 'M',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.LEADER]: {
			abbreviation: 'L',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.ORCHESTRATOR]: {
			abbreviation: 'O',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.COPYIST]: {
			abbreviation: 'CP',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
	},
	[UNION_TYPES.NON_UNION]: {
		[OccupationType.PRINCIPAL]: {
			abbreviation: 'P',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.EXTRA]: {
			abbreviation: 'EX',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.VOICE_OVER]: {
			abbreviation: 'VO',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.BODY_DOUBLE]: {
			abbreviation: 'BD',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.CHOREOGRAPHER]: {
			abbreviation: 'CHGPH',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.DANCER]: {
			abbreviation: 'DA',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.GROUP_DANCER]: {
			abbreviation: 'GDA',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.GROUP_SINGER]: {
			abbreviation: 'GS',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.HAND_MODEL]: {
			abbreviation: 'HM',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.MODEL]: {
			abbreviation: 'MDL',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.MUSICIAN]: {
			abbreviation: 'MUS',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.PHOTO_DOUBLE]: {
			abbreviation: 'PD',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.PILOT]: {
			abbreviation: 'PI',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.PUPPETEER]: {
			abbreviation: 'PUP',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.SINGER]: {
			abbreviation: 'SS',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.SPECIALITY_ACT]: {
			abbreviation: 'SA',
			control: CAST_ABBREVIATION_CONTROL.BOTH,
		},
		[OccupationType.STAND_IN]: {
			abbreviation: 'STIN',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.STUNT_COORDINATOR]: {
			abbreviation: 'STCO',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
		[OccupationType.STUNT_PERFORMER]: {
			abbreviation: 'ST',
			control: CAST_ABBREVIATION_CONTROL.ON_CAMERA,
		},
	},
	[UNION_TYPES.AFM]: {
		[OccupationType.MUSICIAN]: {
			abbreviation: 'M',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.LEADER]: {
			abbreviation: 'L',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.ARRANGER]: {
			abbreviation: 'A',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.ORCHESTRATOR]: {
			abbreviation: 'O',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
		[OccupationType.COPYIST]: {
			abbreviation: 'CP',
			control: CAST_ABBREVIATION_CONTROL.OFF_CAMERA,
		},
	},
};

export const CONTRACT_PAYROLLCOMPANY_NAMES ={
	THE_TEAMS_COMPANY : 'the team company',
	HIGHLAND_TALENT_COMPANY:'highland talent company'
};
export const AdditionalFeeDefineOptions = [
	{label: 'Haircut', value: 'Haircut'},
	{label: 'Mileage', value: 'Mileage'},
	{label: 'Manicure', value: 'Manicure'},
	{label: 'Per Diem', value: 'Per_Diem'},
	{label: 'Cab/Uber', value: 'Cab_Uber'},
	{label: 'Wardrobe', value: 'Wardrobe'},
	{label: 'Cancellation Fees', value: 'Cancellation_Fees'},
];

export const SessionMediaTypeForUnion = [
	SESSION_MEDIA_TYPE.CORPORATE_EDUCATIONAL_BROADCAST,
	SESSION_MEDIA_TYPE.DIGITAL,
	SESSION_MEDIA_TYPE.MUSIC,
	SESSION_MEDIA_TYPE.PRINT,
	SESSION_MEDIA_TYPE.RADIO,
	SESSION_MEDIA_TYPE.TV,
];

export const SessionMediaTypeForNonUnion = [
	SESSION_MEDIA_TYPE.DIGITAL,
	SESSION_MEDIA_TYPE.INDUSTRIAL_NON_BROADCAST,
	SESSION_MEDIA_TYPE.INFOMERCIAL,
	SESSION_MEDIA_TYPE.MULTI,
	SESSION_MEDIA_TYPE.PRINT,
	SESSION_MEDIA_TYPE.RADIO_COMMERCIAL,
	SESSION_MEDIA_TYPE.TV_COMMERCIAL,
	SESSION_MEDIA_TYPE.BEHIND_THE_SCENE_BTS,
	SESSION_MEDIA_TYPE.FILM,
	SESSION_MEDIA_TYPE.GAMES,
	SESSION_MEDIA_TYPE.HISTORICAL,
	SESSION_MEDIA_TYPE.LIVE_EVENT,
	SESSION_MEDIA_TYPE.MUSIC_VIDEO,
	SESSION_MEDIA_TYPE.PROMO,
	SESSION_MEDIA_TYPE.PUBLIC_RELATIONS_EVENT,
	SESSION_MEDIA_TYPE.RADIO_PROGRAM,
	SESSION_MEDIA_TYPE.SPONSORSHIP,
	SESSION_MEDIA_TYPE.TRAILER,
	SESSION_MEDIA_TYPE.TV_PROGRAM,
];