import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import styles from './breadcrumbs.module.scss';
import { Home } from '@mui/icons-material';
import { IStore } from '@/store';
import { useSelector } from 'react-redux';
import useNotification from '@/common/hooks/useNotification';

export interface ICustomCrumb {
	crumbToFind: string;
	crumbToReplaceWith: string;
	crumbReplaceLabel: string
}

interface Props {
	separator?: string;
	customCrumb?: ICustomCrumb[];
	maxCount?:number;
}

const NabuBreadcrumbs = (props: Props) => {
	const location = useLocation();
	let currentLink = '';
	const isEditOpen = useSelector((state: IStore) => state.appContext?.isEditOpen);
	const formName = useSelector((state: IStore) => state.appContext?.formName);
	const notification = useNotification();

	const showEditSectionNotification = () => {
		notification.notify({
			type: 'error',
			message: `${formName} form is open`
		});
	};

	const crumbs = location.pathname.split('/')
		.filter(crumb => crumb !== '')
		.map(crumb => {
			currentLink += `/${crumb}`;
			return { label: crumb, link : currentLink };
		})
		.map( crumb => {
			const replaceCrumb = props.customCrumb?.find(c=> c.crumbToFind === crumb.link);
			const currentCumb = replaceCrumb?.crumbToReplaceWith ? { label: replaceCrumb.crumbReplaceLabel, link: replaceCrumb.crumbToReplaceWith} : crumb;
			
			return (<Link data-cy='parent_link' onClick={() => isEditOpen && showEditSectionNotification()} key={currentCumb.link} to={isEditOpen ? location.pathname : currentCumb.link}>{currentCumb.label}</Link>);
		});

	return (
		<Breadcrumbs data-cy='subHeader_navigation' className={styles.breadcrumbs} maxItems={props?.maxCount || props.maxCount} separator={props.separator || '>'} aria-label='breadcrumb'>
			<Link to={isEditOpen ? location.pathname : '/'} onClick={() => isEditOpen && showEditSectionNotification()} style={{display:'flex'}}> <Home sx={{ mr: 0.5,  fontSize:28 }} fontSize="inherit" /> </Link>
			{crumbs.map(crumb => crumb)}
		</Breadcrumbs>
	);
};

export default NabuBreadcrumbs;