import { ReactNode } from 'react';
import styles from './card.module.scss';
import { Box } from '@mui/joy';

interface Props {
	title?: string;
	children?: ReactNode;
	icon?: any;
	sx?: any;
	isTransparent?: boolean;
	className?: any
}

export const NabuCard = (props: Props) => {
	return (
		<Box className={props.className} data-cy='default_card_wrapper'>
			<section data-cy='default_cardSection' className={`${styles.card} ${props.isTransparent? styles.cardTransparent :''}`} style={props.sx}>
				<header className={styles.cardHeader}>
					{props.icon && <div className={styles.cardIcon}>{props.icon}</div>}
					<div className={styles.cardTitle} data-cy='retrieve-plan-title'>
						{props.title}
					</div>
				</header>
				<div className={styles.cardBody} data-cy='form_children'>
					{props.children}
				</div>
			</section>
		</Box>
	);
};