import NabuButton from '@/common/components/atoms/button/button';
import DataTable from '@/common/components/molecules/dataTable/dataTable';
import NabuDialog, { IDialogAction } from '@/common/components/molecules/dialog/dialog';
import { DefaultPageSize } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { ITableActionMenuModel } from '@/common/types/dataTable';
import { FilterModel } from '@/common/types/filterModel';
import { getNotificationText, getSecondsInMMSS, notificationMessageForFormOpenState } from '@/common/utils/utils';
import { deleteDeliverable, getAllDeliverables } from '@/services/element';
import store, { IStore } from '@/store';
import { setFormName, setIsEditOpen } from '@/store/app/reducer';
import { Add, DeleteForever, Edit } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeliverableForm from './components/deliverableForm';
import styles from './deliverables.module.scss';
import { DeliverableModel } from './shared/models/deliverableModel';
import TextWrap from '@/common/components/atoms/textWrap/textWrap';

const Deliverables = () => {
	const { elementId } = useParams();
	const [id, setId] = useState('');
	const [rowIndex, setRowIndex] = useState(-1);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [deliverableFormOpen, setdeliverableFormOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [formState, setFormState] = useState(false);
	const [rows, setRows] = useState([] as any);
	const [formData, setFormData] = useState({ ...new DeliverableModel(), elementId: elementId });
	const [page, ] = useState({
		pageNumber: 0,
		pageSize: DefaultPageSize,
		sortDescending: true,
		sortField: 'CreatedAt',
		filters: [{
			property: 'Element.Id',
			value: elementId ?? '',
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL
		}]
	} as FilterModel);

	const columns = [
		{
			id: 'numberOfAssets',
			label: 'Number Of Assets',
		},
		{
			id: 'type',
			label: 'Type',
		},
		{
			id: 'length',
			label: 'Length',
		},
		{
			id: 'editingProvisions',
			label: 'Editing Provisions',
		},
		{
			id: 'noOfEdits',
			label: 'Number Of Edits'
		},
		{
			id : 'actions',
			label :''
		}
	];

	const notification = useNotification();

	const { refetch: fetchDeliverables } = useQuery({
		queryFn: () => getAllDeliverables(page),
		refetchOnWindowFocus: false,
		onSuccess: (res: any) => {
			if (res?.status === StatusCodes.OK) {
				setRows(res?.data?.records ?? []);
			}
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('Payroll Vendors', 'FETCHING', true),
			});
		},
	});

	useEffect(() => {
		fetchDeliverables();
	}, []);

	const isEditOpen = useSelector((state: IStore) => state.appContext?.isEditOpen);

	const showEditSectionNotification = () => {
		notification.notify({
			type: 'error',
			message: notificationMessageForFormOpenState('Deliverable')
		});
	};

	const handleOpen = (data: any) => {
		if(!isEditOpen){
			store.dispatch(setIsEditOpen(true));
			store.dispatch(setFormName(data?.id ? 'Edit Deliverable' : 'Add Deliverable'));
			setFormData(data);
			setFormState(prev => !prev);

			if(data?.id){
				setIsEdit(true);
			}else{
				setIsEdit(false);
				setdeliverableFormOpen(true);
			}
		}else showEditSectionNotification();
	};

	const handleClose = () => {
		store.dispatch(setIsEditOpen(false));
		setdeliverableFormOpen(false);
		setIsEdit(false);
		setRowIndex(-1);
	};

	const primaryActions: ITableActionMenuModel[] = [
		{
			title: () => 'Edit',
			icon: () => Edit,
			onClick: (record: any) => {
				if(!isEditOpen){
					handleOpen(record);
					setRowIndex(record?.rowIndex ?? -1);
				}else showEditSectionNotification();
			},
			iconColor: 'primary',
			'data-cy': 'coveredTrackerEdit',
		},
		{
			title: () => 'Delete',
			icon: () => DeleteForever,
			onClick: (record: any) => openDeleteDialog(record),
			iconColor: 'error',
			'data-cy': 'coveredTrackerDelete',
		}
	];

	const closeDeleteDialog = () => {
		setDeleteDialogOpen(false);
	};

	const openDeleteDialog = (record: any) => {
		setDeleteDialogOpen(true);
		setId(record?.id ?? '');
	};

	const LengthCell = (record: any) => {
		return <div>{record?.length ? getSecondsInMMSS(record.length) : ''}</div>;
	};

	const NoOfEditsCell = (record: any) => {
		const noOfEditValue = record?.noOfEdits || '';
		return <>{noOfEditValue}</>;
	};

	const EditingProvisionCell = (record: any) => {
		const editingProvisions = record?.editingProvisions;
		return(
			<>
				<TextWrap title={editingProvisions}/>
			</>
		);
	};

	const TypeCell = (record: any) => {
		const type = record?.type;
		return(
			<>
				<TextWrap title = {type} maxLength={25}/>
			</>
		);
	};

	const handleDelete = () => {
		deleteDeliverable({ elementId: elementId, deliverableId: id }).then((res: any) => {
			if (res?.status === StatusCodes.OK){
				notification.notify({
					message: 'Deliverable deleted Successfully',
					type: 'success'
				});
				closeDeleteDialog();
				fetchDeliverables();
			} else {
				notification.notify({
					message: 'Error occured while deleting Deliverable',
					type: 'error'
				});
			}
		});	
	};

	const deleteDialogActions : IDialogAction[] = [
		{	
			name: 'Yes, Remove', 
			onClick: handleDelete,
			color: 'error', 
			variant: 'contained',
			'data-cy': 'yes_remove_btn'
		},
		{	
			name: 'Do not remove', 
			onClick: closeDeleteDialog,
			variant: 'outlined',
			'data-cy': 'no_remove_btn'
		},
	];

	return(
		<>
			<NabuDialog
				title='Remove Deliverable?'
				open={deleteDialogOpen}
				maxWidth='xs'
				handleClose={closeDeleteDialog}
				fullWidth={true}
				dialogActions={deleteDialogActions}
				isPersistent={true}>
				<strong>Are you sure you want to remove?</strong>
				<p>This action <b>can not be undone</b></p>
			</NabuDialog>
			<Grid item container columnSpacing={3}  sx={{mb:3}}>
				<Grid item md={6}>
				</Grid>
				<Grid item md={6} dir='rtl'>
					{!deliverableFormOpen && <NabuButton variant='filled' onClick={() => handleOpen({ ...new DeliverableModel(), elementId: elementId })}>
						Add Deliverable<Add/></NabuButton>}
				</Grid>
			</Grid>
			{deliverableFormOpen && (
				<DeliverableForm formData={formData} isEdit={isEdit} formState={formState} refresh={fetchDeliverables} onClose={handleClose} />
			)}
			<div className={styles.dGrid}>
				<div className={styles.listShadow}>
					<Box sx={{height:'calc(-332px + 100vh)'}}>
						<DataTable
							data-cy='all-vendorInfolist'
							rows={rows}
							rowsPerPage={page.pageSize}
							currentPage={page.pageNumber}
							editIndex={rowIndex}
							actionsMenu={primaryActions}
							columns={columns}
							tableName='deliverable' totalRowsCount={0}
							inlineRows={{
								slot: 'detail',
								isOpen: isEdit,
								rowIndex: rowIndex
							}}
						>
							<LengthCell slot='length'/>
							<NoOfEditsCell slot='noOfEdits' />
							<EditingProvisionCell slot ='editingProvisions'/>
							<TypeCell slot = 'type'/>
							<DeliverableForm slot='detail' formData={formData} isEdit={isEdit} formState={formState} refresh={fetchDeliverables} onClose={handleClose}/>
						</DataTable>
					</Box>
				</div>
			</div>
		</>
	);
};

export default Deliverables;