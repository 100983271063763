import NabuBreadcrumbs, { ICustomCrumb } from '@/common/components/atoms/breadcrumbs/breadcrumbs';
import { SubHeader } from '@/common/components/molecules/subHeader/subHeader';

interface Props {
	title: string;
}

export default function ReportsSubheader(props: Props) {
	const customCrumb: ICustomCrumb[] = [
		{
			crumbToFind: '/reports',
			crumbToReplaceWith: '/reports',
			crumbReplaceLabel: 'Reports'
		}
	];

	const CustomBreadCrumb = <NabuBreadcrumbs customCrumb={customCrumb}/>;

	return (
		<SubHeader
			title={props.title}
			breadCrumb={CustomBreadCrumb}
		/>
	);
}
