import { IUserAuthInfo } from '@/store/auth/types';
import { httpClient } from './httpClient';
import { IChangeUserPassword, ICreateUser, ILoginUser } from './models/user';

export const createUser = (payload: ICreateUser, pId) => {
	return httpClient.post<IUserAuthInfo>(`/User/AddUser/${pId}`, payload);
};

export const userLogin = (payload: ILoginUser) => {
	return httpClient.post<IUserAuthInfo>('/User/Login', payload);
};

export const userChangePassword = (payload: IChangeUserPassword) => {
	return httpClient.post('/User/ChangePassWord', payload);
};

export const getUserByName = (userName: string) => {
	return httpClient.post(`/User/GetUser/${userName}`);
};

export const getUserById = (id: string) => {
	return httpClient.post(`/User/GetUserById/${id}`);
};

export const getUserByResetToken = (token: string) => {
	return httpClient.post(`/User/GetUserByResetToken/${token}`);
};

export const sendResetPasswordNotification = (payload: any) => {
	return httpClient.post('/User/SendResetPasswordNotification', payload);
};

export const resetPassword = (payload: any) => {
	return httpClient.post('/User/ResetPassword', payload);
};