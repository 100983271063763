import React from 'react';
import { Alert, Snackbar, AlertColor } from '@mui/material';
import { createContext, } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import styles from './notification.module.scss';

export const NotificationContext = createContext({} as any);

export type NotifyType = { 
    message?: string, 
    type?: AlertColor,
	isOpen?: boolean,
	onClose?: any;
};

export const Notification = (props: any) => {
	return (
		
		<Snackbar
			open={props.isOpen}
			autoHideDuration={3000}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			onClose={props.onClose}
			data-cy={props.data_cy}
			key={props.message}
		>
			<Alert className={styles.notification} icon={<NotificationsIcon fontSize="inherit" />} severity={props.type as any} onClose={props.onClose} 
				iconMapping={{
					success: <NotificationsIcon fontSize="inherit" />,
				}} data-cy="notification-alert">
				<div className={styles.notificationMessage} data-cy='notification-description'>{props.message}</div>
			</Alert>
		</Snackbar>
	);
};

