
import { Box, Grid } from '@mui/material';
import styles from './reports.module.scss';

import { useLocation, useNavigate } from 'react-router-dom';

import NabuButton from '@/common/components/atoms/button/button';
import { ReportType } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import { IStore } from '@/store';
import { DescriptionOutlined, ImportantDevices, Wallpaper } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';

const ReportLandingPage = () => {
	const navigate = useNavigate();

	const isEditOpen = useSelector((state: IStore) => state.appContext?.isEditOpen);
	const formName = useSelector((state: IStore) => state.appContext?.formName);
	const location = useLocation();

	const notification = useNotification();
	const onReportClick = (reportType: string) => {
		if(!isEditOpen){
			navigate(`${isEditOpen ? location.pathname : '/reports/' + reportType}`);
			setAnchorEl(null);
		}else {
			showEditSectionNotification();
		}
	};
	const showEditSectionNotification = () => {
		notification.notify({
			type: 'error',
			message: `${formName} form is open`
		});
	};

	const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
	
	return (
		<Box sx={{p:3}}>
			<Grid item container columnSpacing={3}>
				<Grid item md={12} lg={4}>
					<div className={styles.reportsCard}>
						<h3>< ImportantDevices /> Active Asset List</h3>	
						<NabuButton variant='filled' onClick={() => onReportClick(ReportType.ACTIVE_ASSET)}>View Report</NabuButton>
					</div>
				</Grid>
				<Grid item md={12} lg={4}>
					<div className={styles.reportsCard}>
						<h3><ImportantDevices /> Asset Expiration List</h3>	
						<NabuButton variant='filled' onClick={() => onReportClick(ReportType.ASSET_EXPIRATION)}>View Report</NabuButton>
					</div>
				</Grid>
				<Grid item md={12} lg={4}>
					<div className={styles.reportsCard}>
						<h3><ImportantDevices /> Asset Earliest Expiration</h3>	
						<NabuButton variant='filled' onClick={() => onReportClick(ReportType.ASSET_EARLIEST_EXPIRATION)}>View Report</NabuButton>
					</div>
				</Grid>
				<Grid item md={12} lg={4}>
					<div className={styles.reportsCard}>
						<h3><DescriptionOutlined /> Cast Expiration</h3>	
						<NabuButton variant='filled' onClick={() => onReportClick(ReportType.CAST_EXPIRATION)}>View Report</NabuButton>
					</div>
				</Grid>
				<Grid item md={12} lg={4}>
					<div className={styles.reportsCard}>
						<h3><DescriptionOutlined /> Next Holding Fee Due</h3>	
						<NabuButton variant='filled' onClick={() => onReportClick(ReportType.NEXT_HOLDING_FEE_DUE)}>View Report</NabuButton>
					</div>
				</Grid>
				<Grid item md={12} lg={4}>
					<div className={styles.reportsCard}>
						<h3><DescriptionOutlined /> Use Rights Expiring</h3>	
						<NabuButton variant='filled' onClick={() => onReportClick(ReportType.USE_RIGHTS_EXPIRING)}>View Report</NabuButton>
					</div>
				</Grid>
				<Grid item md={12} lg={4}>
					<div className={styles.reportsCard}>
						<h3><Wallpaper /> Active Elements</h3>	
						<NabuButton variant='filled'  onClick={() => onReportClick(ReportType.ACTIVE_ELEMENTS)}>View Report</NabuButton>
					</div>
				</Grid>
				<Grid item md={12} lg={4}>
					<div className={styles.reportsCard}>
						<h3><Wallpaper /> Element Expiration</h3>	
						<NabuButton variant='filled' onClick={() => onReportClick(ReportType.ELEMENT_EXPIRATION)}>View Report</NabuButton>
					</div>
				</Grid>
			</Grid>
			
		</Box>
	);
};

export default ReportLandingPage;