import React, { forwardRef } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

// eslint-disable-next-line react/display-name
export const NabuCheckbox = forwardRef((props: any, ref: any) => {
	return (
		<>	
			<FormGroup>
				<FormControlLabel ref={ref} control={<Checkbox onChange={(event) => props.onChange(event.target.value)} {...props}/>} label={props.label} />
			</FormGroup>
		</>
	);
});
