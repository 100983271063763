import { NonUnionOffCameraPrincipalParts } from './nonUnionOffCameraPrincipal';
import { NonUnionOnCameraPrincipalParts } from './nonUnionOnCameraPrincipal';
import { NonUnionTalentAgreementParts } from './nonUnionTalentAgreement';

const GetSystemTemplate = (value: string) => {
	switch(value){
	case 'NON-UNION ON CAMERA': return NonUnionOnCameraPrincipalParts;
	case 'NON-UNION OFF CAMERA': return NonUnionOffCameraPrincipalParts;
	case 'NON-UNION TALENT AGREEMENT': return NonUnionTalentAgreementParts;
	default: null;
	}
};

export default GetSystemTemplate;