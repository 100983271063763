import NabuButton from '@/common/components/atoms/button/button';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import DataTable from '@/common/components/molecules/dataTable/dataTable';
import NabuDialog, { IDialogAction } from '@/common/components/molecules/dialog/dialog';
import { DefaultPageSize } from '@/common/constants/constants';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useBackNavigation from '@/common/hooks/useBackNavigation';
import useNotification from '@/common/hooks/useNotification';
import { ITableActionMenuModel, columnsType } from '@/common/types/dataTable';
import { IFilterModel } from '@/common/types/filterModel';
import { displayDate, getCompanyDisplayName, getNotificationText } from '@/common/utils/utils';
import { deleteTemplate, getTemplates, saveTemplate } from '@/services/contract';
import { DeleteForever, Edit, Save } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import SnippetFilters from './components/templateFilters';
import TemplateForm from './components/templateForm';
import { TemplateModel } from './shared/models/template';
import styles from './template.module.scss';

const TemplateList = () => {
	const [showTemplate, setShowTemplate] = useState(false);
	const [formData, setFormData] = useState(new TemplateModel());
	const [formState, setFormState] = useState(false);
	const [rows, setRows] = useState([] as any);
	const [totalRecords, setTotalRecords] = useState(0);
	const [templateId, settemplateId] = useState('' as any);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [filterModel, setFilterModel] = useState({
		pageNumber: 0,
		pageSize: DefaultPageSize,
		sortField: 'CreatedAt',
		sortDescending: false,
		filters: []
	} as IFilterModel);
	const [copyTemplateOpen, setCopyTemplate] = useState(false);
	const [templateRecord, setTemplateRecord] = useState<any>();

	//Copy Template Form
	const copyTemplateForm = useForm<{templateName: string}>({
	});

	const navigate = useNavigate();
	const backNavigation = useBackNavigation();

	const handleOpen = () => {
		setFormData({ ...new TemplateModel() });
		backNavigation.startBackNavigation();
		navigate('/contracts/templates/add', { state: { formData: { ...new TemplateModel() }}});
		setFormState((prev: boolean) => !prev);
	};

	const handleClose = () => {
		setShowTemplate(false);
		setFormData({ ...new TemplateModel() });
		setFormState((prev: boolean) => !prev);
	};

	const notification = useNotification();

	const { refetch: fetchTemplates } = useQuery({
		queryKey: ['template', filterModel],
		queryFn: () => getTemplates(filterModel),
		refetchOnWindowFocus: false,
		onSuccess: (res: any) => {
			if (res?.status === StatusCodes.OK) {
				const rows: any = res?.data?.records.map((record: any) => {
					return {
						...record,
						companyId: record?.company?.id,
						companyName: getCompanyDisplayName(record?.company?.companyNames)
					};
				});
				setTotalRecords(res?.data?.totalRecords ?? 0);
				setRows(rows);
			}
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('Templates', 'FETCHING', true),
			});
		},
	});

	const columns: columnsType[] = [
		{
			id: 'templateName',
			label: 'Name'
		},
		{
			id: 'unionType',
			label: 'Contract type'
		},
		{
			id: 'companyName',
			label: 'Company'
		},
		{
			id: 'createdBy',
			label: 'Created By'
		},
		{
			id: 'createdDate',
			label: 'Date created'
		},
		{
			id: 'actions',
			label: ''
		}
	];

	const TemplateNameCell = (record: any) => {
		return <div>
			<a onClick={() => {
				navigate(`/contracts/templates/${record.id}/template-view`, { state: { formData: {...record} }});
				backNavigation.startBackNavigation();
			}}>&nbsp; {record.templateName}</a>
		</div>;
	};

	const handleEdit = (record: TemplateModel) => {
		setFormState(prev => !prev);
		setFormData({ ...record });
		backNavigation.startBackNavigation();
		navigate(`/contracts/templates/${record.id}/edit`, { state: { formData: {...record} }});
	};

	const primaryActions: ITableActionMenuModel[] = [
		{
			title: () => 'Edit',
			icon: () => Edit,
			iconColor: 'primary',
			onClick: (record: any) => handleEdit(record),
			'data-cy': 'contracts_edit',
		},
		{
			title: () => 'Remove',
			icon: () => DeleteForever,
			iconColor: 'error',
			onClick: (record: any) => openDeleteDialog(record),
			'data-cy': 'Contracts_Remove',
		},
		{
			title: () => 'Copy',
			icon: () => ContentCopyIcon,
			iconColor: 'primary',
			onClick: (record: any) => {setCopyTemplate(true); setTemplateRecord(record);},
			'data-cy': 'Contracts_Remove',
		},
	];

	const onPageChange = (currentPage: number) => {
		setFilterModel((currentData: any) => {
			return {
				...currentData,
				pageNumber: currentPage
			};
		});
	};

	const onNumberOfRowsPerPageChange = (numberOfRows: number) => {
		setFilterModel((currentData: any) => {
			return {
				...currentData,
				pageNumber: 0,
				pageSize: numberOfRows
			};
		});
	};

	const handleDelete = () => {
		if(templateId){
			deleteTemplate(templateId).then((res: any) => {
				if(res?.status === StatusCodes.OK){
					notification.notify({
						type: 'success',
						message: 'Template deleted successfully'
					});
					setDeleteOpen(false);
					fetchTemplates();
					settemplateId(undefined);
				}
			});
		}
	};

	const closeDeleteDialog = () => {
		setDeleteOpen(false);
		settemplateId(undefined);
	};

	const openDeleteDialog = (record: TemplateModel) => {
		setDeleteOpen(true);
		settemplateId(record?.id);
	};

	const closeCopyDialog = () => {
		setCopyTemplate(false);
		copyTemplateForm.reset({
			templateName: ''
		});
	};

	const deleteTemplateDialogActions : IDialogAction[] = [
		{	
			name: 'Yes, Remove',
			onClick: handleDelete, 
			color: 'error', 
			variant: 'contained',
			'data-cy': 'yes_remove_btn'
		},
		{	
			name: 'Do not remove', 
			onClick: closeDeleteDialog,
			variant: 'outlined',
			'data-cy': 'no_remove_btn'
		},
	];

	const handleFilterChange = (filters: IFilterModel) => {
		setFilterModel((currentPage: any) => {
			return {
				...currentPage,
				pageNumber: 0,
				filters: filters
			};
		});
	};

	const { mutate: addTemplate } = useMutation({
		mutationFn: saveTemplate,
		onSuccess: (res) => {
			if (res?.status === StatusCodes.OK) {
				notification.notify({
					type: 'success',
					message: getNotificationText('Template', 'UPDATE')
				});
			}else {
				notification.notify({
					type: 'error',
					message: 'Error while saving template'
				});
			}
			closeCopyDialog();
			fetchTemplates();
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Asset', 'UPDATE', true),
				type: 'error',
			});
		},
	});

	const handleCopyTemplateSubmit = (data) => {
		const templateData: TemplateModel = templateRecord;
		templateData.templateName = data.templateName;
		templateData.id = undefined;
		addTemplate(templateData);
	};

	const CreatedDate = (props: any) => {
		return (
			<Box sx={{pl:1}}>
				{displayDate(props?.createdAt)}
			</Box>
		);
	};

	return (
		<>
			<NabuDialog
				title='Remove Template?'
				open={deleteOpen}
				maxWidth='xs'
				handleClose={closeDeleteDialog}
				fullWidth={true}
				dialogActions={deleteTemplateDialogActions}
				isPersistent={true}>
				<strong>Are you sure you want to remove?</strong>
				<p>This action <b>can not be undone</b></p>
			</NabuDialog>
			<NabuDialog
				title='Copy Template'
				open={copyTemplateOpen}
				maxWidth='xs'
				handleClose={closeCopyDialog}
				fullWidth={true}
				dialogActions={[]}
				isPersistent={true}>
				<Grid item lg={12} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
					<FormControl fullWidth>
						<FormLabel>
								Template name<sup />
						</FormLabel>
						<Controller
							control={copyTemplateForm.control}
							name='templateName'
							render={({ field }) => (
								<>
									<NabuTextbox
										{...field}
										placeholder='Template name'
									/>
								</>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item lg={12} dir='rtl'>
					<div >
						<NabuButton variant='filled'
							onClick={copyTemplateForm.handleSubmit(handleCopyTemplateSubmit)}
						>
							{'Save'}
							<Save />
						</NabuButton>
						&nbsp;
						<NabuButton variant='filled' onClick={closeCopyDialog}>Cancel</NabuButton>
					</div>
				</Grid>
			</NabuDialog>
			<Grid
				container
				data-cy='Contracts_heading_wrapper'
			>
			</Grid>
			<Grid
				container
				data-cy='Contracts_heading'
			>
				<SnippetFilters onChange={handleFilterChange} openTemplateForm={handleOpen}/>
			</Grid>
			<Grid sx={{mb:0}}>
				{showTemplate && <TemplateForm refresh={fetchTemplates} formData={formData} formState={formState} onClose={handleClose} />}
			</Grid>
			<Box>
				<div className={'style.dGrid'}>
					<div className={styles.listShadow}>
						<DataTable
							className={styles.contractTable}
							data-cy='contract_List_All'
							rows={rows}
							columns={columns}
							actionsMenu={primaryActions}
							tableName='rightsExpiration'
							totalRowsCount={totalRecords}
							currentPage={filterModel.pageNumber}
							rowsPerPage={filterModel.pageSize}
							onPageChange={onPageChange}
							onNumberOfRowsPerPageChange={onNumberOfRowsPerPageChange}
						>
							<CreatedDate slot='createdDate'/>
							<TemplateNameCell slot='templateName'/>
						</DataTable>
					</div>
				</div>
			</Box>
		</>
	);
};

export default TemplateList;