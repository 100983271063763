import { OPERATORS } from '@/common/enums';
import { IFilterModel, IFilterOption } from '@/common/types/filterModel';
import { Autocomplete, Checkbox, FormHelperText, ListItemText, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export interface IProps {
	onInputChange?: CallableFunction;
  onChange?: any;
  value?: any;
  filterModel?: IFilterModel
  optionGenerator?: any;
  placeholder?: string;
  keyProperty?: string;
	error?: boolean;
	triggerFunction?: boolean;
	helperText?: string;
	multiple?: boolean;
	disabled?: boolean;
}

export interface IState {
  options: any;
  value: string;
}

const NabuAutocomplete = (props: IProps) => {
	const [state, setStateData] = useState({
		options: [],
		value: props.value,
		open: false
	});

	const apiFunction = (filterModel: IFilterModel, value: string) => {
		let options: any;
		if(props.onInputChange) props.onInputChange(filterModel).then((res: any) => {
			options = props.optionGenerator(res?.data);
			setStateData((currentData: any) => {
				return {
					...currentData,
					options
				};
			});
		});
		setStateData((currentData: any) => {
			return {
				...currentData,
				value
			};
		});
	};

	const handleOnInputChange = (_: any, item: string) => {
		let filters: IFilterOption[] = [];

		const keyFilter: IFilterOption = {
			property: props.keyProperty ?? '',
			value: item.toUpperCase(),
			isCaseSensitive: false,
			operator: OPERATORS.CONTAINS
		};

		const otherFilters: IFilterOption[] = props?.filterModel?.filters ? props?.filterModel?.filters.filter((filter: IFilterOption) => filter.property !== props.keyProperty) : [] as IFilterOption[];
		
		if(keyFilter){
			keyFilter.value = item.toUpperCase();
			filters = [keyFilter, ...otherFilters];
		}

		apiFunction({ ...props.filterModel, filters } as IFilterModel, item.toUpperCase());
	};

	useEffect(() => {
		if(props.filterModel) apiFunction(props.filterModel, props.value);
	}, [JSON.stringify(props.value)]);

	useEffect(() => {
		if(props.triggerFunction !== undefined)
			if(props.filterModel) apiFunction(props.filterModel, props?.value ?? []);
	}, [props.triggerFunction]);

	const handleOnChange = (event: any, item: any) => {
		props.multiple ? props.onChange(item ?? []) : props.onChange(item?.id ?? '');
		setStateData((currentData: any) => {
			return {
				...currentData,
				value: item?.label?.toUpperCase() ?? '',
				open: true
			};
		});
	};

	return (
		<>
			{/*<FormControl fullWidth error={props.error}>*/}
			<Autocomplete
				data-cy={`auto-complete-${props.placeholder}`}
				disablePortal
				disabled={props.disabled}
				id="combo-box-demo"
				options={state.options}
				value={state.value}
				multiple={props.multiple}
				disableCloseOnSelect={props.multiple ? true : false}
				onChange={handleOnChange}
				onInputChange={handleOnInputChange}
				renderInput={(params: any) => <TextField placeholder={props.placeholder?.toUpperCase()} data-cy='StaffSelectTextField' {...params}/>}
				renderOption={props.multiple ? (optionProps, option: any, { selected }) => <li {...optionProps} value={option?.id}>
					<Checkbox
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					<ListItemText primary={option?.label}/>
				</li> : undefined}
			/>
			<FormHelperText>{props.helperText}</FormHelperText>
			{/*</FormControl>*/}
		</>
	);
};

export default NabuAutocomplete;
