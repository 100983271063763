import { ModuleNames } from '@/common/constants/constants';
import store from '@/store';
import { setLoader } from '@/store/app/reducer';
import axios, { Axios, AxiosResponse, ResponseType } from 'axios';
import { setModuleName } from './common';

class HttpClient {
	private axiosInstance: Axios;
	private activeRequests: number;

	constructor(apiVersion: string | undefined) {
		this.activeRequests = 0;
		this.axiosInstance = new axios.Axios({
			baseURL: process.env.REACT_APP_API_BASE_URL + '/' + apiVersion
		});

		this.axiosInstance.interceptors.request.use((request) => {
			if (window?.location?.hash?.includes('#/asset')) {
				setModuleName(ModuleNames.Asset);
			}
			const token = localStorage.getItem('access_token');
			const moduleName = localStorage.getItem('moduleName');
			const entityId = localStorage.getItem('entityId');
			request.headers = {
				...request.headers,
				Authorization: `Bearer ${token}`,
				'content-type': 'application/json',
				'moduleName': moduleName ?? '',
				'logGroupId': entityId ?? ''
			} as any;
			this.incrementRequestCounter();
			return request;
		},
		undefined,
		{ synchronous: true }
		);

		this.axiosInstance.interceptors.response.use((response) => {
			this.decrementRequestCounter();
			if (response.status >= 400) {
				if (response.status === 401 || response.status === 595) {
					localStorage.removeItem('access_token');
				}
				return response;
			} else {
				try {
					const data = JSON.parse(response.data);
					return { ...response, data };
				} catch (error) {
					return response;
				}
			}
		},
		() => {
			this.decrementRequestCounter();
		},
		{ synchronous: true }
		);
	}

	private incrementRequestCounter() {
		this.activeRequests += 1;
		store.dispatch(setLoader(true));
	}

	private decrementRequestCounter() {
		this.activeRequests -= 1;
		if (this.activeRequests === 0) {
			store.dispatch(setLoader(false));
		}
	}

	get<T>(endpoint: string, params?: Record<string, any>) {
		return this.axiosInstance.get<T>(endpoint, {
			params
		});
	}

	patch<T>(endpoint: string, params?: Record<string, any>): Promise<T> {
		return this.axiosInstance.patch<any, T>(endpoint, {
			params
		});
	}

	getQRCode<T>(endpoint: string, params?: Record<string, any>, headers: any = {}, responseType: ResponseType = 'json'): Promise<T> {
		return this.axiosInstance.get<any, T>(endpoint, {
			responseType: responseType,
			params,
			headers
		});
	}
	getImage<T>(endpoint: string, params?: Record<string, any>, headers: any = {}, responseType: ResponseType = 'blob'): Promise<T> {
		return this.axiosInstance.get<any, T>(endpoint, {
			responseType: responseType,
			params,
			headers
		});
	}

	uploadFile<T>(endpoint: string, body: any, { params, headers = {} }: { params?: Record<string, any>, headers: Record<string, any> }) {
		return this.axiosInstance.post<T>(endpoint, body, {
			params,
			headers
		});
	}

	postFormData<T>(endpoint: string, body: any, params?: Record<string, any>) {
		return this.axiosInstance.post<T>(endpoint, body, {
			params,
			headers: {'Content-Type': 'multipart/form-data'}
		});
	}

	post<T>(endpoint: string, body?: any, params?: Record<string, any>): Promise<AxiosResponse<T, any>> {
		return this.axiosInstance.post<T>(endpoint, JSON.stringify(body), {
			params,
		});
	}

	delete<T>(endpoint: string,params?: Record<string, any>):Promise<AxiosResponse<T, any>> {
		return this.axiosInstance.delete<T>(endpoint, {
			params,
		});
	}

	deleteV2<T> (endpoint: string, { params, headers = {}, body}: { params?: Record<string, any>, headers: Record<string, any>, body?: Record<string, any> }) {
		return this.axiosInstance.delete<T>(endpoint, {
			params,
			headers,
			data: JSON.stringify(body)
		});
	}

	put<T>(endpoint: string, body?: any, params?: Record<string, any>): Promise<AxiosResponse<T, any>> {
		return this.axiosInstance.put<T>(endpoint, JSON.stringify(body), {
			params,
		});
	}
}

export const httpClient = new HttpClient(process.env.REACT_APP_API_VERSION_V1);
export const httpClientV2 = new HttpClient('client');
