import {
	Grid
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SnippetForm from '../../components/snippetForm';
import { SnippetModel } from '../../shared/models/snippet';
import SnippetInfoSubheader from '../../snippetAddInfo/subheader';
import { Page } from '@/common/components/templates/pages/page/page';

const SaveSnippet = () => {
	const location = useLocation();
	const { snippetId } = useParams();
	const navigate = useNavigate();
	const snippetData = location?.state?.formData ?? new SnippetModel();

	const handleClose = () => {
		navigate('/contracts/snippets/all');
	};

	return (
		<Page SubHeader={<SnippetInfoSubheader snippetName={location.pathname.includes('snippet-view') ? 'View snippet' : (snippetId ? 'Edit snippet' : 'Create Snippet')} snippetId={snippetId ?? ''}/>} hasChild={true}><>
			<Grid sx={{mb:0}}>
				<SnippetForm formData={snippetData} onClose={handleClose} />
			</Grid>
		</></Page>
	);
};

export default SaveSnippet;