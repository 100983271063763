import NabuBreadcrumbs, { ICustomCrumb } from '@/common/components/atoms/breadcrumbs/breadcrumbs';
import { SubHeader } from '@/common/components/molecules/subHeader/subHeader';

interface Props {
	templateId: string;
	templateName: string;
}

const TemplateInfoSubheader = (props: Props) => {
	const TempMoreAction = () => (
		<div>
		</div>
	);

	const customCrumb: ICustomCrumb[] = [
		{
			crumbToFind: '/contracts/templates',
			crumbToReplaceWith: '/contracts/templates',
			crumbReplaceLabel: 'Templates',
		},
		{
			crumbToFind: '/contracts/templates/add',
			crumbToReplaceWith: '/contracts/templates/add',
			crumbReplaceLabel: 'Add',
		},
		{
			crumbToFind: `/contracts/templates/${props.templateId}`,
			crumbToReplaceWith: `/contracts/templates/${props.templateId}/edit`,
			crumbReplaceLabel: 'Template',
		},
		{
			crumbToFind: `/contracts/templates/${props.templateId}/edit`,
			crumbToReplaceWith: `/contracts/templates/${props.templateId}/edit`,
			crumbReplaceLabel: 'Edit',
		}
	];

	const CustomBreadCrumb = <NabuBreadcrumbs customCrumb={customCrumb}/>;
	const subheaderActions = [TempMoreAction];

	return (
		<SubHeader
			breadCrumb={CustomBreadCrumb}
			title={props.templateName}
			subHeaderActions={subheaderActions}
		/>
	);
};

export default TemplateInfoSubheader;
