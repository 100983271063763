import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip, FormControl } from '@mui/material';
import styles from './formsNonUnion.module.scss';
import { PartType } from '@/common/constants/constants';
import { DragHandle } from '../components/formPart';

export const NonUnionOffCameraPrincipalParts = (handleDelete: any, isActionVisible: boolean = true) => {
	return [
		{
			id: 1,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(1)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table className={styles.contractForms} width={'100%'}>
					<tbody>
						<tr>
							<td align='center'><h2>NON-UNION PERFORMER AGREEMENT
								<br></br>
								<span style={{color:'#000'}}>OFF CAMERA PRINCIPAL</span></h2> </td>
						</tr>
					</tbody>
				</table>
				<br></br>
			</div>
		}, {
			id: 2,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(2)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td width={'50%'}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Agency: </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox placeholder="Test Agency" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Agency Job# </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox placeholder="Agency Job#" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Client:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Vital Proteins" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Product:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Vital Proteins" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Recording Co:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Lime Studios, Santa Monica" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Campaign Name:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Q1 Brand Campaign" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Performer Name:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Issa Deas" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>TV Spots:</span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="2 X :30s, 6 X :15s, 4 X :06s, 10 X :10s" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Role:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="VO" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Radio Spots:</span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value=" 2 X :30s" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Session Workday (s):   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="11/16/2023 (session for up to 5 hours)" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Location (City/State):</span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value=" Home Studio, New York" />
								</FormControl>
							</div>
						</td>
					</tr>
				</table>
				<br></br>
			</div>
		}, {
			id: 3,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(3)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr style={{ background: '#808080' }}>

						<th style={{ textAlign: 'center' }} colSpan={2}><div style={{ display: 'flex', justifyContent: 'space-around' }}><span>Time In</span> <span>Out</span>  </div></th>
						<th style={{ textAlign: 'center' }} colSpan={2}><div style={{ display: 'flex', justifyContent: 'space-around' }}><span>Lunch In</span> <span>Out</span>  </div></th>
					</tr>
					<tr>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time'/>
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time'/>
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time'/>
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time'/>
							</FormControl>
						</td>
					</tr>
				</table>
				<p><em><b>**see attached timecard for more details</b></em></p>
				<br></br>
			</div>
		}, {
			id: 4,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(4)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td colSpan={2}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Term:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="1 Year from first use" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Territory:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="North America for Broadcast, Industrial and OOH (print & Digital), Worldwide as it pertains to Internet and New Media" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Exclusivity:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="None" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Estimated First Air Date:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Tentatively scheduled for 12/26/2023" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Radio Session fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="$1,000 + 20% agent’s fee USD." />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>TV/Video Session fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="$1,000 + 20% agent’s fee USD." />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Use Fee: Radio Buyout Use fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="$3,000 + 20% agent’s fee USD" />
								</FormControl>
							</div>
							<p>To be paid within 30 business days of first use, only if performer makes final edit and if used.</p>
						</td>
						<td>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>TV/Video Buyout Use Fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="$6,000 + 20% agent’s fee USD" />
								</FormControl>
							</div>
							
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p><b>Renewal Option:</b> Advertiser may extend all above terms and conditions for a non-consecutive term for an additional payment of</p>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>Radio Buyout Use fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value=": $3,300 + 20% agent’s fee USD" />
								</FormControl>
							</div>
							<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>TV/Video Buyout Use Fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="$6,600 + 20% agent’s fee USD" />
								</FormControl>
							</div>
							<p>for an additional 1-year use period, only if exercised. Option can be exercised by Advertiser within the original term from first use
and if talent remains in final edit. Renewal option to be available for a 5-year window from first use date.</p>
							<p style={{background:'yellow', display:'inline'}}>Agent’s fee will only be paid if talent is represented by agent.</p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p>
								<b>Permitted Use:</b> All audio recordings to be used in any and all types of media, either known now or in the future including but not
limited to broadcast, internet, radio, industrial, and new media including all lifts, edits, variations and cut downs. 
							</p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p>
								<b>Historical uses:</b> Advertising materials may be used anywhere in the world by the Client and its Agency at sales meetings and for
intra-company, research, award, publicity (including, without limitation, on Client’s Ad Agency ‘reel’ and website), file and
reference purposes to be granted in perpetuity.
							</p>
						</td>
					</tr>
				</table>
				<br></br>
				<p>The Agency will administer performer payments on behalf of the Client though Extreme Reach</p>
				<br></br>
				<p>For good and valuable consideration, receipt and sufficiency of which is hereby acknowledged, I hereby agree as follows:
I am appearing as a performer in advertising material being produced by Agency and Client on the work schedule listed above. I
hereby agree to be so recorded, and I grant to Agency and Client, and to other such persons as Agency or Client may designate from
time to time, the absolute right and permission to use any such material, including, without limitation, my likeness as portrayed
therein, during the Term in the Territory in accordance with the terms of Permitted Use. </p>
				<br></br>
				<p>
I agree that I will not hold Agency, Client, or anyone who receives permission from either of them, responsible for any liability
resulting from the use of my likeness and recordings (s) in accordance with the terms hereof, including what might be deemed to be
misrepresentation of me, my character or my person due to distortion, optical illusion or faulty reproduction which may occur in the
finished product. I hereby waive any right that I may have to inspect or approve the finished product or products or the advertising
copy or other matter that may be used in connection therewith.
				</p><br></br>
				<p>
I hereby agree to not post any advertising material or behind the scenes material to my social media or any other venue
which can be viewed by the public without the express written consent of Client. I also agree that I shall abide by any restrictions
placed by client on my use of the advertising material. I shall not use or disclose any of the Client and or Agency trade secrets or
confidential information (including, without limitation, the terms of this agreement). Any advertising materials placed during the Term
which remain on display or in circulation following the expiration of the Term shall not give rise to a claim against the Client and or
Agency, provided, however that they shall not place any additional advertising materials produced hereunder in any media (other
than as permitted herein) following the expiration of the Term. 
				</p>
			</div>
		}, {
			id: 5,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(5)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<p>
				I understand that I will not receive any other payments and my compensation is outlined above. I understand that all
payments and notices to me shall be delivered to the address listed on this release. I also understand that it is my sole responsibility
to inform the Agency and Client of any change of address in writing. If a payment does not reach me due to a change of address,
Agency and Client will hold such payments until notified by me in writing of the new mailing address. Notices with respect to any
provisions herein, including but not limited to notices to renew, extend or exercise any rights or options herein shall be deemed
accepted by me if Agency and/or Client sent such notice to the address which I provided and is unable to reach me due to my failure
to provide change of address notification to Agency and/or Client in writing.
				</p>
				<br></br>
				<p>
During the Term and throughout the Territory, I will not accept employment in, nor authorize use of my name or voice in any
advertising material, for a competitive product or service of Client, as defined above in the Exclusivity clause.
				</p><br></br>
				<p>This agreement is governed by the laws of the State of California, and the parties agree to that jurisdiction if a dispute arises. 
				</p>
				<br></br>
				<p>I warrant and represent that this agreement does not in any way conflict with any existing commitments on my part. Nothing
herein will constitute any obligation on the licensed parties to make any use of any of the rights set forth herein.</p>
				<br></br>
				<p>I am over eighteen (18) years of age and have the right to make this agreement and am authorized to grant these rights.</p>
				<hr></hr>
			</div>
		}, {
			id: 6,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(6)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={0} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td width={210}>
						Signature
						</td>
						<td width={400}>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox placeholder="Signature" />
							</FormControl>
						</td>
						<td width={180}>
							Date:
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='date'/>
							</FormControl>
						</td>
					</tr>
					<tr>
						<td width={210}>
						Print Name:
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox placeholder="Print Name:" />
							</FormControl>
						</td>
						<td width={180}>
						Performer Address
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox placeholder="Performer Address" />
							</FormControl>
						</td>
					</tr>
					<tr>
						<td width={200}>
						Performer Email:
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox placeholder="Performer Email" />
							</FormControl>
						</td>
						<td width={180}>
						Talent Agency / Agent : 
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox placeholder="Linda Stopfer LLC/Linda Stopfer" />
							</FormControl>
						</td>
					</tr>
					<tr>
						<td width={200}>
						Agent Address:
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox placeholder="128 New Hope St. Tuckerton, NJ 08087" />
							</FormControl>
						</td>
						<td width={180}>
						Agent Phone:
						</td>
						<td>
							<FormControl className={styles.tableForms} fullWidth > 
								<NabuTextbox placeholder="646-734-9013"/>
							</FormControl>
						</td>
					</tr>
					<tr>
						<td width={200}>
						Agent Email: 
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox placeholder="Agent Email" />
							</FormControl>
						</td>
					</tr>
				</table>
				<hr></hr>
				<br></br>
				<b>Payment Authorization:</b>
				<p>Performer authorizes payments to which Performer may be entitled hereunder, as follows:</p>
				<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
					<span> To <b>Performer</b> at address listed above or</span>
					<FormControl  className={styles.tableForms}>
						<NabuTextbox placeholder="value" />
					</FormControl>
					<span>To <b>Agent</b> at address listed above</span>
				</div>
				<p><b>Minor:</b></p>
				<div style={{display:'flex', alignItems:'center', gap:'10px'}}><span style={{flex:'none'}}>I, as parent/legal guardian of:   </span>
					<FormControl  className={styles.tableForms}>
						<NabuTextbox placeholder="value" />
					</FormControl>
					<span>agree to the above and I sign this document to signify my agreement.</span>
				</div>
				<hr></hr>
			</div>
		}, {
			id: 7,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(7)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={0} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td width={220}>
						Parent/Guardian Signature
						</td>
						<td>
							<FormControl className={styles.tableForms} fullWidth > 
								<NabuTextbox placeholder="Parent/Guardian Signature"/></FormControl>
						</td>
						<td width={50}>
		Date
						</td>
						<td>
							<FormControl className={styles.tableForms} fullWidth >
								<input type='date'/>
							</FormControl>
						</td>
					</tr>
					<tr>
						<td width={220}>
						Parent/Guardian Print Name:
						</td>
						<td colSpan={3}>
							<FormControl className={styles.tableForms} fullWidth > 
								<NabuTextbox placeholder="Producer’s Signature"/></FormControl>
						</td>
					</tr>
					<tr>
						<td width={220}>
						Producer’s Signature
						</td>
						<td>
							<FormControl className={styles.tableForms} fullWidth > 
								<NabuTextbox placeholder="Producer’s Signature"/></FormControl>
						</td>
						<td width={50}>
		Date
						</td>
						<td>
							<FormControl className={styles.tableForms} fullWidth >
								<input type='date'/>
							</FormControl>
						</td>
					</tr>
					<tr>
						<td width={220}>
						Business Affairs Mgr.
						</td>
						<td>
							<FormControl className={styles.tableForms} fullWidth > 
								<NabuTextbox placeholder="Producer’s Signature"/></FormControl>
						</td>
						<td width={50}>
		Date
						</td>
						<td>
							<FormControl className={styles.tableForms} fullWidth >
								<input type='date'/>
							</FormControl>
						</td>
					</tr>
				</table>
			</div>
		}
	];
};