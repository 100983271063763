import { Page } from '@/common/components/templates/pages/page/page';
import { Box } from '@mui/material';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import styles from './reports.module.scss';
import ReportsSubheader from './subheader/ReportsSubHeader';

const ReportsMainPage = () => {
	const reportType = useParams()?.reportType || '';
	const token = localStorage.getItem('access_token');
	const reportBaseUrl = `${process.env.REACT_APP_REPORT_APP_URL}/#/${reportType}?token=${token}`;
	const iFrameRef = useRef<any>();

	return (
		<Page hasChild={true} SubHeader={<ReportsSubheader title='Reports'/>}>
			<Box>
				{reportBaseUrl && (
					<iframe
						className={styles.iframeContainer}
						src={reportBaseUrl}
						title="Reports"
						ref={iFrameRef}
						loading="lazy"
					>
					</iframe>
				)}
			</Box>
		</Page>
	);
};

export default ReportsMainPage;