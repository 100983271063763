import NabuAutocomplete from '@/common/components/atoms/autocomplete/autocomplete';
import NabuButton from '@/common/components/atoms/button/button';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { NabuCardDefault } from '@/common/components/molecules/cardWhite/cardWhite';
import { NabuSearchFilters } from '@/common/components/molecules/searchFIlter/searchFIlter';
import { DefaultPageSize, PartType, SnippetTypes, TemplatePart } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { ICompany } from '@/common/types/company';
import { IFilterModel, IFilterOption, IPagedResultModel } from '@/common/types/filterModel';
import { getCompanyDisplayName, getNotificationText, replaceSnippetFieldsWithInput } from '@/common/utils/utils';
import { getCompanyBasicDetails } from '@/services/company';
import { getSnippets, getTemplate, getTemplates, saveTemplate } from '@/services/contract';
import store from '@/store';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import { Delete, DeleteForever, ExpandMore, FilterList, MenuOpen, Save, Search } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { AccordionDetails, AccordionSummary, Box, Chip, Fab, FormControl, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import parse from 'html-react-parser';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { SnippetFilterModel, SnippetModel } from '../../snippets/shared/models/snippet';
import { TemplateModel } from '../shared/models/template';
import templateSchema from '../shared/schema/templateSchema';
import GetSystemTemplate from '../systemTemplates';
import { NonUnionOffCameraPrincipalParts } from '../systemTemplates/nonUnionOffCameraPrincipal';
import { NonUnionOnCameraPrincipalParts } from '../systemTemplates/nonUnionOnCameraPrincipal';
import { NonUnionTalentAgreementParts } from '../systemTemplates/nonUnionTalentAgreement';
import styles from './form.module.scss';
import FormPart, { DragHandle } from './formPart';
import Snippet from './snippet';


const ITEM_HEIGHT = 48;

const defaultPart: TemplatePart = {
	id: -1,
	type: PartType.FORMPART,
	component: <table width={'100%'} style={{ visibility: 'hidden' }} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}></table>
};

interface IProps {
	onClose: () => void,
	refresh?: () => void,
	formState?: boolean,
	formData: TemplateModel
}

const TemplateForm = (props: IProps) => {
	const [value, setValue] = useState('');
	const location  = useLocation();
	const [isEditorVisible, setIsEditorVisible] = useState(false);
	const [state, setStateData] = useState({
		searchText: {
			filter: {},
			isSet: false,
		},
		type: {
			filter: {},
			isSet: false,
		}
	} as any);
	const [loading, setLoading] = useState(false);
	const [checked,] = useState(false);
	const [tableData, setTableData] = useState([
		{
			talentName: 'Kale Hills',
			perfCategory: 'Principal',
			role: 'Protagonist',
			agent: 'Reign Talent',
			rateOfPay: 'Scale'
		},
		{
			talentName: 'Mark Bloom',
			perfCategory: 'Principal',
			role: 'Pilot',
			agent: 'Pantheon',
			rateOfPay: 'Scale'
		}
	]);

	const handleAddTalent = () => {
		setTableData([
			...tableData,
			{
				talentName: '',
				perfCategory: '',
				role: '',
				agent: '',
				rateOfPay: ''
			}
		]);
	};

	const replaceTheInput = (domNode: any) => {
		if (domNode.attribs && domNode.attribs.id === 'replace') {
			const placeholder = domNode.attribs.placeholder ?? '';
			return <FormControl className={styles.tableForms} sx={{minWidth:200, marginRight:'5px'}}>
				<NabuTextbox placeholder={placeholder} />
			</FormControl>;
		}
	};
	
	const parsingOptions = {
		replace: replaceTheInput
	};

	const handleRemoveRow = (indexToRemove) => {
		setTableData(tableData.filter((row, index) => index !== indexToRemove));
	};

	const handleInputChange = (index, fieldName, value) => {
		const updatedData = [...tableData];
		updatedData[index][fieldName] = value;
		setTableData(updatedData);
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedOption, setSelectedOption] = useState('All'); // Default value is 'Header'
	const open = Boolean(anchorEl);

	const [snippets, setSnippets] = useState<TemplatePart[]>([]);
	const snippetIds = useMemo(() => snippets.map((col) => col), [snippets]);
	const [formParts, setFormParts] = useState<TemplatePart[]>([defaultPart]);
	const formPartIds = useMemo(() => formParts.map((formPart: any) => formPart.id), [formParts]);
	const [filterModel, setFilterModel] = useState({
		pageNumber: 0,
		pageSize: 1000,
		sortField: 'CreatedAt',
		sortDescending: false,
		filters: []
	} as IFilterModel);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOptionSelect = (option) => {
		setSelectedOption(option);
		handleClose();
	};

	const { mutate: addTemplate } = useMutation({
		mutationFn: saveTemplate,
		onSuccess: (res) => {
			if (res?.status === StatusCodes.OK) {
				notification.notify({
					type: 'success',
					message: form.getValues().id ? getNotificationText('Template', 'UPDATE') : getNotificationText('Template', 'CREATE')
				});
			}else {
				notification.notify({
					type: 'error',
					message: 'Error while saving template'
				});
			}
			props.refresh && props.refresh();
			props.onClose && props.onClose();
			setLoading(false);
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Asset', 'UPDATE', true),
				type: 'error',
			});
		},
	});

	const [active, setActive] = useState<TemplatePart | null>(null);

	const handleDelete = (deleteId: number) => {
		setFormParts((items: TemplatePart[]) => [...items.filter((TemplatePart: TemplatePart) => TemplatePart.id !== deleteId)]);
	};

	const getFirstMissingNumber = (formParts: TemplatePart[]) => {
		if(formParts.length > 0){
			let max = formParts[0].id;
			
			for(let i = 0; i < formParts.length; i++){
				max = Math.max(max, formParts[i].id);
			}

			for(let i = 0; i <= max; i++){
				if(formParts.findIndex((formPart: TemplatePart) => formPart.id === i) == -1){
					return i;
				}
			}

			return max + 1;
		} else return 0;
	};

	const handleDragEnd = (event: DragEndEvent) => {
		const {active, over} = event;
		
		if(active && over){
			const activeType: string = active.data.current?.type ?? '';
			const overType: string = over.data.current?.type ?? '';

			if ((activeType === PartType.FORMPART && overType === PartType.FORMPART) && active.id !== over.id) {
				setFormParts((items) => {
					const oldIndex = items.findIndex((item: TemplatePart) => item.id === active.data.current?.id);
					const newIndex = items.findIndex((item: TemplatePart) => item.id === over.data.current?.id);
					return arrayMove(items, oldIndex, newIndex);
				});
			} else if((activeType === PartType.SNIPPET && overType === PartType.SNIPPET) && active.id !== over.id){
				setSnippets((items) => {
					const oldIndex = items.findIndex((item: TemplatePart) => item.id === active.data.current?.id);
					const newIndex = items.findIndex((item: TemplatePart) => item.id === over.data.current?.id);
					return arrayMove(items, oldIndex, newIndex);
				});
			} else if((activeType === PartType.SNIPPET && overType === PartType.FORMPART) && active.id !== over.id){
				setFormParts((items) => {
					const snippetIndex = snippets.findIndex((item: TemplatePart) => item.id === active.data.current?.id);
					snippets[snippetIndex] = {
						...snippets[snippetIndex],
						id: getFirstMissingNumber(snippets)
					};

					const newIndex = items.findIndex((item: TemplatePart) => item.id === over.data.current?.id);
					const newId = getFirstMissingNumber(formParts);
					items.splice(newIndex, 0, {
						id: newId,
						snippetId: active.data.current?.snippetId,
						snippetName: active.data.current?.snippetName,
						snippetType: active.data.current?.snippetType,
						type: PartType.FORMPART,
						component: active.data.current?.component
					});
					return arrayMove(items, newIndex, newIndex);
				});
			}
		}
	};

	const onDragStart = (event: DragStartEvent) => {
		const data: TemplatePart = event.active.data.current as TemplatePart;
		setActive(data);
	};

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 3
			}
		})
	);

	useEffect(() => {
		const formParts = props.formData.formParts.map((formPart: TemplatePart) => {
			let component: any = '';

			if(formPart.snippetId){
				component = replaceSnippetFieldsWithInput(formPart.component);
			}else {
				const template = GetSystemTemplate(props.formData.predefinedTemplateName);

				if(template){
					component = template(handleDelete, (location.pathname.includes('edit') || location.pathname.includes('add'))).find((templatePart) => templatePart.id === formPart.id)?.component;
				}
			}
			return {
				...formPart,
				component: component
			};
		});

		props.formData.id ? setIsEditorVisible(true) : setIsEditorVisible(false);

		formParts.push(defaultPart);
		setFormParts(formParts);
		form.reset({ ...props.formData, formParts: [] });
		if(!props?.formData?.companyName){
			const userContext = store.getState().userContext;
			const signedInUserStaffDetails =
			userContext?.userAuthInfo?.staffDetail ||
			userContext?.userInfo?.staffDetail ||
			null;
			if (signedInUserStaffDetails) {
				setValue(getCompanyDisplayName(signedInUserStaffDetails.companyNames));
				form.setValue('companyId', signedInUserStaffDetails.id, { shouldDirty: true });
			}
		}else setValue(props.formData.companyName ?? '');
	}, [props.formState]);

	const notification = useNotification();

	const { refetch: fetchSnippets } = useQuery({
		queryKey: ['snippet', filterModel],
		queryFn: () => getSnippets(filterModel),
		refetchOnWindowFocus: false,
		onSuccess: (res: any) => {
			if (res?.status === StatusCodes.OK) {
				const rows: SnippetModel[] = res?.data?.records ?? [];
				
				setSnippets(rows.map((snippet: SnippetModel, index: number) => {
					return {
						snippetId: snippet.id,
						snippetName: snippet.name,
						snippetType: snippet.type,
						id: index,
						type: PartType.SNIPPET,
						component: replaceSnippetFieldsWithInput(snippet.preview)
					};
				}));
			}
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('Snippets', 'FETCHING', true),
			});
		},
	});

	const form = useForm<TemplateModel>({
		defaultValues: { ...new TemplateModel() },
		resolver: yupResolver(templateSchema()),
		mode: 'onChange'
	});

	useEffect(() => {
		fetchSnippets();
	}, [filterModel]);

	const optionGenerator = (result: IPagedResultModel<ICompany>) => {
		if(result){
			return result.records.map((record: any) => {
				return { id: record?.id ?? '', label: getCompanyDisplayName(record?.companyNames) };
			});
		}
	};

	const optionGeneratorForTemplates = (result: any) => {
		if(result){
			return result.records.map((record: any) => {
				return { id: record?.id ?? '', label: record?.templateName };
			});
		}
	};

	const errors = form.formState.errors;

	const handleTemplateTypeChange = (value: string) => {
		switch(value){
		case 'NEW': {
			setFormParts([defaultPart]);
			setIsEditorVisible(true);
			break;
		}
		case 'SYSTEM TEMPLATES': {
			setIsEditorVisible(false);
			form.setValue('predefinedTemplateName', '', { shouldDirty: true });
			break;
		}
		case 'USER CREATED': {
			setIsEditorVisible(false);
			form.setValue('templateId', undefined, { shouldDirty: true });
			break;
		}
		default: setIsEditorVisible(false);
		}
	};

	const handleTemplateChange = (value: string) => {
		switch(value){
		case 'NON-UNION ON CAMERA': {
			setFormParts([defaultPart, ...NonUnionOnCameraPrincipalParts(handleDelete)]);
			break;
		}
		case 'NON-UNION OFF CAMERA': {
			setFormParts([defaultPart, ...NonUnionOffCameraPrincipalParts(handleDelete)]);
			break;
		}
		case 'NON-UNION TALENT AGREEMENT': {
			setFormParts([defaultPart, ...NonUnionTalentAgreementParts(handleDelete)]);
			break;
		}
		}
		setIsEditorVisible(true);
	};

	const onSubmit = (data: TemplateModel) => {
		const parts = formParts.filter((formPart: TemplatePart) => formPart.id !== -1).map((formPart: TemplatePart, index: number) => {
			return {
				...formPart,
				orderId: index,
				component: undefined
			};
		});

		if(parts.length === 0) {
			notification.notify({
				type: 'error',
				message: 'Template is empty'
			});

			return;
		}

		data.formParts = parts;
		addTemplate({ ...data, templateId: undefined });
		setLoading(true);
	};

	const snippetTypes = [...SnippetTypes, 'All'];

	const filterForm = useForm<SnippetFilterModel>({
		defaultValues: {...new SnippetFilterModel()},
		mode: 'onChange'
	});

	const handleFilters = (filterName: string, filter: IFilterOption) => {
		setStateData((currentData: any) => {
			return {
				...currentData,
				[filterName]: {
					isSet: filter.value === 'All' ? false : true,
					filter
				}
			};
		});
	};

	useEffect(() => {
		let filters: any = [];

		if(state.searchText.isSet){
			filters = [state.searchText.filter, ...filters];
		}
		if(state.type.isSet){
			filters = [state.type.filter, ...filters];
		}
		setFilterModel((filterModel) => {
			return {
				...filterModel,
				filters: filters
			};
		});
	}, [state.searchText, state.type]);

	const handleUserTemplateSelect = (value: string) => {
		getTemplate(value).then((res: any) => {
			if(res?.status === StatusCodes.OK){
				const result: TemplateModel = res?.data;

				const formParts = result.formParts.map((formPart: TemplatePart) => {
					let component: any = '';
			
					if(formPart.snippetId){
						component = replaceSnippetFieldsWithInput(formPart.component);
					}else {
						const template = GetSystemTemplate(result.predefinedTemplateName);

						if(template){
							component = template(handleDelete).find((templatePart) => templatePart.id === formPart.id)?.component;
						}
					}
					return {
						...formPart,
						component: component
					};
				});

				form.setValue('predefinedTemplateName', result.predefinedTemplateName, { shouldDirty: true });
				formParts.push(defaultPart);
				setFormParts(formParts);
				setIsEditorVisible(true);
			}
		});
	};

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [tooltipTitle, setTooltipTitle] = useState('Collapse Snippet');

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
		setTooltipTitle(isMenuOpen ? 'Hide Snippet' : 'Open Snippet');
	};

	
	return (
		<>{(location.pathname.includes('edit') || location.pathname.includes('add')) ? <><Box sx={{ width: '100%', mb:3 }}>
			<Box sx={{p:3}}>
				<div className={styles.addContractCard}>
				
					<div className={styles.createTemplateBlock}>
						<Grid item container columnSpacing={3}>
							<Grid item sm={12} md={6} lg={3}>
								<FormControl fullWidth>
									<FormLabel id="demo-row-radio-buttons-group-label">Contract type<sup>*</sup></FormLabel>
									<Controller
										name={'unionType'}
										control={form.control}
										rules={{ required: 'This field is required.' }}
										render={({ field }) => (
											<NabuSelect
												placeholder='Contract type'
												data-cy='person-firstname'
												{...field}
												options={[{ label: 'UNION', value: 'UNION' }, { label: 'NON-UNION', value: 'NON-UNION' }, { label: 'MSA', value: 'MSA' }]}
												error={errors.templateType ? true : false}
												helperText={errors?.templateType?.message ?? ''} />
										)} />
									<FormHelperText>{errors.unionType?.message ?? ''}</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={12} md={6} lg={2}>
								<FormControl fullWidth>
									<FormLabel>
									Company<sup>*</sup>
									</FormLabel>
									<Controller
										control={form.control}
										name={'companyId'}
										render={({ field }) => (<>
											<NabuAutocomplete
												{...field}
												placeholder='COMPANY'
												value={value}
												keyProperty='CompanyNames.Name'
												filterModel={{
													pageNumber: 0,
													pageSize: DefaultPageSize,
													sortDescending: false,
													sortField: 'CreatedDate',
													filters: []
												}}
												error={errors?.companyId ? true : false}
												helperText={errors?.companyId?.message ?? ''}
												onInputChange={getCompanyBasicDetails}
												optionGenerator={optionGenerator}
												multiple={false}
											/>
										</>)}
									/>
								</FormControl>
							</Grid>
							<Grid item sm={12} md={6} lg={2}>
								<FormControl fullWidth>
									<FormLabel>Template name<sup>*</sup></FormLabel>
									<Controller
										name={'templateName'}
										control={form.control}
										rules={{ required: 'This field is required.' }}
										render={({ field }) => (
											<NabuTextbox
												placeholder='Template name'
												data-cy='person-firstname'
												{...field}
												error={errors.templateName ? true : false}
												helperText={errors?.templateName?.message ?? ''} />
										)} />
								</FormControl>
							</Grid>
							<Grid item sm={12} md={6} lg={2}>
								<FormControl fullWidth>
									<FormLabel>Template type<sup>*</sup></FormLabel>
									<Controller
										name={'templateType'}
										control={form.control}
										rules={{ required: 'This field is required.' }}
										render={({ field }) => (
											<NabuSelect
												placeholder='Template type'
												data-cy='person-firstname'
												{...field}
												options={[{ label: 'NEW', value: 'NEW' }, { label: 'User created', value: 'User created' }, { label: 'System templates', value: 'System templates' }]}
												error={errors.templateType ? true : false}
												onChange={(event: any) => { field.onChange(event); handleTemplateTypeChange(event.target.value); }}
												helperText={errors?.templateType?.message ?? ''} />
										)} />
								</FormControl>
							</Grid>
							{form.watch('templateType') === 'USER CREATED' && <Grid item sm={12} md={6} lg={2}>
								<FormControl fullWidth>
									<FormLabel
										id='select-field-demo-label'
										htmlFor='select-field-demo-button'
									>
									Select template<sup></sup>
									</FormLabel>
									<Controller
										control={form.control}
										name={'templateId'}
										render={({ field }) => (<>
											<NabuAutocomplete
												{...field}
												placeholder='Template name'
												keyProperty='TemplateName'
												filterModel={{
													pageNumber: 0,
													pageSize: DefaultPageSize,
													sortDescending: false,
													sortField: 'CreatedDate',
													filters: []
												}}
												onChange={(event) => { field.onChange(event); handleUserTemplateSelect(event); }}
												onInputChange={getTemplates}
												optionGenerator={optionGeneratorForTemplates}
											/>
										</>)}
									/>
								</FormControl>
							</Grid>}
							{form.watch('templateType') === 'SYSTEM TEMPLATES' && <Grid item sm={12} md={6} lg={2}>
								<FormControl fullWidth>
									<FormLabel>Select Template<sup></sup></FormLabel>
									<Controller
										name={'predefinedTemplateName'}
										control={form.control}
										rules={{ required: 'This field is required.' }}
										render={({ field }) => (
											<NabuSelect
												placeholder='Select template'
												data-cy='person-firstname'
												{...field}
												options={[
													{ label: 'NON-UNION PERFORMER AGREEMENT ON CAMERA PRINCIPAL', value: 'NON-UNION ON CAMERA' },
													{ label: 'NON-UNION PERFORMER AGREEMENT OFF CAMERA PRINCIPAL', value: 'NON-UNION OFF CAMERA' },
													{ label: 'NON-UNION TALENT AGREEMENT', value: 'NON-UNION TALENT AGREEMENT' }
												]}
												onChange={(event: any) => { field.onChange(event); handleTemplateChange(event.target.value); }}
												error={errors.predefinedTemplateName ? true : false}
												helperText={errors?.predefinedTemplateName?.message ?? ''} />
										)} />
								</FormControl>
							</Grid>}
							<Grid item md={12} dir='rtl'>
								<NabuButton variant='filled' onClick={form.handleSubmit(onSubmit)}>{loading ? 'Submitting' : <>Save <Save/></>}</NabuButton> &nbsp;
								<NabuButton shouldHandleConfirmAction={form.formState.isDirty} variant='outlined' onClick={props.onClose}>Cancel</NabuButton>
							</Grid>
							{/* <Grid item sm={12} md={6} lg={2}>
								<Box sx={{mt:3.5}}>
									<FormControlLabel
										control={<Checkbox defaultChecked={checked} onChange={handleCheckboxChange} />}
										label="Copy Contract" />
								</Box>
							</Grid> */}
							{checked && (
								<Grid item md={12}>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.bgWhite} style={{padding:'20px'}}>
											<div>
												<table width={'100%'} border={1} cellPadding={0} cellSpacing={0}>
													<thead>
														<tr style={{ background: '#808080' }}>
															<th align='left' style={{ paddingLeft: '6px' }}>Talent Name</th>
															<th align='left' style={{ paddingLeft: '6px' }}>Perf Category</th>
															<th align='left' style={{ paddingLeft: '6px' }}>Role</th>
															<th align='left' style={{ paddingLeft: '6px' }}>Agent</th>
															<th align='left' style={{ paddingLeft: '6px' }}>Rate of pay</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{tableData.map((row, index) => (
															<tr key={index}>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		<NabuTextbox type="text" value={row.talentName} onChange={(e) => handleInputChange(index, 'talentName', e.target.value)} />
																	</FormControl>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		<NabuTextbox type="text" value={row.perfCategory} onChange={(e) => handleInputChange(index, 'perfCategory', e.target.value)} />
																	</FormControl>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		<NabuTextbox type="text" value={row.role} onChange={(e) => handleInputChange(index, 'role', e.target.value)} />
																	</FormControl>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		<NabuTextbox type="text" value={row.agent} onChange={(e) => handleInputChange(index, 'agent', e.target.value)} />
																	</FormControl>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		<NabuTextbox type="text" value={row.rateOfPay} onChange={(e) => handleInputChange(index, 'rateOfPay', e.target.value)} />
																	</FormControl>
																</td>
																<td>
																	<Button variant="text" color="error" onClick={() => handleRemoveRow(index)}><DeleteForever /></Button>
																</td>
															</tr>
														))}
													</tbody>
												</table>
												<Box dir="rtl">
													<NabuButton variant='text' color='primary' onClick={handleAddTalent}>Talent + </NabuButton>
												</Box>
											</div>
										
										</div>
									</div>
								</Grid>
							)}

						</Grid>
					</div>
					
					<Grid item container columnSpacing={3}>
						<Grid item md={12}>
						
							{isEditorVisible && <div>
								<hr></hr>
								<div>
									<DndContext sensors={sensors} onDragStart={onDragStart} onDragCancel={() => setActive(null)} onDragEnd={handleDragEnd}>
										{createPortal(<DragOverlay>
											{active && ((active.type === PartType.SNIPPET) ? <Snippet {...active}>
												<AccordionSummary
													expandIcon={<ExpandMore />}
													aria-controls="panel1bh-content"
													id="panel1bh-header"
												>
													<Typography> {active.snippetName} <Chip label={active.snippetType} size="small" variant="outlined"  className={styles.customChipSnippet}/> </Typography>
												</AccordionSummary>
												<AccordionDetails>
													{parse(active.component, parsingOptions)}
												</AccordionDetails>
											</Snippet> : <FormPart {...active}>
												{active.snippetId ? <div><div className={styles.rearangeButtonControls}>
													<IconButton onClick={() => handleDelete(active.id)} aria-label="delete" size="small">
														<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>	
													</IconButton>
													<DragHandle/>
												</div><div className={styles.snippetView}> {parse(active.component, parsingOptions)}<br></br></div></div> : active.component}</FormPart>)}
										</DragOverlay>, document.body)}
										<Box>
											<Grid item container columnSpacing={3}>
												<Grid item lg={isMenuOpen ? 12 : 4} style={{ display: isMenuOpen ? 'none' : 'block' }}>
													<div>
														<Grid container columnSpacing={3}>
															<Grid item md={12}>
																<h3>Snippets</h3>
															</Grid>
														</Grid>
														<Grid item container columnSpacing={3}>
															<Grid item md={8}>
																<div className={styles.snippetAccrod}> 
																	<Controller
																		control={filterForm.control}
																		name='searchText'
																		defaultValue=''
																		render={({ field }) => (								
																			<NabuSearchFilters
																				className={styles.searchMain}
																				{...field}
																				id='search-input-text-field'
																				placeholder='Search by name'
																				variant='outlined'
																				onChange={(event: any) => {field.onChange(event); handleFilters('searchText', {
																					value: filterForm.getValues().searchText,
																					operator: OPERATORS.CONTAINS,
																					property: 'Name',
																					isCaseSensitive: false
																				});}}
																				size='small'
																				InputProps={{
																					startAdornment: (
																						<InputAdornment position='start'  >
																							<Search/>
																						</InputAdornment>
																					),
																				}}
																				data-cy='searchInputFilterTextBox'
																			/>
																		)}
																	/>
																	<div>
																		<IconButton
																			aria-label="more"
																			id="long-button"
																			aria-controls={open ? 'long-menu' : undefined}
																			aria-expanded={open ? 'true' : undefined}
																			aria-haspopup="true"
																			onClick={handleClick}
																		>
																			<FilterList />
																		</IconButton>
																		<Menu
																			id="long-menu"
																			MenuListProps={{
																				'aria-labelledby': 'long-button',
																			}}
																			anchorEl={anchorEl}
																			open={open}
																			onClose={handleClose}
																			PaperProps={{
																				style: {
																					maxHeight: ITEM_HEIGHT * 4.5,
																					width: '20ch',
																				},
																			}}
																		>
																			{snippetTypes.map((option) => (
																				<MenuItem
																					key={option}
																					selected={option === selectedOption}
																					onClick={() => {
																						handleOptionSelect(option);
																						filterForm.setValue('type', option, { shouldDirty: true });
																						handleFilters('type', {
																							value: filterForm.getValues().type,
																							operator: OPERATORS.CONTAINS,
																							property: 'Type',
																							isCaseSensitive: false
																						});
																					}}
																				>
																					{option}
																				</MenuItem>
																			))}
																		</Menu>
																		
																	</div>
																</div>
															</Grid>
														</Grid>
														{/* Display the header only if selectedOption is 'Header' */}
														<div className={styles.contractFormBlockSnippet}>
															<div className={styles.addSnippetForm}>
																<SortableContext items={snippetIds}>
																	{snippets.map((snippet: TemplatePart, index: number) => <Snippet {...snippet} key={index}>
																		<AccordionSummary
																			expandIcon={<ExpandMore />}
																			aria-controls="panel1bh-content"
																			id="panel1bh-header"
																		>
																			<Typography> {snippet.snippetName} <Chip label={snippet.snippetType} size="small" variant="outlined"  className={styles.customChipSnippet}/> </Typography>
																		</AccordionSummary>
																		<AccordionDetails>
																			{parse(snippet.component, parsingOptions)}
																		</AccordionDetails>
																	</Snippet>)}
																</SortableContext>
															</div>
														</div>
													</div>
												</Grid>
												<Grid item lg={isMenuOpen ? 12 : 8}>
													<div >
														<Tooltip title={tooltipTitle}>
															<Fab size="small" color="primary" aria-label="Collapse Snippet" className={styles.collapseSnippet} onClick={toggleMenu}>
																{isMenuOpen ? <MenuIcon /> : <MenuOpen />}
															</Fab>
														</Tooltip>
													</div>
													<div className={styles.scrollFIle}>
														<div className={styles.contractFormBlock}>
															<div className={styles.bgWhite}>
																<SortableContext items={formPartIds}>
																	{formParts.map((formPart: TemplatePart, index: any) => <FormPart {...formPart} key={index}>
																		{formPart.snippetId ? <div><div className={styles.rearangeButtonControls}>
																			<IconButton onClick={() => handleDelete(formPart.id)} aria-label="delete" size="small">
																				<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>	
																			</IconButton>
																			<DragHandle/>
																		</div>
																		<div className={styles.dragAria}>
																			{parse(formPart.component, parsingOptions)}
																		</div>
																		<br></br>
																			
																		</div>
																			: formPart.component}
																	</FormPart>)}
																</SortableContext>
															</div>
														</div>
														<br></br>
													</div>
												</Grid>
											</Grid>
										</Box>
									</DndContext>
								</div>
							</div>}
						</Grid>
					</Grid>
				
				</div>
			</Box>
		</Box>
	
		</> : <Box sx={{p:3, pt:0}}>
			<NabuCardDefault>
				<div className={styles.bgWhite}>
					{formParts.map((formPart: TemplatePart, index: any) => <FormPart {...formPart} key={index}>
						{formPart.snippetId ? <div>
							<div className={styles.dragAria}>
								{parse(formPart.component, parsingOptions)}
							</div>
							<br></br>				
						</div> : formPart.component}
					</FormPart>)}
				</div>
			</NabuCardDefault>
			<br></br>
		</Box>}</>
	);
};

export default TemplateForm;