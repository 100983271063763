import { Tooltip, TooltipProps } from '@mui/material';

interface Props extends Omit<TooltipProps, 'children'> {
	maxLength?: number;
	title: string;
	spanClassName?: string;
	defaultCase?: boolean;
	hideTooltip?: boolean;
}

const TextWrap = (props: Props) => {
	const { maxLength, title, spanClassName, ...rest } = props;
	
	const MAX_LENGTH = maxLength || 20;
	const displayText = title?.length > MAX_LENGTH ? `${title.substring(0, MAX_LENGTH)}...` : title;

	const tooltipText = props?.hideTooltip ? undefined : ((title?.length > MAX_LENGTH) && (props?.defaultCase ? title : title?.toUpperCase()));

	return (
		<Tooltip title={tooltipText} placement='top-start' {...rest}>
			<span className={spanClassName}>{props?.defaultCase ? displayText : displayText?.toUpperCase()}</span>
		</Tooltip>
	);
};

export default TextWrap;
